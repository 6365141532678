/* table alignment within table expando row */
table tr.expando-row td {
	table td {
		background-color: var(--application-base-background-color);
		padding: var(--table-base-cell-padding);
	}
}

table.nowrap {
	th,
	td {
	  a {
	  	white-space: inherit;
	  }
	}
}

td,
tr {
	&.confirmation {
		background-color: var(--notification-confirmation-background-color);
		border-width: var(--notification-confirmation-border-width);
  	border-style: var(--notification-confirmation-border-style);
  	border-color: var(--notification-confirmation-border-color);

  	&:before {
	    content: var(--notification-confirmation-icon-before-content);
	  }

	  &:after {
	    content: var(--notification-confirmation-icon-after-content);
	  }
	}

	&.error {
		background-color: var(--notification-error-background-color);
		border-width: var(--notification-error-border-width);
  	border-style: var(--notification-error-border-style);
  	border-color: var(--notification-error-border-color);

  	&:before {
	    content: var(--notification-error-icon-before-content);
	  }

	  &:after {
	    content: var(--notification-error-icon-after-content);
	  }
	}

	&.warning {
		background-color: var(--notification-warning-background-color);
		border-width: var(--notification-warning-border-width);
  	border-style: var(--notification-warning-border-style);
  	border-color: var(--notification-warning-border-color);

  	&:before {
	    content: var(--notification-warning-icon-before-content);
	  }

	  &:after {
	    content: var(--notification-warning-icon-after-content);
	  }
	}

	&.explanation {
		background-color: var(--notification-explanation-background-color);
		border-width: var(--notification-explanation-border-width);
  	border-style: var(--notification-explanation-border-style);
  	border-color: var(--notification-explanation-border-color);

  	&:before {
	    content: var(--notification-explanation-icon-before-content);
	  }

	  &:after {
	    content: var(--notification-explanation-icon-after-content);
	  }
	}
}