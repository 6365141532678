/*
For a tree-view (tables in tables), we can save some space
*/
table.tree-view tbody > tr > td[colspan] {
  padding-right: 0;
}

table.tree-view tr > .col-ooi-id {
  width: 65%;
}
table.tree-view tr > .col-ooi-type {
  width: 20%;
}
table.tree-view tr > .col-action {
  width: 15%;
}

table.tree-view tr.folded > td {
  background-color: #cccccc;
  overflow: hidden;
}

table.tree-view tr.folded > td:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/*
show/hide table body, easy-mode (NOT accessability complient)

You'll need to include checkboxToggler.js in the HTML

Markup:
<input type="checkbox" class="toggle-table-body is-hidden" id="checkbox-id">
<table>
  <thead>
    <tr><th>
      <label for="checkbox-id">
        Title of table
      </label>
    </th></tr>
  </thead>
  <tbody>
    <tr><td>Body of table</td></tr>
  </tbody>
</table>
*/
.toggle-table-body:not(:checked) + table.tree-view tbody {
  display: none;
}

.toggle-table-body + table.tree-view > thead label {
  cursor: pointer;
}

.toggle-table-body:checked + table.tree-view > thead .ro-icon.open {
  display: none;
}

.toggle-table-body:not(:checked) + table.tree-view > thead .ro-icon.close {
  display: none;
}

table.tree-view {
  table-layout: fixed;
}

table.tree-view th > div {
  display: flex;
  justify-content: space-between;
}
