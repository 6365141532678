.align-right {
  float: right;
}

.is-hidden {
  display: none;
}

.uc-first {
  text-transform: capitalize;
}

:root {
  --critical-informational-critical: #42145f;
  --critical-informational-high: #d6293e;
  --critical-informational-medium: #c36100;
  --critical-informational-low: #00519c;
  --critical-informational-informational: #13853e;
}

.critical, .high, .medium, .low, .informational {
  color: #fff;
}

.critical {
  background-color: var(--critical-informational-critical);
}

.high {
  background-color: var(--critical-informational-high);
}

.medium {
  background-color: var(--critical-informational-medium);
}

.low {
  background-color: var(--critical-informational-low);
}

.informational {
  background-color: var(--critical-informational-informational);
}

:root {
  --tags-soft-blue-light-background-color: #eef8fe;
  --tags-soft-blue-light-text-color: #006fb3;
  --tags-soft-blue-light-border-color: #006fb3;
  --tags-soft-blue-medium-background-color: #abdbf8;
  --tags-soft-blue-medium-text-color: #005c94;
  --tags-soft-blue-medium-border-color: #005c94;
  --tags-soft-blue-dark-background-color: #40adef;
  --tags-soft-blue-dark-text-color: #003e64;
  --tags-soft-blue-dark-border-color: #003e64;
  --tags-soft-green-light-background-color: #ebfaef;
  --tags-soft-green-light-text-color: #157b31;
  --tags-soft-green-light-border-color: #157b31;
  --tags-soft-green-medium-background-color: #94e6ab;
  --tags-soft-green-medium-text-color: #116629;
  --tags-soft-green-medium-border-color: #1b9d3f;
  --tags-soft-green-dark-background-color: #20bd4c;
  --tags-soft-green-dark-text-color: #134421;
  --tags-soft-green-dark-border-color: #116629;
  --tags-soft-yellow-light-background-color: #fff7cf;
  --tags-soft-yellow-light-text-color: #7d6900;
  --tags-soft-yellow-light-border-color: #7d6900;
  --tags-soft-yellow-medium-background-color: #fecf48;
  --tags-soft-yellow-medium-text-color: #685700;
  --tags-soft-yellow-medium-border-color: #a08700;
  --tags-soft-yellow-dark-background-color: #ddb900;
  --tags-soft-yellow-dark-text-color: #584a00;
  --tags-soft-yellow-dark-border-color: #685700;
  --tags-soft-orange-light-background-color: #fff5ed;
  --tags-soft-orange-light-text-color: #a75312;
  --tags-soft-orange-light-border-color: #a75312;
  --tags-soft-orange-medium-background-color: #ffcaa1;
  --tags-soft-orange-medium-text-color: #8b450f;
  --tags-soft-orange-medium-border-color: #d66a17;
  --tags-soft-orange-dark-background-color: #ffa865;
  --tags-soft-orange-dark-text-color: #5e2f0a;
  --tags-soft-orange-dark-border-color: #a75312;
  --tags-soft-red-light-background-color: #fff4f3;
  --tags-soft-red-light-text-color: #c3372c;
  --tags-soft-red-light-border-color: #ffa49d;
  --tags-soft-red-medium-background-color: #ffc7c3;
  --tags-soft-red-medium-text-color: #a22e25;
  --tags-soft-red-medium-border-color: #f84638;
  --tags-soft-red-dark-background-color: #ff7d73;
  --tags-soft-red-dark-text-color: #6e1f19;
  --tags-soft-red-dark-border-color: #a22e25;
  --tags-soft-violet-light-background-color: #f7f6fc;
  --tags-soft-violet-light-text-color: #6e5fae;
  --tags-soft-violet-light-border-color: #bfb6e6;
  --tags-soft-violet-medium-background-color: #d6d1ef;
  --tags-soft-violet-medium-text-color: #5b4f90;
  --tags-soft-violet-medium-border-color: #8c7cd2;
  --tags-soft-violet-dark-background-color: #a89cdd;
  --tags-soft-violet-dark-text-color: #3d3560;
  --tags-soft-violet-dark-border-color: #5b4f90;
  --branding-color-1: LightSalmon;
  --branding-color-2: SeaShell;
  --branding-color-accent-1: LightSeaGreen;
  --branding-color-accent-2: MediumVioletRed;
  --branding-color-accent-3: SlateBlue;
  --branding-color-accent-4: Purple;
  --branding-color-accent-5: OliveDrab;
  --branding-color-1-background-color: var(--branding-color-1);
  --branding-color-1-text-color: black;
  --branding-color-1-link-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-active-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-hover-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-hover-text-color: var(--branding-color-1-text-color);
  --branding-color-2-background-color: var(--branding-color-2);
  --branding-color-2-text-color: black;
  --branding-color-2-link-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-active-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-hover-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-hover-text-color: var(--branding-color-2-text-color);
  --branding-color-accent-1-background-color: var(--branding-color-accent-1);
  --branding-color-accent-1-text-color: white;
  --branding-color-accent-1-link-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-active-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-hover-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-hover-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-2-background-color: var(--branding-color-accent-2);
  --branding-color-accent-2-text-color: white;
  --branding-color-accent-2-link-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-active-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-hover-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-hover-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-3-background-color: var(--branding-color-accent-3);
  --branding-color-accent-3-text-color: white;
  --branding-color-accent-3-link-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-active-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-hover-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-hover-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-4-background-color: var(--branding-color-accent-4);
  --branding-color-accent-4-text-color: white;
  --branding-color-accent-4-link-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-active-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-hover-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-hover-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-5-background-color: var(--branding-color-accent-5);
  --branding-color-accent-5-text-color: white;
  --branding-color-accent-5-link-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-active-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-hover-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-hover-text-color: var(--branding-color-accent-5-text-color);
}

.branding-color-1 {
  background-color: var(--branding-color-1-background-color);
  color: var(--branding-color-1-text-color);
}

.branding-color-1 a {
  color: var(--branding-color-1-link-text-color);
}

.branding-color-1 a:active {
  color: var(--branding-color-1-link-active-text-color);
}

.branding-color-1 a:visited {
  color: var(--branding-color-1-link-visited-text-color);
}

.branding-color-1 a:visited:hover {
  color: var(--branding-color-1-link-visited-hover-text-color);
}

.branding-color-1 a:hover {
  color: var(--branding-color-1-link-hover-text-color);
}

.branding-color-2 {
  background-color: var(--branding-color-2-background-color);
  color: var(--branding-color-2-text-color);
}

.branding-color-2 a {
  color: var(--branding-color-2-link-text-color);
}

.branding-color-2 a:active {
  color: var(--branding-color-2-link-active-text-color);
}

.branding-color-2 a:visited {
  color: var(--branding-color-2-link-visited-text-color);
}

.branding-color-2 a:visited:hover {
  color: var(--branding-color-2-link-visited-hover-text-color);
}

.branding-color-2 a:hover {
  color: var(--branding-color-2-link-hover-text-color);
}

.branding-color-accent-1 {
  background-color: var(--branding-color-accent-1-background-color);
  color: var(--branding-color-accent-1-text-color);
}

.branding-color-accent-1 a {
  color: var(--branding-color-accent-1-link-text-color);
}

.branding-color-accent-1 a:active {
  color: var(--branding-color-accent-1-link-active-text-color);
}

.branding-color-accent-1 a:visited {
  color: var(--branding-color-accent-1-link-visited-text-color);
}

.branding-color-accent-1 a:visited:hover {
  color: var(--branding-color-accent-1-link-visited-hover-text-color);
}

.branding-color-accent-1 a:hover {
  color: var(--branding-color-accent-1-link-hover-text-color);
}

.branding-color-accent-2 {
  background-color: var(--branding-color-accent-2-background-color);
  color: var(--branding-color-accent-2-text-color);
}

.branding-color-accent-2 a {
  color: var(--branding-color-accent-2-link-text-color);
}

.branding-color-accent-2 a:active {
  color: var(--branding-color-accent-2-link-active-text-color);
}

.branding-color-accent-2 a:visited {
  color: var(--branding-color-accent-2-link-visited-text-color);
}

.branding-color-accent-2 a:visited:hover {
  color: var(--branding-color-accent-2-link-visited-hover-text-color);
}

.branding-color-accent-2 a:hover {
  color: var(--branding-color-accent-2-link-hover-text-color);
}

.branding-color-accent-3 {
  background-color: var(--branding-color-accent-3-background-color);
  color: var(--branding-color-accent-3-text-color);
}

.branding-color-accent-3 a {
  color: var(--branding-color-accent-3-link-text-color);
}

.branding-color-accent-3 a:active {
  color: var(--branding-color-accent-3-link-active-text-color);
}

.branding-color-accent-3 a:visited {
  color: var(--branding-color-accent-3-link-visited-text-color);
}

.branding-color-accent-3 a:visited:hover {
  color: var(--branding-color-accent-3-link-visited-hover-text-color);
}

.branding-color-accent-3 a:hover {
  color: var(--branding-color-accent-3-link-hover-text-color);
}

.branding-color-accent-4 {
  background-color: var(--branding-color-accent-4-background-color);
  color: var(--branding-color-accent-4-text-color);
}

.branding-color-accent-4 a {
  color: var(--branding-color-accent-4-link-text-color);
}

.branding-color-accent-4 a:active {
  color: var(--branding-color-accent-4-link-active-text-color);
}

.branding-color-accent-4 a:visited {
  color: var(--branding-color-accent-4-link-visited-text-color);
}

.branding-color-accent-4 a:visited:hover {
  color: var(--branding-color-accent-4-link-visited-hover-text-color);
}

.branding-color-accent-4 a:hover {
  color: var(--branding-color-accent-4-link-hover-text-color);
}

.branding-color-accent-5 {
  background-color: var(--branding-color-accent-5-background-color);
  color: var(--branding-color-accent-5-text-color);
}

.branding-color-accent-5 a {
  color: var(--branding-color-accent-5-link-text-color);
}

.branding-color-accent-5 a:active {
  color: var(--branding-color-accent-5-link-active-text-color);
}

.branding-color-accent-5 a:visited {
  color: var(--branding-color-accent-5-link-visited-text-color);
}

.branding-color-accent-5 a:visited:hover {
  color: var(--branding-color-accent-5-link-visited-hover-text-color);
}

.branding-color-accent-5 a:hover {
  color: var(--branding-color-accent-5-link-hover-text-color);
}

:root {
  --critical-informational-labels-padding: 0 1rem;
  --critical-informational-labels-min-width: 5rem;
  --critical-informational-labels-text-align: center;
}

table .critical, table .high, table .medium, table .low, table .informational {
  padding: var(--critical-informational-labels-padding);
  min-width: var(--critical-informational-labels-min-width);
  text-align: var(--critical-informational-labels-text-align);
  display: inline-block;
}

:root {
  --disabled-cursor: not-allowed;
  --disabled-opacity: .4;
}

:disabled, .disabled {
  opacity: var(--disabled-opacity);
  cursor: var(--disabled-cursor) !important;
}

:disabled + label, :disabled + label:before, .disabled + label, .disabled + label:before {
  cursor: var(--disabled-cursor);
  opacity: var(--disabled-opacity);
}

:root {
  --application-base-background-color: #fff;
  --application-base-text-color: #000;
  --application-base-tint-1-background-color: #f5f5f5;
  --application-base-tint-1-text-color: #000;
  --application-base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Roboto Sans", "Noto Sans", sans-serif;
  --application-base-font-size: 1.125rem;
  --application-base-line-height: 1.5;
  --application-base-font-weight: normal;
  --application-base-text-align: left;
  --application-base-padding: 1.5rem;
  --application-base-border-radius: 0;
  --application-base-gap: 1rem;
  --application-base-accent-color: #931171;
  --application-base-accent-color-text-color: #fff;
  --application-base-accent-color-hover: hotpink;
  --application-base-accent-color-hover-text-color: black;
  --application-base-accent-color-active: hotpink;
  --application-base-accent-color-active-text-color: black;
  --application-base-accent-color-focus: hotpink;
  --application-base-accent-color-focus-text-color: black;
  --application-base-accent-color-selected: hotpink;
  --application-base-accent-color-selected-text-color: black;
  --cta-background-color: green;
  --cta-text-color: #fff;
}

body {
  min-height: 100vh;
  background-color: var(--application-base-background-color, white);
  color: var(--application-base-text-color, black);
  font-family: var(--application-base-font-family, sans-serif);
  font-size: var(--application-base-font-size, initial);
  line-height: var(--application-base-line-height, initial);
  font-weight: var(--application-base-font-weight, initial);
  text-align: var(--application-base-text-align, initial);
  flex-direction: column;
  margin: 0;
  display: flex;
}

body > main {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

:root {
  --icon-base-font-family: "Manon icons";
  --icon-base-padding: .5rem;
  --icon-base-width: 2.75rem;
  --icon-base-height: 2.75rem;
  --icon-base-min-width: 2.75rem;
  --icon-base-min-height: 2.75rem;
  --icon-base-font-size: var(--application-base-font-size);
  --icon-base-before-font-size: var(--icon-base-font-size);
  --icon-base-image-width: var(--icon-base-width);
  --icon-base-image-height: var(--icon-base-height);
  --icon-base-image-padding: var(--icon-base-padding);
}

ul.icons li:before, ul.icons li :after, .icon:before, .icon :after {
  font-family: var(--icon-base-font-family);
  font-size: var(--icon-base-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  position: static;
}

.icon:before, .icon :after {
  text-align: center;
  font-size: var(--icon-base-before-font-size);
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

ul.icons li:before, ul.icons li :after {
  text-align: center;
  font-size: var(--icon-base-before-font-size);
  justify-content: center;
  align-items: center;
  display: flex;
}

img.icon {
  object-position: center;
  width: var(--icon-base-image-width);
  min-width: var(--icon-base-image-min-width);
  max-width: var(--icon-base-image-max-width);
  height: var(--icon-base-image-height);
  min-height: var(--icon-base-image-min-height);
  max-height: var(--icon-base-image-max-height);
  padding: var(--icon-base-image-padding);
}

:root {
  --headings-font-family: var(--headings-base-set-font-family, var(--application-base-font-family));
  --headings-font-size: 1.5rem;
  --headings-font-weight: bold;
  --headings-line-height: var(--application-base-line-height);
  --headings-text-color: var(--application-base-text-color);
  --headings-margin: 1rem;
  --h1-font-family: var(--headings-font-family);
  --h1-font-size: var(--heading-xxl-font-size);
  --h1-font-weight: var(--heading-xxl-font-weight);
  --h1-line-height: var(--heading-xxl-line-height);
  --h1-text-color: var(--heading-xxl-text-color);
  --h1-margin: var(--heading-xxl-margin);
  --h2-font-family: var(--headings-font-family);
  --h2-font-size: var(--heading-xl-font-size);
  --h2-text-color: var(--heading-xl-text-color);
  --h2-font-weight: var(--heading-xl-font-weight);
  --h2-line-height: var(--heading-xl-line-height);
  --h2-margin: var(--heading-xl-margin);
  --h3-font-family: var(--headings-font-family);
  --h3-font-size: var(--heading-large-font-size);
  --h3-text-color: var(--heading-large-text-color);
  --h3-font-weight: var(--heading-large-font-weight);
  --h3-line-height: var(--heading-large-line-height);
  --h3-margin: var(--heading-large-margin);
  --h4-font-family: var(--headings-font-family);
  --h4-font-size: var(--heading-normal-font-size);
  --h4-text-color: var(--heading-normal-text-color);
  --h4-font-weight: var(--heading-normal-font-weight);
  --h4-line-height: var(--heading-normal-line-height);
  --h4-margin: var(--heading-normal-margin);
  --h5-font-family: var(--headings-font-family);
  --h5-font-size: var(--heading-small-font-size);
  --h5-text-color: var(--heading-small-text-color);
  --h5-font-weight: var(--heading-small-font-weight);
  --h5-line-height: var(--heading-small-line-height);
  --h5-margin: var(--heading-small-margin);
  --h6-font-family: var(--headings-font-family);
  --h6-font-size: var(--heading-xs-font-size);
  --h6-text-color: var(--heading-xs-text-color);
  --h6-font-weight: var(--heading-xs-font-weight);
  --h6-line-height: var(--heading-xs-line-height);
  --h6-margin: var(--heading-xs-margin);
}

h1, h2, h3, h4, h5, h6 {
  hyphens: auto;
  margin: 0;
}

h1, h1 > a {
  font-family: var(--h1-font-family, var(--application-base-font-family));
  font-size: var(--h1-font-size, inherit);
  color: var(--h1-text-color, inherit);
  font-weight: var(--h1-font-weight, inherit);
  line-height: var(--h1-line-height, inherit);
  margin: var(--h1-margin, inherit);
}

h2, h2 > a {
  font-family: var(--h2-font-family, var(--application-base-font-family));
  font-size: var(--h2-font-size, inherit);
  color: var(--h2-text-color, inherit);
  font-weight: var(--h2-font-weight, inherit);
  line-height: var(--h2-line-height, inherit);
  margin: var(--h2-margin, inherit);
}

h3, h3 > a {
  font-family: var(--h3-font-family, var(--application-base-font-family));
  font-size: var(--h3-font-size, inherit);
  color: var(--h3-text-color, inherit);
  font-weight: var(--h3-font-weight, inherit);
  line-height: var(--h3-line-height, inherit);
  margin: var(--h3-margin, inherit);
}

h4, h4 > a {
  font-family: var(--h4-font-family, var(--application-base-font-family));
  font-size: var(--h4-font-size, inherit);
  color: var(--h4-text-color, inherit);
  font-weight: var(--h4-font-weight, inherit);
  line-height: var(--h4-line-height, inherit);
  margin: var(--h4-margin, inherit);
}

h5, h5 > a {
  font-family: var(--h5-font-family, var(--application-base-font-family));
  font-size: var(--h5-font-size);
  color: var(--h5-text-color);
  font-weight: var(--h5-font-weight);
  line-height: var(--h5-line-height, initial);
  margin: var(--h5-margin, inherit);
}

h6, h6 > a {
  font-family: var(--h6-font-family, var(--application-base-font-family));
  font-size: var(--h6-font-size);
  color: var(--h6-text-color);
  font-weight: var(--h6-font-weight);
  line-height: var(--h6-line-height, initial);
  margin: var(--h6-margin, inherit);
}

:root {
  --headings-base-set-font-family: var(--application-base-font-family);
  --headings-base-set-font-size: 1.5rem;
  --headings-base-set-font-weight: bold;
  --headings-base-set-line-height: var(--application-base-line-height);
  --headings-base-set-text-color: var(--application-base-text-color);
  --headings-base-set-margin: 0;
  --heading-xxl-font-family: var(--headings-base-set-font-family);
  --heading-xxl-font-size: 2.5rem;
  --heading-xxl-font-weight: var(--headings-base-set-font-weight);
  --heading-xxl-line-height: var(--headings-base-set-line-height);
  --heading-xxl-text-color: var(--headings-base-set-text-color);
  --heading-xxl-margin: var(--headings-base-set-margin);
  --heading-xl-font-family: var(--headings-base-set-font-family);
  --heading-xl-font-size: 2rem;
  --heading-xl-font-weight: var(--headings-base-set-font-weight);
  --heading-xl-line-height: var(--headings-base-set-line-height);
  --heading-xl-text-color: var(--headings-base-set-text-color);
  --heading-xl-margin: var(--headings-base-set-margin);
  --heading-large-font-family: var(--headings-base-set-font-family);
  --heading-large-font-size: 1.5rem;
  --heading-large-font-weight: var(--headings-base-set-font-weight);
  --heading-large-line-height: var(--headings-base-set-line-height);
  --heading-large-text-color: var(--headings-base-set-text-color);
  --heading-large-margin: var(--headings-base-set-margin);
  --heading-normal-font-family: var(--headings-base-set-font-family);
  --heading-normal-font-size: 1rem;
  --heading-normal-font-weight: var(--headings-base-set-font-weight);
  --heading-normal-line-height: var(--headings-base-set-line-height);
  --heading-normal-text-color: var(--headings-base-set-text-color);
  --heading-normal-margin: var(--headings-base-set-margin);
  --heading-small-font-family: var(--headings-base-set-font-family);
  --heading-small-font-size: .8rem;
  --heading-small-font-weight: var(--headings-base-set-font-weight);
  --heading-small-line-height: var(--headings-base-set-line-height);
  --heading-small-text-color: var(--headings-base-set-text-color);
  --heading-small-margin: var(--headings-base-set-margin);
  --heading-xs-font-family: var(--headings-base-set-font-family);
  --heading-xs-font-size: .7rem;
  --heading-xs-font-weight: var(--headings-base-set-font-weight);
  --heading-xs-line-height: var(--headings-base-set-line-height);
  --heading-xs-text-color: var(--headings-base-set-text-color);
  --heading-xs-margin: var(--headings-base-set-margin);
}

.heading-xxl {
  font-family: var(--heading-xxl-font-family, inherit);
  font-size: var(--heading-xxl-font-size, inherit);
  font-weight: var(--heading-xxl-font-weight, inherit);
  line-height: var(--heading-xxl-line-height, inherit);
  color: var(--heading-xxl-text-color, inherit);
  margin: var(--heading-xxl-margin);
}

.heading-xl {
  font-family: var(--heading-xl-font-family, inherit);
  font-size: var(--heading-xl-font-size, inherit);
  font-weight: var(--heading-xl-font-weight, inherit);
  line-height: var(--heading-xl-line-height, inherit);
  color: var(--heading-xl-text-color, inherit);
  margin: var(--heading-xl-margin);
}

.heading-large {
  font-family: var(--heading-large-font-family, inherit);
  font-size: var(--heading-large-font-size, inherit);
  font-weight: var(--heading-large-font-weight, inherit);
  line-height: var(--heading-large-line-height, inherit);
  color: var(--heading-large-text-color, inherit);
  margin: var(--heading-large-margin);
}

.heading-normal {
  font-family: var(--heading-normal-font-family, inherit);
  font-size: var(--heading-normal-font-size, inherit);
  font-weight: var(--heading-normal-font-weight, inherit);
  line-height: var(--heading-normal-line-height, inherit);
  color: var(--heading-normal-text-color, inherit);
  margin: var(--heading-normal-margin);
}

.heading-small {
  font-family: var(--heading-small-font-family, inherit);
  font-size: var(--heading-small-font-size, inherit);
  font-weight: var(--heading-small-font-weight, inherit);
  line-height: var(--heading-small-line-height, inherit);
  color: var(--heading-small-text-color, inherit);
  margin: var(--heading-small-margin);
}

.heading-xs {
  font-family: var(--heading-xs-font-family, inherit);
  font-size: var(--heading-xs-font-size, inherit);
  font-weight: var(--heading-xs-font-weight, inherit);
  line-height: var(--heading-xs-line-height, inherit);
  color: var(--heading-xs-text-color, inherit);
  margin: var(--heading-xs-margin);
}

:root {
  --p-line-height: var(--application-base-line-height);
  --p-font-size: var(--application-base-font-size);
  --p-font-weight: var(--application-base-font-weight);
  --p-text-color: var(--application-base-text-color);
  --p-background-color: unset;
}

p {
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
  color: inherit;
  background-color: var(--p-background-color);
  margin: 0;
}

:root {
  --emphasized-font-size: var(--application-base-font-size);
  --emphasized-font-weight: bold;
  --emphasized-line-height: var(--application-base-line-height);
  --emphasized-text-color: var(--application-base-text-color);
}

.emphasized {
  font-size: var(--emphasized-font-size, inherit);
  font-weight: var(--emphasized-font-weight, inherit);
  line-height: var(--emphasized-line-height, inherit);
  color: var(--emphasized-text-color, inherit);
}

:root {
  --de-emphasized-font-size: .8rem;
  --de-emphasized-font-weight: var(--application-base-font-weight);
  --de-emphasized-line-height: var(--application-base-line-height);
  --de-emphasized-text-color: #696969;
}

.de-emphasized {
  font-size: var(--de-emphasized-font-size, inherit);
  font-weight: var(--de-emphasized-font-weight, inherit);
  line-height: var(--de-emphasized-line-height, inherit);
  color: var(--de-emphasized-text-color, inherit);
}

:root {
  --link-background-color: unset;
  --link-text-color: #0000c2;
  --link-hover-text-color: #008;
  --link-visited-text-color: #5b109f;
  --link-visited-hover-text-color: #400b6f;
}

a:visited > h1, a:visited > h2, a:visited > h3, a:visited > h4, a:visited > h5, a:visited > h6, a.visited > h1, a.visited > h2, a.visited > h3, a.visited > h4, a.visited > h5, a.visited > h6, a:visited, a.visited {
  background-color: var(--link-background-color);
  color: var(--link-visited-text-color, inherit);
}

a:visited > h1:before, a:visited > h2:before, a:visited > h3:before, a:visited > h4:before, a:visited > h5:before, a:visited > h6:before, a.visited > h1:before, a.visited > h2:before, a.visited > h3:before, a.visited > h4:before, a.visited > h5:before, a.visited > h6:before, a:visited:before, a.visited:before {
  color: inherit;
}

a:visited > h1:hover, a:visited > h2:hover, a:visited > h3:hover, a:visited > h4:hover, a:visited > h5:hover, a:visited > h6:hover, a.visited > h1:hover, a.visited > h2:hover, a.visited > h3:hover, a.visited > h4:hover, a.visited > h5:hover, a.visited > h6:hover, a:hover:visited, a.visited:hover, a:visited > h1.hover, a:visited > h2.hover, a:visited > h3.hover, a:visited > h4.hover, a:visited > h5.hover, a:visited > h6.hover, a.visited > h1.hover, a.visited > h2.hover, a.visited > h3.hover, a.visited > h4.hover, a.visited > h5.hover, a.visited > h6.hover, a.hover:visited, a.hover.visited {
  color: var(--link-visited-hover-text-color, inherit);
}

a {
  background-color: var(--link-background-color);
  color: var(--link-text-color, initial);
  cursor: pointer;
  white-space: break-spaces;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

a > h1, a > h2, a > h3, a > h4, a > h5, a > h6 {
  color: var(--link-text-color, inherit);
}

a:hover, a.hover {
  color: var(--link-hover-text-color, inherit);
}

a:active, a.active {
  outline-offset: .25rem;
  z-index: 1;
  outline: 1px dotted #ccc;
}

:root {
  --nota-bene-line-height: var(--application-base-line-height);
  --nota-bene-font-size: .8rem;
  --nota-bene-text-color: #5d5d5d;
}

.nota-bene {
  line-height: var(--nota-bene-line-height, inherit);
  font-size: var(--nota-bene-font-size, inherit);
  font-weight: var(--nota-bene-font-weight, inherit);
  color: var(--nota-bene-text-color, inherit);
  background-color: var(--nota-bene-background-color, transparent);
  margin: 0;
}

:root {
  --horizontal-view-gap: 1rem;
  --horizontal-view-justify-content: flex-start;
}

.horizontal-view-group > div, .horizontal-view {
  align-items: center;
  justify-content: var(--horizontal-view-justify-content);
  gap: var(--horizontal-view-gap);
  flex-wrap: wrap;
  display: flex;
  flex-direction: row !important;
}

@media (min-width: 50rem) {
  .horizontal-view-group > div, .horizontal-view {
    flex-wrap: nowrap;
  }
}

.horizontal-scroll {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.centered-view {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.focus-only, .button.focus-only {
  font-size: inherit;
  padding: inherit;
  transform: scale(1);
}

.focus-only:not(:focus), .button.focus-only:not(:focus) {
  min-height: 0;
  padding: 0;
  font-size: 0;
  transform: scale(1, 0);
}

table tr.nowrap, table th.nowrap, table td.nowrap, table tfoot.nowrap th, table tfoot.nowrap td, table tbody.nowrap th, table tbody.nowrap td, table thead.nowrap th, table.nowrap th, table.nowrap td, .nowrap, table tr.nowrap a, table th.nowrap a, table td.nowrap a, table tfoot.nowrap th a, table tfoot.nowrap td a, table tbody.nowrap th a, table tbody.nowrap td a, table thead.nowrap th a, table.nowrap th a, table.nowrap td a, .nowrap a {
  white-space: nowrap;
}

.visually-hidden {
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  white-space: nowrap;
  width: 0;
  height: 0;
  max-width: 0;
  max-height: 0;
  overflow: hidden;
  min-width: 0 !important;
  min-height: 0 !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
}

:root {
  --layout-base-padding-top: 2rem;
  --layout-base-padding-right: 3%;
  --layout-base-padding-bottom: 2rem;
  --layout-base-padding-left: 3%;
  --layout-base-max-width: 80rem;
  --layout-base-content-block-align-items: flex-start;
  --layout-base-gap: 5rem;
  --layout-base-content-block-gap: 4rem;
  --layout-base-within-content-block-gap: 2rem;
  --layout-base-content-block-title-margin: var(--heading-xl-margin);
  --layout-base-content-block-subtitle-margin: var(--heading-large-margin);
  --layout-header-content-block-gap: 0;
  --layout-header-content-block-padding-top: 0;
  --layout-header-content-block-padding-right: var(--layout-base-padding-right);
  --layout-header-content-block-padding-bottom: 0;
  --layout-header-content-block-padding-left: var(--layout-base-padding-left);
  --layout-header-content-block-max-width: var(--layout-base-max-width);
}

body > header {
  min-height: var(--layout-header-content-block-min-height);
  flex-direction: column;
  display: flex;
}

body > header > div {
  width: 100%;
  gap: var(--layout-header-content-block-gap);
  padding-top: var(--layout-header-content-block-padding-top);
  padding-right: var(--layout-header-content-block-padding-right);
  padding-bottom: var(--layout-header-content-block-padding-bottom);
  padding-left: var(--layout-header-content-block-padding-left);
  box-sizing: border-box;
  max-width: var(--layout-header-content-block-max-width);
  flex-direction: column;
  align-self: center;
  display: flex;
}

:root {
  --layout-main-gap: var(--layout-block-gap);
  --layout-main-content-block-gap: 1.5rem;
  --layout-main-content-block-padding-top: var(--layout-base-padding-top);
  --layout-main-content-block-padding-right: var(--layout-base-padding-right);
  --layout-main-content-block-padding-bottom: var(--layout-base-padding-bottom);
  --layout-main-content-block-padding-left: var(--layout-base-padding-left);
  --layout-main-content-block-max-width: var(--layout-base-max-width);
  --layout-main-content-block-first-padding-top: 5rem;
  --layout-main-content-block-last-padding-bottom: 5rem;
}

body > main {
  gap: var(--layout-main-content-block-gap);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

body > main > section, body > main > article, body > main > div {
  flex-direction: column;
  display: flex;
}

body > main > section > div, body > main > section > article, body > main > section > section, body > main > article > div, body > main > article > article, body > main > article > section, body > main > div > div, body > main > div > article, body > main > div > section {
  width: 100%;
  gap: var(--layout-main-content-block-gap);
  padding-top: var(--layout-main-content-block-padding-top);
  padding-right: var(--layout-main-content-block-padding-right);
  padding-bottom: var(--layout-main-content-block-padding-bottom);
  padding-left: var(--layout-main-content-block-padding-left);
  box-sizing: border-box;
  max-width: var(--layout-main-content-block-max-width);
  flex-direction: column;
  align-self: center;
  display: flex;
}

body > main > section:first-child, body > main > article:first-child, body > main > div:first-child {
  padding-top: var(--layout-main-content-block-first-padding-top);
  box-sizing: border-box;
}

body > main > section:last-child, body > main > article:last-child, body > main > div:last-child {
  padding-bottom: var(--layout-main-content-block-last-padding-bottom);
  box-sizing: border-box;
}

:root {
  --layout-footer-content-block-min-height: 15rem;
  --layout-footer-content-block-gap: 1.5rem;
  --layout-footer-content-block-padding-top: var(--layout-base-padding-top);
  --layout-footer-content-block-padding-right: var(--layout-base-padding-right);
  --layout-footer-content-block-padding-bottom: var(--layout-base-padding-bottom);
  --layout-footer-content-block-padding-left: var(--layout-base-padding-left);
  --layout-footer-content-block-max-width: var(--layout-base-max-width);
}

body > footer {
  min-height: var(--layout-footer-content-block-min-height);
  flex-direction: column;
  display: flex;
}

body > footer > section, body > footer > article, body > footer > div {
  width: 100%;
  gap: var(--layout-footer-content-block-gap);
  padding-top: var(--layout-footer-content-block-padding-top);
  padding-right: var(--layout-footer-content-block-padding-right);
  padding-bottom: var(--layout-footer-content-block-padding-bottom);
  padding-left: var(--layout-footer-content-block-padding-left);
  box-sizing: border-box;
  max-width: var(--layout-footer-content-block-max-width);
  flex-direction: column;
  align-self: center;
  display: flex;
}

:root {
  --layout-authentication-max-width: 40rem;
  --layout-authentication-padding: var(--layout-authentication-padding);
  --layout-authentication-breakpoint-1-padding: var(--layout-authentication-padding);
  --layout-authentication-breakpoint-2-padding: var(--layout-authentication-breakpoint-1-padding);
}

section.layout-authentication, article.layout-authentication, header nav.layout-authentication, footer.layout-authentication, div.layout-authentication, form.layout-authentication {
  padding: var(--layout-authentication-padding);
  max-width: var(--layout-authentication-max-width);
  background-color: var(--layout-authentication-background-color);
  color: var(--layout-authentication-text-color);
  margin: 0 auto;
}

@media (min-width: 24rem) {
  section.layout-authentication, article.layout-authentication, header nav.layout-authentication, footer.layout-authentication, div.layout-authentication, form.layout-authentication {
    padding: var(--layout-authentication-breakpoint-1-padding);
  }
}

@media (min-width: 42rem) {
  section.layout-authentication, article.layout-authentication, header nav.layout-authentication, footer.layout-authentication, div.layout-authentication, form.layout-authentication {
    padding: var(--layout-authentication-breakpoint-2-padding);
  }
}

:root {
  --layout-one-third-two-thirds-gap: var(--layout-base-content-block-gap);
  --layout-one-third-two-thirds-breakpoint-gap: var(--layout-one-third-two-thirds-gap);
}

div.one-third-two-thirds, section.one-third-two-thirds, article.one-third-two-thirds, header nav.one-third-two-thirds, footer.one-third-two-thirds {
  gap: var(--layout-one-third-two-thirds-gap);
  flex-flow: wrap;
  display: flex;
}

div.one-third-two-thirds > :first-child, section.one-third-two-thirds > :first-child, article.one-third-two-thirds > :first-child, header nav.one-third-two-thirds > :first-child, footer.one-third-two-thirds > :first-child {
  width: 100%;
}

@media (min-width: 42rem) {
  div.one-third-two-thirds, section.one-third-two-thirds, article.one-third-two-thirds, header nav.one-third-two-thirds, footer.one-third-two-thirds {
    gap: var(--layout-one-third-two-thirds-breakpoint-gap);
  }

  div.one-third-two-thirds > *, section.one-third-two-thirds > *, article.one-third-two-thirds > *, header nav.one-third-two-thirds > *, footer.one-third-two-thirds > * {
    width: 66.66%;
  }

  div.one-third-two-thirds > :nth-child(2n+1), section.one-third-two-thirds > :nth-child(2n+1), article.one-third-two-thirds > :nth-child(2n+1), header nav.one-third-two-thirds > :nth-child(2n+1), footer.one-third-two-thirds > :nth-child(2n+1) {
    width: calc(33.33% - var(--layout-one-third-two-thirds-gap) );
  }
}

:root {
  --layout-two-thirds-one-third-gap: var(--layout-base-content-block-gap);
  --layout-two-thirds-one-third-breakpoint-1-gap: var(--layout-two-thirds-one-third-gap);
}

div.two-thirds-one-third, section.two-thirds-one-third, article.two-thirds-one-third, header nav.two-thirds-one-third, footer.two-thirds-one-third {
  gap: var(--layout-two-thirds-one-third-gap);
  flex-flow: wrap;
  display: flex;
}

div.two-thirds-one-third > *, section.two-thirds-one-third > *, article.two-thirds-one-third > *, header nav.two-thirds-one-third > *, footer.two-thirds-one-third > * {
  width: 100%;
}

@media (min-width: 42rem) {
  div.two-thirds-one-third, section.two-thirds-one-third, article.two-thirds-one-third, header nav.two-thirds-one-third, footer.two-thirds-one-third {
    gap: var(--layout-two-thirds-one-third-breakpoint-1-gap);
  }

  div.two-thirds-one-third > *, section.two-thirds-one-third > *, article.two-thirds-one-third > *, header nav.two-thirds-one-third > *, footer.two-thirds-one-third > * {
    width: 33.33%;
  }

  div.two-thirds-one-third > :nth-child(2n+1), section.two-thirds-one-third > :nth-child(2n+1), article.two-thirds-one-third > :nth-child(2n+1), header nav.two-thirds-one-third > :nth-child(2n+1), footer.two-thirds-one-third > :nth-child(2n+1) {
    width: calc(66.66% - var(--layout-two-thirds-one-third-gap) );
  }
}

:root {
  --layout-fifty-fifty-gap: var(--layout-base-content-block-gap);
  --layout-fifty-fifty-breakpoint-gap: var(--layout-fifty-fifty-gap);
}

section.fifty-fifty, article.fifty-fifty, header nav.fifty-fifty, footer.fifty-fifty, div.fifty-fifty {
  gap: var(--layout-fifty-fifty-gap);
  box-sizing: border-box;
  flex-flow: wrap;
  display: flex;
}

section.fifty-fifty > *, article.fifty-fifty > *, header nav.fifty-fifty > *, footer.fifty-fifty > *, div.fifty-fifty > * {
  width: 100%;
}

@media (min-width: 42rem) {
  section.fifty-fifty, article.fifty-fifty, header nav.fifty-fifty, footer.fifty-fifty, div.fifty-fifty {
    gap: var(--layout-fifty-fifty-breakpoint-gap);
  }

  section.fifty-fifty > *, article.fifty-fifty > *, header nav.fifty-fifty > *, footer.fifty-fifty > *, div.fifty-fifty > * {
    max-width: calc(50% - var(--layout-fifty-fifty-gap) );
    flex-grow: 1;
  }
}

.column-2 {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

@media (min-width: 42rem) {
  .column-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.column-3 {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

@media (min-width: 42rem) {
  .column-3 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 60rem) {
  .column-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

:root {
  --layout-column-4-gap: var(--layout-base-content-block-gap) 1rem;
}

.column-4 {
  gap: var(--layout-column-4-gap);
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 42rem) {
  .column-4 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 60rem) {
  .column-4 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 70rem) {
  .column-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.centered {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.centered > div, .centered > section, .centered > article {
  width: auto;
}

body > header {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body > header > section, body > header > div {
  width: 100%;
  flex-direction: column;
  display: flex;
}

body > header > section > div, body > header > div > div {
  padding: 0;
}

:root {
  --header-navigation-base-align-items: var(--layout-base-align-items);
  --header-navigation-base-padding-top: var(--layout-base-padding-top);
  --header-navigation-base-padding-right: var(--layout-base-padding-right);
  --header-navigation-base-padding-bottom: var(--layout-base-padding-bottom);
  --header-navigation-base-paddin-leftg: var(--layout-base-padding-left);
  --header-navigation-base-max-width: var(--layout-base-max-width);
  --header-navigation-base-gap: var(--layout-base-gap);
  --header-navigation-base-breakpoint-1-padding: var(--header-navigation-base-padding);
  --header-navigation-base-breakpoint-2-padding: var(--header-navigation-base-breakpoint-1-padding);
}

header nav {
  background-color: var(--header-navigation-base-background-color);
  color: var(--header-navigation-base-text-color);
}

header nav > div {
  align-items: var(--header-navigation-base-align-items);
  padding-top: var(--header-navigation-base-padding-top);
  padding-right: var(--header-navigation-base-padding-right);
  padding-bottom: var(--header-navigation-base-padding-bottom);
  padding-left: var(--header-navigation-base-padding-left);
  box-sizing: border-box;
  max-width: var(--header-navigation-base-max-width);
  gap: var(--header-navigation-base-gap);
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 24rem) {
  header nav {
    padding: var(--header-navigation-base-breakpoint-1-padding);
  }
}

@media (min-width: 42rem) {
  header nav {
    padding: var(--header-navigation-base-breakpoint-2-padding);
  }
}

:root {
  --main-justify-content: flex-start;
  --main-base-padding-top: 5rem;
  --main-base-padding-bottom: 5rem;
}

body > main {
  justify-content: var(--main-justify-content);
  align-items: var(--main-align-items);
  padding: var(--main-padding);
  padding-top: var(--main-padding-top);
  padding-bottom: var(--main-padding-bottom);
  gap: var(--main-gap);
  width: 100%;
  box-sizing: border-box;
  background-color: var(--main-background-color);
  color: var(--main-text-color);
  margin: 0 auto;
  display: flex;
}

:root {
  --navigation-gap: 0, 5rem;
  --navigation-align-content: flex-end;
  --navigation-width: 100%;
  --navigation-max-width: 100%;
  --navigation-padding-top: 1rem;
  --navigation-padding-right: var(--layout-base-padding-right);
  --navigation-padding-bottom: 1rem;
  --navigation-padding-left: var(--layout-base-padding-left);
  --navigation-margin: 0;
  --navigation-content-block-padding-top: var(--layout-base-content-block-padding-top);
  --navigation-content-block-padding-right: var(--layout-base-content-block-padding-right);
  --navigation-content-block-padding-bottom: var(--layout-base-content-block-padding-bottom);
  --navigation-content-block-padding-left: var(--layout-base-content-block-padding-left);
  --navigation-content-block-max-width: var(--layout-base-content-block-max-width);
  --navigation-list-justify-content: flex-end;
  --navigation-link-hover-text-decoration: underline;
}

body > header {
  width: 100%;
  box-sizing: border-box;
}

body > header nav {
  align-items: stretch;
  justify-content: var(--navigation-align-content);
  width: var(--navigation-width);
  max-width: var(--navigation-max-width);
  margin: var(--navigation-margin);
  min-height: var(--navigation-min-height);
  gap: var(--navigation-gap);
  box-sizing: border-box;
  background-color: var(--navigation-background-color);
  padding-top: var(--navigation-padding-top);
  padding-right: var(--navigation-padding-right);
  padding-bottom: var(--navigation-padding-bottom);
  padding-left: var(--navigation-padding-left);
  flex-direction: row;
  display: flex;
}

body > header nav > div {
  align-items: var(--navigation-align-content);
  max-width: var(--navigation-content-block-max-width);
  padding-top: var(--navigation-content-block-padding-top);
  padding-right: var(--navigation-content-block-padding-right);
  padding-bottom: var(--navigation-content-block-padding-bottom);
  padding-left: var(--navigation-content-block-padding-left);
}

body > header nav ul, body > header nav ol {
  gap: var(--navigation-list-gap, .5rem);
  color: var(--navigation-text-color);
  justify-content: var(--navigation-list-justify-content);
  width: 100%;
  flex-flow: wrap;
  padding: 0;
  display: flex;
}

body > header nav ul li, body > header nav ol li {
  color: inherit;
  justify-content: var(--navigation-list-item-justify-content);
  list-style: none;
  display: flex;
}

body > header nav ul li a, body > header nav ul li a:visited, body > header nav ol li a, body > header nav ol li a:visited {
  color: inherit;
  text-decoration: var(--navigation-link-text-decoration);
}

body > header nav ul li a:hover, body > header nav ul li a:visited:hover, body > header nav ol li a:hover, body > header nav ol li a:visited:hover {
  text-decoration: var(--navigation-link-hover-text-decoration);
  color: inherit;
}

:root {
  --section-align-items: var(--layout-base-content-block-align-items);
  --section-gap: var(--layout-base-within-content-block-gap);
  --section-title-margin: var(--layout-base-content-block-title-margin);
  --section-subtitle-margin: var(--layout-base-content-block-subtitle-margin);
  --section-content-block-gap: var(--section-gap);
}

main section {
  align-items: var(--section-align-items);
  gap: var(--section-gap);
  background-color: var(--section-background-color);
  color: var(--section-text-color, inherit);
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
}

main section > div {
  align-items: var(--section-content-block-align-items);
  width: 100%;
  box-sizing: border-box;
  gap: var(--section-content-block-gap);
  flex-direction: column;
  margin: 0 auto;
  display: flex;
}

main section > div h1 {
  margin: var(--section-title-margin);
}

main section > div h2 {
  margin: var(--section-subtitle-margin);
}

main section h1 {
  margin: var(--section-title-margin);
}

main section h2 {
  margin: var(--section-subtitle-margin);
}

:root {
  --footer-min-height: 10rem;
  --footer-justify-content: flex-end;
  --footer-background-color: var(--branding-color-1-background-color);
  --footer-text-color: var(--branding-color-1-text-color);
  --footer-padding: var(--application-base-padding);
  --footer-link-text-color: var(--footer-text-color);
  --footer-link-text-decoration: none;
  --footer-link-hover-text-color: var(--footer-link-text-color);
  --footer-link-hover-text-decoration: underline;
}

body > footer {
  justify-content: var(--footer-justify-content);
  min-height: var(--footer-min-height);
  width: 100%;
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
  padding: var(--footer-padding);
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

body > footer h1, body > footer h2, body > footer h3, body > footer h4, body > footer h5, body > footer h6, body > footer p, body > footer span, body > footer li {
  color: var(--footer-text-color);
}

body > footer a, body > footer a:visited {
  color: var(--footer-link-text-color, inherit);
  text-decoration: var(--footer-link-text-decoration);
}

body > footer a:before, body > footer a:hover, body > footer a:visited:before, body > footer a:visited:hover {
  color: var(--footer-link-hover-text-color);
}

body > footer a:hover, body > footer a:visited:hover {
  text-decoration: var(--footer-link-hover-text-decoration);
}

:root {
  --breadcrumb-bar-background-color: var(--application-base-tint-1-background-color, var(--application-base-background-color));
  --breadcrumb-bar-text-color: var(--application-base-tint-1-text-color, var(--application-base-text-color));
  --breadcrumb-bar-content-block-padding-top: 1rem;
  --breadcrumb-bar-content-block-padding-right: 3%;
  --breadcrumb-bar-content-block-padding-bottom: 1rem;
  --breadcrumb-bar-content-block-padding-left: 3%;
  --breadcrumb-bar-content-block-gap: 1rem;
  --layout-header-content-block-max-width: 80rem;
  --breadcrumb-bar-content-block-list-vertical-align: center;
  --breadcrumb-bar-content-block-list-padding: 0;
  --breadcrumb-bar-content-block-list-gap: 1rem;
  --breadcrumb-bar-content-block-list-item-gap: 1rem;
  --breadcrumb-bar-icon-font-family: var(--icon-base-font-family);
  --breadcrumb-bar-content-block-icon: ">";
  --breadcrumb-bar-content-block-list-item-last-child-font-weight: bold;
  --breadcrumb-bar-content-block-link-text-decoration: none;
  --breadcrumb-bar-content-block-link-hover-text-decoration: none;
}

.breadcrumb-bar {
  width: 100%;
  background-color: var(--breadcrumb-bar-background-color);
  color: var(--breadcrumb-bar-text-color);
  flex-direction: column;
  padding: 0;
  display: flex;
}

.breadcrumb-bar > div {
  width: 100%;
  padding-top: var(--breadcrumb-bar-content-block-padding-top);
  padding-right: var(--breadcrumb-bar-content-block-padding-right);
  padding-bottom: var(--breadcrumb-bar-content-block-padding-bottom);
  padding-left: var(--breadcrumb-bar-content-block-padding-left);
  gap: var(--breadcrumb-bar-content-block-gap);
  box-sizing: border-box;
  max-width: var(--layout-header-content-block-max-width);
  flex-direction: row;
  align-self: center;
  display: flex;
}

.breadcrumb-bar > div ul, .breadcrumb-bar > div ol {
  justify-content: var(--breadcrumb-bar-content-block-justify-content);
  align-items: var(--breadcrumb-bar-content-block-list-vertical-align);
  padding: var(--breadcrumb-bar-content-block-list-padding);
  gap: var(--breadcrumb-bar-content-block-list-gap);
  color: inherit;
  flex-flow: wrap;
  display: flex;
}

.breadcrumb-bar > div ul li, .breadcrumb-bar > div ol li {
  align-items: center;
  gap: var(--breadcrumb-bar-content-block-list-item-gap);
  flex-flow: row;
  list-style: none;
  display: flex;
}

.breadcrumb-bar > div ul li:after, .breadcrumb-bar > div ol li:after {
  content: var(--breadcrumb-bar-content-block-icon);
  font-family: var(--breadcrumb-bar-icon-font-family, inherit);
  margin-left: var(--breadcrumb-bar-content-block-icon-margin-left);
  margin-right: var(--breadcrumb-bar-content-block-icon-margin-right);
}

.breadcrumb-bar > div ul li:hover, .breadcrumb-bar > div ol li:hover {
  color: var(--breadcrumb-bar-content-block-hover-text-color);
}

.breadcrumb-bar > div ul li:hover:after, .breadcrumb-bar > div ol li:hover:after {
  color: var(--breadcrumb-bar-content-block-text-color);
}

.breadcrumb-bar > div ul li:last-child, .breadcrumb-bar > div ol li:last-child {
  font-weight: var(--breadcrumb-bar-content-block-list-item-last-child-font-weight);
}

.breadcrumb-bar > div ul li:last-child:after, .breadcrumb-bar > div ol li:last-child:after {
  content: none;
}

.breadcrumb-bar > div ul li a, .breadcrumb-bar > div ol li a {
  word-break: break-word;
  color: inherit;
  text-decoration: var(--breadcrumb-bar-content-block-link-text-decoration);
}

.breadcrumb-bar > div ul li a:hover, .breadcrumb-bar > div ol li a:hover {
  text-decoration: var(--breadcrumb-bar-content-block-link-hover-text-decoration);
}

.breadcrumb-bar > div ul li a:before, .breadcrumb-bar > div ol li a:before {
  content: none;
}

:root {
  --navigation-collapsible-menu-list-collapsed-background-color: var(--application-base-accent-color-tint-2);
  --navigation-collapsible-menu-list-collapsed-text-color: var(--application-base-accent-color-tint-2-text-color);
  --navigation-collapsible-menu-list-item-collapsed-hover-background-color: var(--application-base-accent-color-tint-1);
  --navigation-collapsible-menu-list-item-collapsed-hover-text-color: var(--application-base-accent-color-tint-1-text-color);
  --navigation-collapsible-menu-list-item-collapsed-hover-background-color: #ccc;
  --navigation-collapsible-menu-list-item-collapsed-padding: .5rem 1rem;
  --navigation-collapsible-menu-list-item-hover-background-color: var(--application-base-accent-color-tint-1);
  --navigation-collapsible-menu-list-item-hover-text-color: var(--application-base-accent-color-tint-1-text-color);
  --navigation-collapsible-menu-button-background-color: transparent;
  --navigation-collapsible-menu-button-height: var(--font-size-text-line-height, 1.5rem);
  --navigation-collapsible-menu-button-min-height: var(--navigation-base-min-height);
  --navigation-collapsible-menu-button-min-width: 2.75rem;
  --navigation-collapsible-menu-icon: "≡";
  --navigation-collapsible-menu-icon-font-size: 1.5rem;
  --navigation-collapsible-menu-icon-color: var(--application-base-accent-color-text-color);
  --navigation-collapsible-menu-icon-collapsed: "x";
  --navigation-collapsible-menu-icon-collapsed-text-color: inherit;
}

.collapsible-menu {
  width: 100%;
  flex-flow: column;
  padding: 0;
  display: flex;
}

.collapsible-menu ul, .collapsible-menu ol {
  gap: var(--navigation-collapsible-menu-list-collapsed-gap);
  background-color: var(--navigation-collapsible-menu-list-collapsed-background-color);
  flex-direction: column;
  flex-grow: 1;
  order: 2;
  margin: 0;
  padding: 0;
  display: flex;
}

.collapsible-menu ul li, .collapsible-menu ol li {
  width: 100%;
  color: var(--navigation-collapsible-menu-list-collapsed-text-color);
  border-width: var(--navigation-collapsible-menu-list-item-collapsed-border-width);
  border-style: var(--navigation-collapsible-menu-list-item-collapsed-border-style);
  border-color: var(--navigation-collapsible-menu-list-item-collapsed-border-color);
  color: var(--navigation-collapsible-menu-list-collapsed-text-color);
  background-color: var(--navigation-collapsible-menu-list-item-collapsed-background-color);
  align-items: center;
  display: flex;
}

.collapsible-menu ul li:last-child, .collapsible-menu ol li:last-child {
  border-width: var(--navigation-collapsible-menu-list-item-last-child-collapsed-border-width, var(--navigation-collapsible-menu-list-item-collapsed-border-width));
  border-style: var(--navigation-collapsible-menu-list-item-last-child-collapsed-border-style, var(--navigation-collapsible-menu-list-item-collapsed-border-style));
  border-color: var(--navigation-collapsible-menu-list-item-last-child-collapsed-border-color, var(--navigation-collapsible-menu-list-item-collapsed-border-color));
}

.collapsible-menu ul li:hover, .collapsible-menu ol li:hover {
  background-color: var(--navigation-collapsible-menu-list-item-collapsed-hover-background-color);
  color: var(--navigation-collapsible-menu-list-item-hover-text-color);
}

.collapsible-menu ul li [aria-current], .collapsible-menu ol li [aria-current] {
  background-color: var(--navigation-collapsible-menu-list-item-hover-background-color);
  color: var(--navigation-collapsible-menu-list-item-hover-text-color);
}

.collapsible-menu ul li a, .collapsible-menu ol li a {
  width: 100%;
  max-width: 100%;
  padding: var(--navigation-collapsible-menu-list-item-collapsed-padding);
  box-sizing: border-box;
  text-decoration: var(--navigation-collapsible-link-text-decoration);
}

.collapsible-menu ul li a:hover, .collapsible-menu ol li a:hover {
  color: var(--navigation-collapsible-menu-list-item-hover-text-color);
}

.collapsible-menu button.menu_toggle {
  display: flex;
}

.collapsible-menu button.menu_toggle[aria-expanded="false"] + ul, .collapsible-menu button.menu_toggle[aria-expanded="false"] + ol {
  display: none;
}

button.menu_toggle {
  background-color: var(--navigation-collapsible-menu-button-background-color);
  color: var(--navigation-collapsible-menu-icon-color);
  font-size: var(--navigation-collapsible-menu-button-font-size, 0);
  height: var(--navigation-collapsible-menu-button-height);
  height: 100%;
  min-height: var(--navigation-collapsible-menu-button-min-height);
  min-width: var(--navigation-collapsible-menu-button-min-width);
  border: 0;
  order: 1;
  margin-left: auto;
  display: flex;
}

button.menu_toggle:after {
  content: var(--navigation-collapsible-menu-icon);
  font-size: var(--navigation-collapsible-menu-icon-font-size);
}

button.menu_toggle[aria-expanded="true"]:after {
  content: var(--navigation-collapsible-menu-icon-collapsed);
  color: var(--navigation-collapsible-menu-icon-collapsed-text-color);
}

button.menu_toggle[aria-expanded="false"] + ul, button.menu_toggle[aria-expanded="false"] + ol {
  display: none;
}

body > header nav {
  padding: var(--navigation-base-padding, 0 .5rem);
}

body > header nav > div {
  display: flex;
}

body > header nav ul {
  background-color: #0000;
  flex-direction: row;
  align-items: flex-start;
}

body > header nav ul li {
  height: auto;
  box-sizing: border-box;
  background-color: var(--navigation-collapsible-menu-list-item-background-color);
  color: var(--navigation-collapsible-menu-list-item-text-color);
  border: 0;
  justify-content: center;
  display: flex;
}

body > header nav ul li:hover {
  background-color: var(--navigation-collapsible-menu-list-item-background-color-hover);
  color: var(--navigation-collapsible-menu-list-item-hover-text-color);
  border-color: var(--application-base-accent-color-tint-1);
}

body > header nav ul li a {
  padding: var(--navigation-collapsible-menu-list-item-link-padding);
}

body > header nav ul li:last-child {
  border: inherit;
}

body > header nav button.menu_toggle {
  display: none;
}

body > header nav button.menu_toggle[aria-expanded="false"] + ul, body > header nav button.menu_toggle[aria-expanded="false"] + ol {
  display: flex;
}

:root {
  --accordion-gap: .5rem;
  --accordion-button-padding: 0 1rem;
  --accordion-button-justify-content: flex-start;
  --accordion-button-background-color: var(--button-base-background-color);
  --accordion-button-text-color: var(--button-base-text-color);
  --accordion-button-icon-before-font-size: 1rem;
  --accordion-button-icon-after-font-size: 1rem;
  --accordion-button-icon-font-family: var(--icon-base-font-family);
  --accordion-button-icon-after-open-content: "v";
  --accordion-button-icon-after-close-content: "^";
  --accordion-button-icon-after-margin-left: auto;
  --accordion-content-padding: 2rem 1rem;
  --accordion-content-gap: 1rem;
  --accordion-content-border-width: 1px;
  --accordion-content-border-style: solid;
  --accordion-content-border-color: #ccc;
  --accordion-content-background-color: #f3f3f3;
  --accordion-content-text-color: var(--application-base-text-color);
}

.accordion, ul.accordion {
  gap: var(--accordion-gap);
  flex-direction: column;
  display: flex;
}

.accordion > div > button, ul.accordion > li > button {
  width: 100%;
  padding: var(--accordion-button-padding);
  font-size: var(--accordion-font-size, inherit);
  justify-content: var(--accordion-button-justify-content);
  line-height: var(--accordion-button-line-height, inherit);
  color: var(--accordion-button-text-color, inherit);
  background-color: var(--accordion-button-background-color, inherit);
  text-decoration: none;
}

.accordion > div > div, ul.accordion > li > div {
  gap: var(--accordion-content-gap);
  padding: var(--accordion-content-padding);
  font-size: var(--accordion-content-font-size, inherit);
  font-weight: var(--accordion-content-font-weight, inherit);
  line-height: var(--accordion-content-line-height, inherit);
  border-width: var(--accordion-content-border-width);
  border-style: var(--accordion-content-border-style);
  border-color: var(--accordion-content-border-color);
  background-color: var(--accordion-content-background-color);
  color: var(--accordion-content-text-color, inherit);
  flex-direction: column;
  display: flex;
}

.accordion > div > div > :first-child, ul.accordion > li > div > :first-child {
  margin-top: 0;
}

ul.accordion {
  width: 100%;
  padding-left: 0;
}

ul.accordion > li {
  width: 100%;
  list-style: none;
}

section.accordion > div {
  gap: 0;
}

.accordion > div {
  width: 100%;
}

.js-accordion-loaded .accordion button[aria-expanded="false"]:before {
  content: var(--accordion-button-icon-before-open-content, none);
  font-family: var(--accordion-button-icon-font-family, inherit);
  font-size: var(--accordion-button-icon-before-font-size);
  font-weight: var(--accordion-button-icon-before-font-weight);
  white-space: var(--accordion-button-icon-before-white-space);
}

.js-accordion-loaded .accordion button[aria-expanded="false"]:after {
  content: var(--accordion-button-icon-after-open-content, none);
  font-family: var(--accordion-button-icon-font-family, inherit);
  font-size: var(--accordion-button-icon-after-font-size);
  font-weight: var(--accordion-button-icon-after-font-weight);
  white-space: var(--accordion-button-icon-after-white-space);
  margin-left: auto;
}

.js-accordion-loaded .accordion button[aria-expanded="false"] + div {
  display: none;
}

.js-accordion-loaded .accordion button[aria-expanded="true"]:before {
  content: var(--accordion-button-icon-before-close-content, none);
  font-family: var(--accordion-button-icon-font-family, inherit);
  font-size: var(--accordion-button-icon-before-font-size);
  font-weight: var(--accordion-button-icon-before-font-weight);
  white-space: var(--accordion-button-icon-before-white-space);
}

.js-accordion-loaded .accordion button[aria-expanded="true"]:after {
  content: var(--accordion-button-icon-after-close-content, none);
  font-family: var(--accordion-button-icon-font-family, inherit);
  font-size: var(--accordion-button-icon-after-font-size);
  font-weight: var(--accordion-button-icon-after-font-weight);
  white-space: var(--accordion-button-icon-after-white-space);
  margin-left: var(--accordion-button-icon-after-margin-left);
}

:root {
  --filter-intro-padding: .5rem 0;
  --filter-intro-border-width: 0 0 1px 0;
  --filter-intro-border-style: solid;
  --filter-intro-border-color: #ccc;
  --filter-intro-text-color: #6c6c6c;
  --filter-intro-span-font-size: 1.5rem;
  --filter-intro-span-text-color: #000;
  --filter-button-padding: 0 .5rem;
  --filter-button-margin-left: auto;
  --filter-button-font-size: 0;
  --filter-button-icon-font-family: var(--icon-base-font-family);
  --filter-button-icon-font-size: var(--icon-base-font-size);
  --filter-button-icon-font-weight: bold;
  --filter-button-icon-line-height: 1;
  --filter-button-icon-before-open-content: "v";
  --filter-button-icon-before-close-content: "^";
}

.filter > form[hidden] {
  display: none;
}

.filter > div {
  width: 100%;
  border-width: var(--filter-intro-border-width);
  border-style: var(--filter-intro-border-style);
  border-color: var(--filter-intro-border-color);
  font-size: var(--filter-intro-font-size, inherit);
  color: var(--filter-intro-text-color, inherit);
  flex-flow: wrap;
  align-items: center;
  padding: 0;
  display: flex;
}

.filter > div p {
  font-size: inherit;
  color: inherit;
}

.filter > div p > span {
  font-size: var(--filter-intro-span-font-size, inherit);
  color: var(--filter-intro-span-text-color, inherit);
}

.filter > div button {
  background-color: var(--filter-button-background-color, inherit);
  color: var(--filter-button-text-color, inherit);
  border-width: var(--filter-button-border-width);
  border-style: var(--filter-button-border-style);
  border-color: var(--filter-button-border-color);
  padding: var(--filter-button-padding);
  min-height: var(--filter-button-min-height);
  margin-left: var(--filter-button-margin-left);
  font-size: var(--filter-button-font-size);
  min-width: 0;
  display: none;
}

.filter > div button:after {
  font-family: var(--filter-button-icon-font-family, inherit);
  line-height: var(--filter-button-icon-line-height);
  font-size: var(--filter-button-icon-font-size, inherit);
  font-weight: var(--filter-button-icon-font-weight, inherit);
}

.filter > div button[aria-expanded="true"]:after {
  content: var(--filter-button-icon-before-close-content);
}

.filter > div button[aria-expanded="false"]:after {
  content: var(--filter-button-icon-before-open-content);
}

.js-filters-loaded .filter > div > button {
  display: inline-flex;
}

:root {
  --description-list-item-padding: .5rem 1rem;
  --description-list-item-odd-background-color: var(--application-base-tint-1-background-color, #f0f0f0);
}

dl {
  width: var(--description-list-width, 100%);
  max-width: var(--description-list-max-width);
}

dl > div {
  gap: var(--description-list-item-gap, 0 1rem);
  grid-template-columns: 1fr;
  display: grid;
}

dl > div:nth-child(2n+1) {
  background-color: var(--description-list-item-odd-background-color);
}

dl dt, dl dd {
  white-space: pre-wrap;
  word-break: break-word;
  padding: var(--description-list-item-padding, .5rem 0);
  margin: 0;
}

dl dt {
  font-weight: var(--description-list-item-title-font-weight, bolder);
  background-color: var(--description-list-item-title-background-color);
  color: var(--description-list-item-title-text-color);
}

dl dd {
  background-color: var(--description-list-item-data-background-color);
  color: var(--description-list-item-data-text-color);
}

@media (min-width: 24rem) {
  dl div {
    grid-template-columns: 1fr 2fr;
  }
}

:root {
  --hero-height: 30rem;
  --hero-title-font-size: 2rem;
  --hero-image-object-position: center;
}

.hero {
  width: 100%;
  min-height: var(--hero-min-height);
  height: var(--hero-height);
  max-height: var(--hero-max-height);
  display: flex;
  position: relative;
}

.hero img {
  object-fit: cover;
  object-position: var(--hero-image-object-position);
  width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: -1;
  padding: 0;
  position: absolute;
  top: 0;
}

.hero h1 {
  font-size: var(--hero-title-font-size);
}

:root {
  --pagination-border-width: 1px 0 0 0;
  --pagination-border-style: solid;
  --pagination-border-color: #d7d7d7;
  --pagination-padding-top: .5rem;
  --pagination-gap: .25rem;
  --pagination-item-height: 2rem;
  --pagination-item-width: 2rem;
  --pagination-item-padding: 0 .5rem;
  --pagination-item-text-color: var(--application-base-text-color);
  --pagination-item-text-decoration: none;
  --pagination-item-active-background-color: var(--application-base-accent-color);
  --pagination-item-active-text-color: var(--application-base-accent-color-text-color);
}

.pagination > ul {
  padding: 0;
  padding-top: var(--pagination-padding-top);
  border-width: var(--pagination-border-width);
  border-style: var(--pagination-border-style);
  border-color: var(--pagination-border-color);
  gap: var(--pagination-gap);
  flex-direction: row;
  display: flex;
}

.pagination > ul > li {
  margin-top: -.1rem;
  padding: 0;
  list-style: none;
}

.pagination > ul > li > a {
  box-sizing: border-box;
  padding: var(--pagination-item-padding);
  color: var(--pagination-item-text-color);
  background-color: var(--pagination-item-background-color, transparent);
  height: var(--pagination-item-height);
  min-width: 2rem;
  text-decoration: var(--pagination-item-text-decoration);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.pagination > ul > li > a[aria-current] {
  background-color: var(--pagination-item-active-background-color);
  color: var(--pagination-item-active-text-color);
}

.pagination > ul > li > a:before {
  content: none;
}

.pagination > ul > li:hover {
  background-color: var(--pagination-item-active-background-color);
}

.pagination > ul > li:hover span, .pagination > ul > li:hover a {
  color: var(--pagination-item-active-text-color);
}

:root {
  --tabs-border-width: 0 0 1px 0;
  --tabs-border-style: solid;
  --tabs-border-color: gray;
  --tabs-gap: 1rem;
  --tabs-item-text-color: gray;
  --tabs-item-padding: .5rem;
  --tabs-item-text-decoration: none;
  --tabs-item-hover-border-width: 0 0 1px 0;
  --tabs-item-hover-border-style: solid;
  --tabs-item-hover-border-color: var(--application-base-accent-color);
  --tabs-item-hover-text-color: var(--application-base-accent-color);
  --tabs-item-active-border-width: 0 0 1px 0;
  --tabs-item-active-border-style: solid;
  --tabs-item-active-border-color: var(--application-base-accent-color);
  --tabs-item-active-text-color: var(--application-base-accent-color);
}

.tabs {
  white-space: nowrap;
  background-color: var(--tabs-background-color, transparent);
  overflow: auto hidden;
}

.tabs ul {
  border-width: var(--tabs-border-width);
  border-style: var(--tabs-border-style);
  border-color: var(--tabs-border-color);
  justify-content: flex-start;
  gap: var(--tabs-gap);
  flex-direction: row;
  padding-left: 0;
  display: flex;
}

.tabs ul li {
  list-style: none;
}

.tabs ul li > a {
  border-width: var(--tabs-item-border-width);
  border-style: var(--tabs-item-border-style);
  border-color: var(--tabs-item-border-color);
  color: var(--tabs-item-text-color, inherit);
  line-height: var(--tabs-item-line-height, inherit);
  padding: var(--tabs-item-padding);
  background-color: var(--tabs-item-background-color, transparent);
  text-decoration: var(--tabs-item-text-decoration);
  white-space: nowrap;
}

.tabs ul li > a:hover {
  border-width: var(--tabs-item-hover-border-width);
  border-style: var(--tabs-item-hover-border-style);
  border-color: var(--tabs-item-hover-border-color);
  color: var(--tabs-item-hover-text-color, inherit);
  background-color: var(--tabs-item-hover-background-color, transparent);
}

.tabs ul li[aria-current="page"] > a {
  border-width: var(--tabs-item-active-border-width);
  border-style: var(--tabs-item-active-border-style);
  border-color: var(--tabs-item-active-border-color);
  color: var(--tabs-item-active-text-color, inherit);
  background-color: var(--tabs-item-active-background-color, transparent);
}

:root {
  --code-base-base-background-color: var(--application-base-tint-1-background-color, var(--application-base-background-color));
  --code-base-base-text-color: var(--application-base-tint-1-text-color, var(--application-base-text-color));
  --code-base-font-size: inherit;
}

code {
  background-color: var(--code-base-background-color, inherit);
  color: var(--code-base-text-color, inherit);
  font-size: var(--code-base-font-size, inherit);
  width: 100%;
  word-break: var(--code-base-word-break, inherit);
}

@media (min-width: 24rem) {
  code {
    word-break: var(----code-base-breakpoint-word-break, break-word);
  }
}

:root {
  --button-base-flex: inline-flex;
  --button-base-gap: .5rem;
  --button-base-padding-top: .25rem;
  --button-base-padding-right: 1rem;
  --button-base-padding-bottom: .25rem;
  --button-base-padding-left: 1rem;
  --button-base-justify-content: center;
  --button-base-min-width: 8rem;
  --button-base-min-height: 2.75rem;
  --button-base-background-color: var(--application-base-accent-color);
  --button-base-text-color: var(--application-base-accent-color-text-color);
  --button-base-border-width: none;
  --button-base-border-style: unset;
  --button-base-border-color: transparent;
  --button-base-font-size: var(--application-base-font-size);
  --button-base-line-height: var(--application-base-line-height);
  --button-base-font-weight: var(--application-base-font-weight);
  --button-base-text-decoration: none;
  --button-base-border-radius: var(--application-base-border-radius);
  --button-base-align-self: flex-end;
  --button-base-hover-background-color: var(--application-base-accent-color-hover, var(--application-base-accent-color));
  --button-base-hover-text-color: var(--application-base-accent-color-hover-text-color, var(--application-base-accent-color-text-color));
  --button-base-active-background-color: var(--application-base-accent-color-active, var(--application-base-accent-color));
  --button-base-active-text-color: var(--application-base-accent-color-active-text-color, var(--application-base-accent-color-text-color));
  --button-base-focus-background-color: var(--application-base-accent-color-focus, var(--application-base-accent-color));
  --button-base-focus-text-color: var(--application-base-accent-color-focus-text-color, var(--application-base-accent-color-text-color));
  --button-base-selected-background-color: var(--application-base-accent-color-selected, var(--application-base-accent-color));
  --button-base-selected-text-color: var(--application-base-accent-color-selected-text-color, var(--application-base-accent-color-text-color));
  --button-base-image-max-width: 1.5rem;
}

button, a.button, input[type="button"], input[type="submit"], input[type="reset"] {
  display: var(--button-base-flex);
  box-sizing: border-box;
  align-self: flex-start;
  align-items: center;
  justify-content: var(--button-base-justify-content);
  gap: var(--button-base-gap);
  padding-top: var(--button-base-padding-top);
  padding-right: var(--button-base-padding-right);
  padding-bottom: var(--button-base-padding-bottom);
  padding-left: var(--button-base-padding-left);
  min-width: var(--button-base-min-width);
  min-height: var(--button-base-min-height);
  background-color: var(--button-base-background-color);
  border-width: var(--button-base-border-width);
  border-style: var(--button-base-border-style);
  border-color: var(--button-base-border-color);
  color: var(--button-base-text-color, inherit);
  font-size: var(--button-base-font-size, inherit);
  font-weight: var(--button-base-font-weight, inherit);
  line-height: var(--button-base-line-height, inherit);
  text-decoration: var(--button-base-text-decoration);
  border-radius: var(--button-base-border-radius, inherit);
  webkit-border-radius: var(--button-base-border-radius);
  -webkit-appearance: var(--button-base-webkit-appearance);
  cursor: pointer;
  width: auto;
}

button:visited, a.button:visited, input[type="button"]:visited, input[type="submit"]:visited, input[type="reset"]:visited {
  color: var(--button-base-text-color);
}

button:hover, button.hover, a.button:hover, a.button.hover, input[type="button"]:hover, input[type="button"].hover, input[type="submit"]:hover, input[type="submit"].hover, input[type="reset"]:hover, input[type="reset"].hover {
  background-color: var(--button-base-hover-background-color);
  color: var(--button-base-hover-text-color);
  border-color: var(--button-base-hover-background-color);
}

button:active, button.active, a.button:active, a.button.active, input[type="button"]:active, input[type="button"].active, input[type="submit"]:active, input[type="submit"].active, input[type="reset"]:active, input[type="reset"].active {
  outline-style: var(--button-base-active-outline-style);
  outline-color: var(--button-base-active-outline-color);
  outline-width: var(--button-base-active-outline-width);
  outline-offset: var(--button-base-active-outline-offset);
  background-color: var(--button-base-active-background-color);
  color: var(--button-base-active-text-color);
  border-style: var(--button-base-active-border-style);
  border-color: var(--button-base-active-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-base-active-border-width);
}

button:focus, button.focus, a.button:focus, a.button.focus, input[type="button"]:focus, input[type="button"].focus, input[type="submit"]:focus, input[type="submit"].focus, input[type="reset"]:focus, input[type="reset"].focus {
  outline-style: var(--button-base-focus-outline-style);
  outline-color: var(--button-base-focus-outline-color);
  outline-width: var(--button-base-focus-outline-width);
  outline-offset: var(--button-base-focus-outline-offset);
  border-style: var(--button-base-focus-border-style);
  border-color: var(--button-base-focus-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-base-focus-border-width);
  background-color: var(--button-base-focus-background-color, var(--application-base-accent-color-accent));
  color: var(--button-base-focus-text-color, var(--application-text-color-accent));
}

button.selected, a.button.selected, input[type="button"].selected, input[type="submit"].selected, input[type="reset"].selected {
  outline-style: var(--button-base-focus-outline-style);
  outline-color: var(--button-base-focus-outline-color);
  outline-width: var(--button-base-focus-outline-width);
  outline-offset: var(--button-base-focus-outline-offset);
  border-style: var(--button-base-focus-border-style);
  border-color: var(--button-base-focus-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-base-focus-border-width);
  background-color: var(--button-base-selected-background-color, var(--application-base-accent-color-accent));
  color: var(--button-base-selected-text-color, var(--application-text-color-accent));
}

button > img, a.button > img, input[type="button"] > img, input[type="submit"] > img, input[type="reset"] > img {
  max-width: var(--button-base-image-max-width);
}

:root {
  --button-ghost-background-color: transparent;
  --button-ghost-text-color: var(--application-base-accent-color);
  --button-ghost-border-width: 2px;
  --button-ghost-border-style: solid;
  --button-ghost-border-color: var(--application-base-accent-color);
  --button-ghost-border-radius: var(--button-base-border-radius);
  --button-ghost-hover-background-color: var(--application-base-accent-color);
  --button-ghost-hover-text-color: var(--application-base-accent-color-text-color);
  --button-ghost-hover-border-width: var(--button-ghost-border-width);
  --button-ghost-hover-border-style: var(--button-ghost-border-style);
  --button-ghost-hover-border-color: var(--button-ghost-border-color);
  --button-ghost-hover-border-radius: var(--button-ghost-border-radius);
  --button-ghost-active-border-width: var(--button-ghost-border-width);
  --button-ghost-active-border-style: var(--button-ghost-border-style);
  --button-ghost-active-border-color: var(--button-ghost-border-color);
  --button-ghost-active-background-color: var(--button-ghost-background-color);
  --button-ghost-active-text-color: var(--button-ghost-text-color);
  --button-ghost-focus-border-width: var(--button-ghost-border-width);
  --button-ghost-focus-border-style: var(--button-ghost-border-style);
  --button-ghost-focus-border-color: var(--button-ghost-border-color);
  --button-ghost-focus-background-color: var(--button-ghost-background-color);
  --button-ghost-focus-text-color: var(--button-ghost-text-color);
}

button.ghost, a.button.ghost, input[type="button"].ghost, input[type="submit"].ghost, input[type="reset"].ghost {
  outline-style: var(--button-ghost-outline-style);
  outline-color: var(--button-ghost-outline-color);
  outline-width: var(--button-ghost-outline-width);
  outline-offset: var(--button-ghost-outline-offset);
  background-color: var(--button-ghost-background-color);
  border-width: var(--button-ghost-border-width);
  border-style: var(--button-ghost-border-style);
  border-color: var(--button-ghost-border-color);
  color: var(--button-ghost-text-color);
  -webkit-appearance: none;
  border-radius: var(--button-ghost-border-radius);
  webkit-border-radius: var(--button-ghost-border-radius);
}

button.ghost:visited, a.button.ghost:visited, input[type="button"].ghost:visited, input[type="submit"].ghost:visited, input[type="reset"].ghost:visited {
  color: var(--button-ghost-text-color);
}

button.ghost:hover, button.ghost.hover, a.button.ghost:hover, a.button.ghost.hover, input[type="button"].ghost:hover, input[type="button"].ghost.hover, input[type="submit"].ghost:hover, input[type="submit"].ghost.hover, input[type="reset"].ghost:hover, input[type="reset"].ghost.hover {
  outline-style: var(--button-ghost-hover-outline-style);
  outline-color: var(--button-ghost-hover-outline-color);
  outline-width: var(--button-ghost-hover-outline-width);
  outline-offset: var(--button-ghost-hover-outline-offset);
  background-color: var(--button-ghost-hover-background-color);
  color: var(--button-ghost-hover-text-color);
  border-style: var(--button-ghost-hover-border-style);
  border-color: var(--button-ghost-hover-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-ghost-hover-border-width);
}

button.ghost:active, button.ghost.active, a.button.ghost:active, a.button.ghost.active, input[type="button"].ghost:active, input[type="button"].ghost.active, input[type="submit"].ghost:active, input[type="submit"].ghost.active, input[type="reset"].ghost:active, input[type="reset"].ghost.active {
  outline-style: var(--button-ghost-active-outline-style);
  outline-color: var(--button-ghost-active-outline-color);
  outline-width: var(--button-ghost-active-outline-width);
  outline-offset: var(--button-ghost-active-outline-offset);
  background-color: var(--button-ghost-active-background-color);
  color: var(--button-ghost-active-text-color);
  border-style: var(--button-ghost-active-border-style);
  border-color: var(--button-ghost-active-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-ghost-active-border-width);
}

button.ghost:focus, button.ghost.focus, a.button.ghost:focus, a.button.ghost.focus, input[type="button"].ghost:focus, input[type="button"].ghost.focus, input[type="submit"].ghost:focus, input[type="submit"].ghost.focus, input[type="reset"].ghost:focus, input[type="reset"].ghost.focus {
  outline-style: var(--button-ghost-focus-outline-style);
  outline-color: var(--button-ghost-focus-outline-color);
  outline-width: var(--button-ghost-focus-outline-width);
  outline-offset: var(--button-ghost-focus-outline-offset);
  border-style: var(--button-ghost-focus-border-style);
  border-color: var(--button-ghost-focus-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-ghost-focus-border-width);
  background-color: var(--button-ghost-focus-background-color, var(--application-base-accent-color-accent));
  color: var(--button-ghost-focus-text-color, var(--application-text-color-accent));
}

button.ghost > img, a.button.ghost > img, input[type="button"].ghost > img, input[type="submit"].ghost > img, input[type="reset"].ghost > img {
  max-width: var(--button-base-image-max-width);
}

:root {
  --button-cta-background-color: var(--cta-background-color);
  --button-cta-text-color: var(--cta-text-color);
  --button-cta-border-width: 2px;
  --button-cta-border-style: solid;
  --button-cta-border-color: var(--cta-background-color);
  --button-cta-border-radius: var(--button-base-border-radius);
  --button-cta-hover-background-color: var(--cta-background-color-accent, var(--button-cta-background-color));
  --button-cta-hover-text-color: var(--cta-text-color, var(--button-cta-text-color));
  --button-cta-hover-border-width: var(--button-cta-border-width);
  --button-cta-hover-border-style: var(--button-cta-border-style);
  --button-cta-hover-border-color: var(--button-cta-border-color);
  --button-cta-hover-border-radius: var(--button-cta-border-radius);
  --button-cta-active-border-width: var(--button-cta-border-width);
  --button-cta-active-border-style: var(--button-cta-border-style);
  --button-cta-active-border-color: var(--button-cta-border-color);
  --button-cta-active-background-color: var(--button-cta-background-color);
  --button-cta-active-text-color: var(--button-cta-text-color);
  --button-cta-focus-border-width: var(--button-cta-border-width);
  --button-cta-focus-border-style: var(--button-cta-border-style);
  --button-cta-focus-border-color: var(--button-cta-border-color);
  --button-cta-focus-background-color: var(--button-cta-background-color);
  --button-cta-focus-text-color: var(--button-cta-text-color);
}

button.cta, a.button.cta, input[type="button"].cta, input[type="submit"].cta, input[type="reset"].cta {
  outline-style: var(--button-cta-outline-style);
  outline-color: var(--button-cta-outline-color);
  outline-width: var(--button-cta-outline-width);
  outline-offset: var(--button-cta-outline-offset);
  background-color: var(--button-cta-background-color);
  border-width: var(--button-cta-border-width);
  border-style: var(--button-cta-border-style);
  border-color: var(--button-cta-border-color);
  color: var(--button-cta-text-color);
  -webkit-appearance: none;
  border-radius: var(--button-cta-border-radius);
  webkit-border-radius: var(--button-cta-border-radius);
}

button.cta:visited, a.button.cta:visited, input[type="button"].cta:visited, input[type="submit"].cta:visited, input[type="reset"].cta:visited {
  color: var(--button-cta-text-color);
}

button.cta:hover, button.cta.hover, a.button.cta:hover, a.button.cta.hover, input[type="button"].cta:hover, input[type="button"].cta.hover, input[type="submit"].cta:hover, input[type="submit"].cta.hover, input[type="reset"].cta:hover, input[type="reset"].cta.hover {
  outline-style: var(--button-cta-hover-outline-style);
  outline-color: var(--button-cta-hover-outline-color);
  outline-width: var(--button-cta-hover-outline-width);
  outline-offset: var(--button-cta-hover-outline-offset);
  background-color: var(--button-cta-hover-background-color);
  color: var(--button-cta-hover-text-color);
  border-style: var(--button-cta-hover-border-style);
  border-color: var(--button-cta-hover-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-cta-hover-border-width);
}

button.cta:active, button.cta.active, a.button.cta:active, a.button.cta.active, input[type="button"].cta:active, input[type="button"].cta.active, input[type="submit"].cta:active, input[type="submit"].cta.active, input[type="reset"].cta:active, input[type="reset"].cta.active {
  outline-style: var(--button-cta-active-outline-style);
  outline-color: var(--button-cta-active-outline-color);
  outline-width: var(--button-cta-active-outline-width);
  outline-offset: var(--button-cta-active-outline-offset);
  background-color: var(--button-cta-active-background-color);
  color: var(--button-cta-active-text-color);
  border-style: var(--button-cta-active-border-style);
  border-color: var(--button-cta-active-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-cta-active-border-width);
}

button.cta:focus, button.cta.focus, a.button.cta:focus, a.button.cta.focus, input[type="button"].cta:focus, input[type="button"].cta.focus, input[type="submit"].cta:focus, input[type="submit"].cta.focus, input[type="reset"].cta:focus, input[type="reset"].cta.focus {
  outline-style: var(--button-cta-focus-outline-style);
  outline-color: var(--button-cta-focus-outline-color);
  outline-width: var(--button-cta-focus-outline-width);
  outline-offset: var(--button-cta-focus-outline-offset);
  border-style: var(--button-cta-focus-border-style);
  border-color: var(--button-cta-focus-border-color, var(--application-base-accent-color-accent));
  border-width: var(--button-cta-focus-border-width);
  background-color: var(--button-cta-focus-background-color, var(--application-base-accent-color-accent));
  color: var(--button-cta-focus-text-color, var(--application-text-color-accent));
}

:root {
  --button-icon-min-width: var(--icon-base-min-width);
  --button-icon-base-width: var(--icon-base-width);
  --button-icon-min-height: var(--icon-base-min-height);
  --button-icon-base-height: var(--icon-base-height);
  --button-icon-font-size: var(--icon-base-font-size);
  --button-icon-font-family: var(--icon-base-font-family);
}

button.icon, a.button.icon, input[type="button"].icon, input[type="submit"].icon, input[type="reset"].icon {
  min-width: var(--button-icon-min-width);
  width: var(--button-icon-width);
  min-height: var(--button-icon-min-height);
  height: var(--button-icon-height);
  gap: 0;
  font-size: 0;
}

button.icon:before, a.button.icon:before, input[type="button"].icon:before, input[type="submit"].icon:before, input[type="reset"].icon:before {
  font-size: var(--button-icon-font-size);
  font-family: var(--icon-base-font-family);
  text-align: center;
  color: var(--button-icon-text-color, inherit);
  margin-right: 0;
  line-height: 1;
}

:root {
  --list-base-line-height: 2.25;
}

ul, ol {
  gap: var(--list-base-gap, .5rem);
  padding: var(--list-base-padding, 0 0 0 2rem);
  background-color: var(--list-base-background-color, inherit);
  line-height: var(--list-base-line-height, inherit);
  font-size: var(--list-base-font-size, inherit);
  font-weight: var(--list-base-font-weight, inherit);
  color: var(--list-base-text-color, inherit);
  flex-direction: column;
  margin: 0;
  display: flex;
}

ul li, ol li {
  background-color: var(--list-base-item-background-color);
  border-color: var(--list-base-item-border-color);
  border-style: var(--list-base-item-border-style);
  border-width: var(--list-base-item-border-width);
  padding: var(--list-base-item-padding);
  line-height: var(--list-base-line-height, inherit);
  font-size: var(--list-base-font-size, inherit);
  font-weight: var(--list-base-font-weight, inherit);
  color: var(--list-base-text-color, inherit);
}

ul li a, ol li a {
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
}

ul > li {
  list-style: var(--list-base-item-style, initial);
}

ol > li {
  display: list-item;
}

:root {
  --skip-to-content-base-background-color: var(--application-base-accent-color);
  --skip-to-content-base-text-color: var(--application-base-accent-color-text-color);
  --skip-to-content-base-border-color: var(--application-base-accent-color);
  --skip-to-content-base-hover-background-color: var(--application-base-accent-color-hover);
  --skip-to-content-base-hover-text-color: var(--application-base-accent-color-hover-text-color);
  --skip-to-content-base-hover-border-color: var(--application-base-accent-color-hover);
}

.skip-to-content, a.button.skip-to-content {
  min-height: 0;
  background-color: var(--skip-to-content-base-background-color);
  color: var(--skip-to-content-base-text-color);
  padding: 0;
  font-size: 0;
  display: block;
}

.skip-to-content:hover, a.button.skip-to-content:hover {
  background-color: var(--skip-to-content-base-hover-background-color);
  color: var(--skip-to-content-base-hover-text-color);
  border-width: var(--skip-to-content-base-hover-border-width);
  border-style: var(--skip-to-content-base-hover-border-style);
  border-color: var(--skip-to-content-base-hover-border-color);
}

.skip-to-content:focus, a.button.skip-to-content:focus {
  height: auto;
  width: auto;
  padding: var(--skip-to-content-base-padding, .5rem);
  text-align: var(--skip-to-content-base-text-align, center);
  font-size: var(--application-base-font-size);
  min-height: var(--skip-to-content-base-min-height, 2.75rem);
  border-width: var(--skip-to-content-base-border-width);
  border-style: var(--skip-to-content-base-border-style);
  border-color: var(--skip-to-content-base-border-color);
  display: flex;
  position: static;
}

:root {
  --logo-min-width: 2.65rem;
  --logo-img-min-height: 2rem;
  --logo-img-max-width: 100%;
  --logo-img-max-height: 2.5rem;
  --logo-img-object-position: center;
  --logo-img-object-fit: contain;
}

.logo {
  min-width: var(--logo-min-width);
  width: var(--logo-width);
  max-width: var(--logo-max-width);
  font-size: 0;
}

.logo img {
  min-height: var(--logo-img-min-height);
  height: var(--logo-img-height);
  min-width: var(--logo-img-min-width);
  width: var(--logo-img-width);
  max-width: var(--logo-img-max-width);
  max-height: var(--logo-img-max-height);
  object-position: var(--logo-img-object-position);
  object-fit: var(--logo-img-object-fit);
}

:root {
  --tile-gap: 1rem;
  --tile-padding-top: 1.5rem;
  --tile-padding-right: 1.5rem;
  --tile-padding-bottom: 1.5rem;
  --tile-padding-left: 1.5rem;
  --tile-border-width: 1px;
  --tile-border-style: solid;
  --tile-border-color: #ccc;
  --tile-title-font-size: var(--font-size-text);
  --tile-subtitle-font-size: var(--font-size-text);
  --tiles-align-items: stretch;
}

ul.tiles > li, .tiles > div, .tile {
  flex-direction: var(--tile-flex-direction, column);
  gap: var(--tile-gap);
  border-width: var(--tile-border-width);
  border-style: var(--tile-border-style);
  border-color: var(--tile-border-color);
  box-sizing: border-box;
  background-color: var(--tile-background-color);
  color: var(--tile-text-color);
  padding-top: var(--tile-padding-top);
  padding-right: var(--tile-padding-right);
  padding-bottom: var(--tile-padding-bottom);
  padding-left: var(--tile-padding-left);
  border-radius: var(--tile-border-radius);
  display: flex;
}

ul.tiles > li > *, .tiles > div > *, .tile > * {
  box-sizing: border-box;
  width: 100%;
  color: inherit;
}

ul.tiles > li h1, .tiles > div h1, .tile h1 {
  font-size: var(--tile-title-font-size-text);
  color: var(--tile-title-text-color, inherit);
}

ul.tiles > li h2, .tiles > div h2, .tile h2 {
  font-size: var(--tile-subtitle-font-size-text);
  color: var(--tile-subtitle-text-color, inherit);
}

ul.tiles > li ul, .tiles > div ul, .tile ul {
  padding-left: 2rem;
}

.tiles {
  align-items: var(--tiles-align-items);
}

ul.tiles {
  padding-left: 0;
}

ul.tiles > li > a {
  color: var(--text-dark);
  text-decoration: none;
}

:root {
  --tile-image-cover-max-height: 12rem;
  --tile-image-cover-margin-top: var(--tile-padding-top);
  --tile-image-cover-margin-right: var(--tile-padding-right);
  --tile-image-cover-margin-left: var(--tile-padding-left);
}

.tiles.images-cover > div img, .tiles.images-cover li img, .tile.image-cover img {
  object-position: var(--tile-image-cover-object-position, var(--image-cover-object-position));
  max-height: var(--tile-image-cover-max-height);
  width: calc(100% + var(--tile-padding-right)  + var(--tile-padding-left) );
  max-width: unset;
  margin-top: calc(-1 * var(--tile-image-cover-margin-top) );
  margin-right: calc(-1 * var(--tile-image-cover-margin-right) );
  margin-left: calc(-1 * var(--tile-image-cover-margin-left) );
}

:root {
  --tile-groups-flex-direction: row;
  --tile-groups-border-width: 1px 0 0 0;
  --tile-groups-border-style: solid;
  --tile-groups-border-color: #f0f0f0;
  --tile-groups-padding-top: 1rem;
  --tile-groups-padding-right: 0;
  --tile-groups-padding-bottom: 0;
  --tile-groups-padding-left: 0;
}

ul.tiles > li > div, .tiles > div > div, ul.tiles > li > section, .tiles > div > section, .tile > div, .tile > section {
  flex-direction: var(--tile-groups-flex-direction, inherit);
  gap: var(--tile-groups-gap, inherit);
  border-width: var(--tile-groups-border-width);
  border-style: var(--tile-groups-border-style);
  border-color: var(--tile-groups-border-color);
  padding-top: var(--tile-groups-padding-top);
  padding-right: var(--tile-groups-padding-right);
  padding-bottom: var(--tile-groups-padding-bottom);
  padding-left: var(--tile-groups-padding-left);
  display: flex;
}

:root {
  --table-base-cells-padding: .5rem;
  --table-base-head-background-color: var(--application-base-accent-color);
  --table-base-head-text-color: var(--application-base-accent-color-text-color);
  --table-base-head-cell-padding: var(--table-base-cells-padding);
  --table-base-head-cell-font-weight: bolder;
  --table-base-body-head-cell-border-width: 1px;
  --table-base-body-head-cell-border-style: solid;
  --table-base-body-head-cell-border-color: #ccc;
  --table-base-body-head-cell-padding: var(--table-base-cells-padding);
  --table-base-body-head-cell-font-weight: 700;
  --table-base-body-head-cell-background-color: var(--application-base-tint-1-background-color);
  --table-base-row-background-color-striping: #9494941a;
  --table-base-cell-padding: var(--table-base-cells-padding);
  --table-base-cell-border-width: 1px;
  --table-base-cell-border-style: solid;
  --table-base-cell-border-color: #ccc;
  --table-base-first-row-cell-border-width: var(--table-base-cell-border-width);
  --table-base-first-row-cell-border-style: var(--table-base-cell-border-style);
  --table-base-first-row-cell-border-color: var(--table-base-cell-border-color);
  --table-base-last-row-cell-border-width: var(--table-base-cell-border-width);
  --table-base-last-row-cell-border-style: var(--table-base-cell-border-style);
  --table-base-last-row-cell-border-color: var(--table-base-cell-border-color);
  --table-base-foot-background-color: #ddd;
  --table-base-foot-padding: var(--table-base-cells-padding);
  --table-base-foot-header-padding: var(--table-base-foot-padding);
  --table-base-foot-cell-padding: var(--table-base-foot-padding);
  --table-base-foot-cell-background-color: #f0f0f0;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: var(--table-base-text-align, inherit);
  font-size: var(--table-base-font-size, inherit);
  line-height: var(--table-base-line-height, inherit);
  font-weight: var(--table-base-font-weight, inherit);
  color: var(--table-base-text-color, inherit);
  background-color: var(--table-base-background-color, transparent);
  border-width: var(--table-base-border-width);
  border-style: var(--table-base-border-style);
  border-color: var(--table-base-border-color);
  margin: 0;
  overflow: auto;
}

table thead {
  background-color: var(--table-base-head-background-color, inherit);
  color: var(--table-base-head-text-color, inherit);
  border-width: var(--table-base-head-border-width);
  border-style: var(--table-base-head-border-style);
  border-color: var(--table-base-head-border-color);
}

table thead th {
  padding: var(--table-base-head-cell-padding);
  border-width: var(--table-base-head-cell-border-width);
  border-style: var(--table-base-head-cell-border-style);
  border-color: var(--table-base-head-cell-border-color);
  font-weight: var(--table-base-head-cell-font-weight);
  font-size: var(--table-base-head-cell-font-size);
}

table thead a, table thead a:visited, table thead a:hover, table thead a:before, table thead a:visited:before, table thead a:hover:before {
  color: inherit;
}

table tbody th {
  padding: var(--table-base-body-head-cell-padding);
  border-width: var(--table-base-body-head-cell-border-width);
  border-style: var(--table-base-body-head-cell-border-style);
  border-color: var(--table-base-body-head-cell-border-color);
  background-color: var(--table-base-body-head-cell-background-color);
  font-weight: var(--table-base-body-head-cell-font-weight);
  font-size: var(--table-base-body-head-cell-font-size);
}

table td {
  padding: var(--table-base-cell-padding);
  gap: var(--table-base-cell-gap);
  background-color: var(--table-base-cell-background-color, inherit);
  color: var(--table-base-cell-text-color, inherit);
  border-width: var(--table-base-cell-border-width);
  border-style: var(--table-base-cell-border-style);
  border-color: var(--table-base-cell-border-color);
}

table tr:nth-child(2n):not(.odd), table tr:nth-child(2n+1).even {
  background-color: var(--table-base-row-background-color-striping, inherit);
}

table tr:first-of-type td {
  border-width: var(--table-base-first-row-cell-border-width);
  border-style: var(--table-base-first-row-cell-border-style);
  border-color: var(--table-base-first-row-cell-border-color);
}

table tr:last-of-type td {
  border-width: var(--table-base-last-row-cell-border-width);
  border-style: var(--table-base-last-row-cell-border-style);
  border-color: var(--table-base-last-row-cell-border-color);
}

table tfoot {
  background-color: var(--table-base-foot-background-color, inherit);
  color: var(--table-base-foot-text-color, inherit);
  font-size: var(--table-base-foot-font-size, inherit);
  font-weight: var(--table-base-foot-font-weight, inherit);
  padding: var(--table-base-foot-padding);
  border-width: var(--table-base-foot-border-width);
  border-style: var(--table-base-foot-border-style);
  border-color: var(--table-base-foot-border-color);
}

table tfoot th {
  background-color: var(--table-base-foot-header-background-color, inherit);
  color: var(--table-base-foot-header-text-color, inherit);
  border-width: var(--table-base-foot-header-border-width);
  border-style: var(--table-base-foot-header-border-style);
  border-color: var(--table-base-foot-header-border-color);
  padding: var(--table-base-foot-header-padding);
  font-weight: var(--table-base-foot-header-font-weight, inherit);
  font-size: var(--table-base-foot-header-font-size, inherit);
}

table tfoot td {
  background-color: var(--table-foot-cell-background-color, inherit);
  color: var(--table-foot-cell-text-color, inherit);
  border-width: var(--table-base-foot-cell-border-width);
  border-style: var(--table-base-foot-cell-border-style);
  border-color: var(--table-base-foot-cell-border-color);
  padding: var(--table-base-foot-cell-padding);
  font-weight: var(--table-base-foot-cell-font-weight, inherit);
  font-size: var(--table-base-foot-cell-font-size, inherit);
}

table tr.expando-row td dl > div > dd, table.condensed tr.expando-row td dl > div > dd, table.sticky tr.expando-row td dl > div > dd {
  max-width: 47rem;
}

article table tr.expando-row td dl > div > dd, article table.condensed tr.expando-row td dl > div > dd, article table.sticky tr.expando-row td dl > div > dd {
  max-width: 30rem;
}

.auth table tr.expando-row td dl > div > dd, .auth table.condensed tr.expando-row td dl > div > dd, .auth table.sticky tr.expando-row td dl > div > dd {
  max-width: 37rem;
}

.form-view table tr.expando-row td dl > div > dd, .form-view table.condensed tr.expando-row td dl > div > dd, .form-view table.sticky tr.expando-row td dl > div > dd {
  max-width: 45rem;
}

:root {
  --expando-rows-table-cell-background-color: #e5e5e5;
  --expando-rows-table-cell-padding: 2rem 1rem;
  --expando-rows-table-content-title-font-size: 1.25rem;
  --expando-rows-table-content-title-font-weight: bolder;
  --expando-rows-table-content-subtitle-font-size: 1rem;
  --expando-rows-table-content-subtitle-font-weight: bolder;
  --expando-rows-table-cell-after-breakpoint-padding: 2rem 3rem;
}

table button.expando-button {
  display: none;
}

table tr.expando-row {
  display: table-layout;
}

table tr.expando-row td {
  background-color: var(--expando-rows-table-cell-background-color);
  padding: var(--expando-rows-table-cell-padding);
  position: relative;
}

@media (min-width: 24rem) {
  table tr.expando-row td {
    padding: var(--expando-rows-table-cell-after-breakpoint-padding);
  }
}

table tr.expando-row td h1 {
  font-size: var(--expando-rows-table-content-title-font-size);
  font-weight: var(--expando-rows-table-content-title-font-weight);
}

table tr.expando-row td h2 {
  font-size: var(--expando-rows-table-content-subtitle-font-size);
  font-weight: var(--expando-rows-table-content-subtitle-font-weight);
}

.js-expando-rows-loaded table tr.expando-row[hidden] {
  display: none;
}

.js-expando-rows-loaded table button.expando-button {
  display: inline-flex;
}

:root {
  --table-base-caption-text-color: var(--de-emphasized-text-color);
  --table-base-caption-font-size: var(--de-emphasized-font-size);
  --table-base-caption-line-height: var(--de-emphasized-line-height);
  --table-base-caption-padding: 1rem 0 .5rem 0;
  --table-base-caption-text-align: left;
}

table caption {
  background-color: var(--table-base-caption-background-color);
  color: var(--table-base-caption-text-color);
  font-size: var(--table-base-caption-font-size);
  text-align: var(--table-base-caption-text-align);
  line-height: var(--table-base-caption-line-height);
  padding: var(--table-base-caption-padding);
}

:root {
  --table-number-text-align: right;
  --table-number-font-family: monospace;
}

.number {
  text-align: var(--table-number-text-align);
  font-variant-numeric: var(--table-number-font-family);
}

td.number input {
  text-align: inherit;
}

:root {
  --table-action-buttons-button-background-color: transparent;
  --table-action-buttons-button-text-color: #6c6c6c;
  --table-action-buttons-button-font-size: 1rem;
  --table-action-buttons-button-justify-content: center;
  --table-action-buttons-button-hover-background-color: transparent;
  --table-action-buttons-button-hover-text-color: #000;
  --table-action-buttons-form-display: flex;
  --table-action-buttons-flex-direction: row;
  --table-action-buttons-justify-content: center;
  --table-action-buttons-width: auto;
}

table.action-buttons button, table.action-buttons a.button, table.action-buttons input[type="button"], table.action-buttons input[type="submit"], table.action-buttons input[type="reset"], table button.action-button, table a.button.action-button, table input[type="button"].action-button, table input[type="submit"].action-button, table input[type="reset"].action-button {
  min-height: unset;
  background: var(--table-action-buttons-button-background-color);
  color: var(--table-action-buttons-button-text-color);
  justify-content: var(--table-action-buttons-button-justify-content);
  border: 0;
  margin: 0;
  padding: 0;
}

table.action-buttons button:before, table.action-buttons a.button:before, table.action-buttons input[type="button"]:before, table.action-buttons input[type="submit"]:before, table.action-buttons input[type="reset"]:before, table button.action-button:before, table a.button.action-button:before, table input[type="button"].action-button:before, table input[type="submit"].action-button:before, table input[type="reset"].action-button:before {
  color: var(--table-action-buttons-button-text-color);
  font-size: var(--table-action-buttons-button-font-size);
}

table.action-buttons button:hover, table.action-buttons a.button:hover, table.action-buttons input[type="button"]:hover, table.action-buttons input[type="submit"]:hover, table.action-buttons input[type="reset"]:hover, table button.action-button:hover, table a.button.action-button:hover, table input[type="button"].action-button:hover, table input[type="submit"].action-button:hover, table input[type="reset"].action-button:hover {
  background: var(--table-action-buttons-button-hover-background-color);
  border: 0;
}

table.action-buttons button:hover:before, table.action-buttons a.button:hover:before, table.action-buttons input[type="button"]:hover:before, table.action-buttons input[type="submit"]:hover:before, table.action-buttons input[type="reset"]:hover:before, table button.action-button:hover:before, table a.button.action-button:hover:before, table input[type="button"].action-button:hover:before, table input[type="submit"].action-button:hover:before, table input[type="reset"].action-button:hover:before {
  color: var(--table-action-buttons-button-hover-text-color);
}

table form {
  width: var(--table-action-buttons-width);
  display: var(--table-action-buttons-form-display);
  flex-direction: var(--table-action-buttons-flex-direction);
  justify-content: var(--table-action-buttons-justify-content);
  background-color: #0000;
  padding: 0;
}

:root {
  --form-base-flex-align-items: flex-start;
  --form-base-justify-content: flex-start;
  --form-base-padding-top: 2rem;
  --form-base-padding-right: 2rem;
  --form-base-padding-bottom: 2rem;
  --form-base-padding-left: 2rem;
  --form-base-background-color: var(--application-base-tint-1-background-color, var(--application-base-background-color));
  --form-base-text-color: var(--application-base-tint-1-text-color, var(--application-base-text-color));
  --form-base-gap: 1.25rem;
}

form {
  align-items: var(--form-base-align-items);
  justify-content: var(--form-base-justify-content);
  width: 100%;
  box-sizing: border-box;
  padding-top: var(--form-base-padding-top);
  padding-right: var(--form-base-padding-right);
  padding-bottom: var(--form-base-padding-bottom);
  padding-left: var(--form-base-padding-left);
  background-color: var(--form-base-background-color, inherit);
  color: var(--form-base-text-color, inherit);
  font-size: var(--form-base-font-size, inherit);
  line-height: var(--form-base-line-height, inherit);
  font-weight: var(--form-base-font-weight, inherit);
  gap: var(--form-base-gap);
  border-width: var(--form-base-border-width);
  border-style: var(--form-base-border-style);
  border-color: var(--form-base-border-color);
  flex-direction: column;
  display: flex;
}

form div, form fieldset {
  width: 100%;
  box-sizing: border-box;
}

form div {
  align-items: flex-start;
  gap: var(--form-base-gap);
  flex-direction: column;
  display: flex;
}

form input, form textarea {
  color: var(--form-base-input-text-color, inherit);
}

form input, form button, form a.button, form input[type="button"], form input[type="submit"], form input[type="reset"] {
  min-height: 0;
}

form .centered {
  width: auto;
  text-align: center;
  display: table-cell;
}

:root {
  --form-input-padding-top: .5rem;
  --form-input-padding-right: 1rem;
  --form-input-padding-bottom: .5rem;
  --form-input-padding-left: 1rem;
  --form-input-background-color: #fff;
  --form-input-min-height: 2.75rem;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding-top: var(--form-input-padding-top);
  padding-right: var(--form-input-padding-right);
  padding-bottom: var(--form-input-padding-bottom);
  padding-left: var(--form-input-padding-left);
  background-color: var(--form-input-background-color);
  color: var(--form-input-text-color, inherit);
  font-size: var(--form-input-font-size, inherit);
  line-height: var(--form-input-line-height, inherit);
  font-weight: var(--form-input-font-weight, inherit);
  min-height: var(--form-input-min-height, inherit);
  border-width: var(--form-input-border-width);
  border-style: var(--form-input-border-style);
  border-color: var(--form-input-border-color);
  border-radius: var(--form-input-border-radius);
}

:root {
  --form-textarea-width: 100%;
  --form-textarea-resize: vertical;
  --form-textarea-min-height: 2.75rem;
  --form-textarea-padding: .75rem;
  --form-textarea-background-color: white;
  --form-textarea-border-width: 1px;
  --form-textarea-border-style: solid;
  --form-textarea-border-color: black;
}

form textarea {
  width: var(--form-textarea-width);
  resize: var(--form-textarea-resize);
  box-sizing: border-box;
  padding: var(--form-textarea-padding);
  font-size: var(--form-textarea-font-size, inherit);
  font-family: var(--form-textarea-font-family, inherit);
  color: var(--form-textarea-text-color, inherit);
  background-color: var(--form-textarea-background-color);
  min-height: var(--form-textarea-min-height);
  border-width: var(--form-textarea-border-width);
  border-style: var(--form-textarea-border-style);
  border-color: var(--form-textarea-border-color);
}

:root {
  --form-checkbox-margin-right: .75rem;
  --form-checkbox-align-self: flex-start;
  --form-checkbox-vertical-align: top;
  --form-checkbox-width: 1.25rem;
  --form-checkbox-height: 1.25rem;
  --form-checkbox-cursor: pointer;
}

form input[type="checkbox"] {
  margin-right: var(--form-checkbox-margin-right, 0);
  vertical-align: var(--form-checkbox-vertical-align);
  cursor: var(--form-checkbox-cursor);
  width: var(--form-checkbox-width);
  min-width: var(--form-checkbox-width);
  height: var(--form-checkbox-height);
  accent-color: var(--form-radio-background-color);
  padding: 0;
}

form input[type="checkbox"] + label {
  width: auto;
  cursor: pointer;
  margin-top: 0;
}

form input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

:root {
  --form-select-width: 100%;
}

form select {
  width: var(--form-select-width);
}

:root {
  --form-radio-margin-right: .75rem;
  --form-radio-align-self: flex-start;
  --form-radio-vertical-align: top;
  --form-radio-width: 1.25rem;
  --form-radio-height: 1.25rem;
  --form-radio-cursor: pointer;
  --form-radio-background-color: var(--application-base-accent-color);
}

form input[type="radio"] {
  margin: var(--form-radio-margin-right, 0);
  vertical-align: var(--form-radio-vertical-align);
  cursor: var(--form-radio-cursor);
  width: var(--form-radio-width);
  min-width: var(--form-radio-width);
  height: var(--form-radio-height);
  align-self: var(--form-radio-align-self);
  accent-color: var(--form-radio-background-color);
  padding: 0;
}

form input[type="radio"] + label {
  width: auto;
  cursor: pointer;
  margin-top: 0;
}

form input[type="radio"]:disabled {
  cursor: not-allowed;
}

:root {
  --form-accent-color-color: var(--application-base-accent-color);
}

form input[type="checkbox"], form input[type="radio"], form input[type="range"], form progress {
  accent-color: var(--form-accent-color-color, initial);
}

:root {
  --form-horizontal-view-justify-content: var(--form-base-flex-justify-content);
  --form-horizontal-view-gap: var(--form-base-gap);
  --form-horizontal-view-label-max-width: 33.33%;
  --form-horizontal-view-input-max-width: 66.66%;
  --form-horizontal-view-fieldset-fields-margin-bottom: 1.25rem;
  --form-horizontal-view-button-margin-left: calc(var(--form-horizontal-view-label-max-width)  + var(--form-horizontal-view-gap) );
}

form.horizontal-view {
  gap: var(--form-horizontal-view-gap);
  flex-flow: column wrap;
}

form.horizontal-view > button, form.horizontal-view > a.button, form.horizontal-view > input[type="button"], form.horizontal-view > input[type="submit"], form.horizontal-view > input[type="reset"] {
  margin-left: var(--form-horizontal-view-button-margin-left);
}

form.horizontal-view > div, form.horizontal-view > section {
  align-items: center;
  justify-content: var(--form-horizontal-view-justify-content);
  gap: var(--form-horizontal-view-gap);
  flex-flow: wrap;
  display: flex;
}

form.horizontal-view > div.checkbox, form.horizontal-view > section.checkbox {
  gap: var(--form-checkbox-margin-right);
}

form.horizontal-view > div.checkbox > div, form.horizontal-view > div.checkbox form.horizontal-view > section.checkbox > div, form.horizontal-view > div.checkbox input[type="checkbox"], form.horizontal-view > section.checkbox form.horizontal-view > div.checkbox > div, form.horizontal-view > section.checkbox > div, form.horizontal-view > section.checkbox input[type="checkbox"] {
  margin-left: calc(var(--form-horizontal-view-label-max-width)  + var(--form-horizontal-view-gap) );
  flex-grow: 0;
  margin-right: 0;
}

form.horizontal-view > div.checkbox > div + label, form.horizontal-view > div.checkbox input[type="checkbox"] + label, form.horizontal-view > section.checkbox > div + label, form.horizontal-view > section.checkbox input[type="checkbox"] + label {
  max-width: 100%;
}

form.horizontal-view > div.checkbox > div, form.horizontal-view > section.checkbox > div {
  gap: 0;
}

form.horizontal-view > div.checkbox > div input[type="checkbox"], form.horizontal-view > section.checkbox > div input[type="checkbox"] {
  margin-left: 0;
}

form.horizontal-view > div.radio-button, form.horizontal-view > section.radio-button {
  gap: var(--form-radio-margin-right);
}

form.horizontal-view > div.radio-button > div, form.horizontal-view > div.radio-button form.horizontal-view > section.radio-button > div, form.horizontal-view > div.radio-button input[type="radio"], form.horizontal-view > section.radio-button form.horizontal-view > div.radio-button > div, form.horizontal-view > section.radio-button > div, form.horizontal-view > section.radio-button input[type="radio"] {
  margin-left: calc(var(--form-horizontal-view-label-max-width)  + var(--form-horizontal-view-gap) );
  flex-grow: 0;
  margin-right: 0;
}

form.horizontal-view > div.radio-button > div + label, form.horizontal-view > div.radio-button input[type="radio"] + label, form.horizontal-view > section.radio-button > div + label, form.horizontal-view > section.radio-button input[type="radio"] + label {
  max-width: 100%;
}

form.horizontal-view > div.radio-button > div input[type="radio"], form.horizontal-view > section.radio-button > div input[type="radio"] {
  margin-left: 0;
}

form.horizontal-view > div input[type="color"], form.horizontal-view > section input[type="color"] {
  max-width: var(--form-input-color-max-width);
}

form.horizontal-view > div > *, form.horizontal-view > section > * {
  box-sizing: border-box;
  flex-grow: 1;
}

form.horizontal-view > div > button, form.horizontal-view > div > a.button, form.horizontal-view > div > input[type="button"], form.horizontal-view > div > input[type="submit"], form.horizontal-view > div > input[type="reset"], form.horizontal-view > section > button, form.horizontal-view > section > a.button, form.horizontal-view > section > input[type="button"], form.horizontal-view > section > input[type="submit"], form.horizontal-view > section > input[type="reset"] {
  flex-grow: 0;
}

@media (min-width: 24rem) {
  form.horizontal-view > div, form.horizontal-view > section {
    flex-wrap: nowrap;
  }

  form.horizontal-view > div > *, form.horizontal-view > section > * {
    max-width: var(--form-horizontal-view-input-max-width);
  }

  form.horizontal-view > div label, form.horizontal-view > section label {
    width: 100%;
    max-width: var(--form-horizontal-view-label-max-width);
  }
}

@media (min-width: 24rem) {
  form.horizontal-view > div.horizontal-view {
    max-width: var(--form-horizontal-view-input-max-width);
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: auto;
  }

  form.horizontal-view > div.horizontal-view > * {
    width: 100%;
    max-width: 100%;
  }

  form.horizontal-view > div.horizontal-view > button, form.horizontal-view > div.horizontal-view > a.button, form.horizontal-view > div.horizontal-view > input[type="button"], form.horizontal-view > div.horizontal-view > input[type="submit"], form.horizontal-view > div.horizontal-view > input[type="reset"] {
    width: auto;
    flex-grow: 0;
  }
}

:root {
  --form-button-min-width: var(--button-base-min-width);
  --form-button-padding-top: var(--button-base-padding-top);
  --form-button-padding-right: var(--button-base-padding-right);
  --form-button-padding-bottom: var(--button-base-padding-bottom);
  --form-button-padding-left: var(--button-base-padding-left);
  --form-button-background-color: var(--button-base-background-color);
  --form-button-color: var(--button-base-text-color);
  --form-button-font-size: var(--application-base-font-size);
  --form-button-line-height: var(--text-base-line-height);
  --form-button-font-weight: var(--text-base-font-weight);
  --form-button-border-width: var(--button-base-border-width);
  --form-button-border-style: var(--button-base-border-style);
  --form-button-border-color: var(--button-base-border-color);
  --form-button-align-self: var(--button-base-align-self);
}

form button, form input[type="button"], form input[type="submit"], form input[type="reset"] {
  width: auto;
  min-width: var(--form-button-min-width);
  box-sizing: border-box;
  padding-top: var(--form-button-padding-top);
  padding-right: var(--form-button-padding-right);
  padding-bottom: var(--form-button-padding-bottom);
  padding-left: var(--form-button-padding-left);
  background-color: var(--form-button-background-color);
  color: var(--form-button-color);
  font-size: var(--form-button-font-size);
  line-height: var(--form-button-line-height);
  font-weight: var(--form-button-font-weight);
  border-width: var(--form-button-border-width);
  border-style: var(--form-button-border-style);
  border-color: var(--form-button-border-color);
  align-self: var(--form-button-align-self);
}

:root {
  --form-button-grouped-horizontal-first-child-margin-left: auto;
}

form .horizontal-view button:first-child {
  margin-left: var(--form-button-grouped-horizontal-first-child-margin-left);
}

:root {
  --form-inline-text-color: var(--application-base-text-color);
}

form.inline {
  color: var(--form-inline-text-color);
  background-color: #0000;
  padding: 0;
}

form.inline button {
  align-self: flex-start;
}

.combined-field {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  display: flex;
}

:root {
  --form-help-padding-right: 3rem;
  --form-help-button-width: 1rem;
  --form-help-button-height: 1rem;
  --form-help-button-padding-top: .5rem;
  --form-help-button-padding-right: .5rem;
  --form-help-button-padding-bottom: .5rem;
  --form-help-button-padding-left: .5rem;
  --form-help-button-top: .75rem;
  --form-help-button-right: -2.5rem;
  --form-help-button-border-width: 2px;
  --form-help-button-border-style: solid;
  --form-help-button-border-color: #3f51b5;
  --form-help-button-border-radius: 50%;
  --form-help-button-background-color: #3f51b5;
  --form-help-button-text-color: #fff;
  --form-help-button-hover-background-color: var(--form-help-button-background-color);
  --form-help-button-hover-text-color: var(--form-help-button-text-color);
  --form-help-button-icon-content: "?";
  --form-help-button-icon-font-size: .9rem;
  --form-help-button-icon-font-weight: bold;
  --form-help-button-icon-line-height: 1;
}

form.help {
  padding-right: var(--form-help-padding-right, var(--form-base-padding-right));
  position: relative;
}

form.help div, form.help > div, form.help > fieldset {
  position: relative;
}

form.help > div .help-button, form.help > fieldset .help-button {
  top: var(--form-help-button-top);
  right: var(--form-help-button-right, auto);
  padding-top: var(--form-help-button-padding-top);
  padding-right: var(--form-help-button-padding-right);
  padding-bottom: var(--form-help-button-padding-bottom);
  padding-left: var(--form-help-button-padding-left);
  box-sizing: border-box;
  border-width: var(--form-help-button-border-width);
  border-style: var(--form-help-button-border-style);
  border-color: var(--form-help-button-border-color);
  border-radius: var(--form-help-button-border-radius);
  min-width: 0;
  width: var(--form-help-button-width);
  min-height: 0;
  height: var(--form-help-button-height);
  background-color: var(--form-help-button-background-color, inherit);
  color: var(--form-help-button-text-color, inherit);
  position: absolute;
}

form.help > div .help-button:hover, form.help > fieldset .help-button:hover {
  background-color: var(--form-help-button-hover-background-color);
  color: var(--form-help-button-hover-text-color);
}

form.help > div .help-button:before, form.help > fieldset .help-button:before {
  content: var(--form-help-button-icon-content);
  font-family: var(--form-help-button-icon-font-family, inherit);
  line-height: var(--form-help-button-icon-line-height);
  font-size: var(--form-help-button-icon-font-size, inherit);
  font-weight: var(--form-help-button-icon-font-weight, inherit);
  vertical-align: middle;
}

form.help > div p.explanation.collapsed, form.help > div div.explanation.collapsed, form.help > fieldset p.explanation.collapsed, form.help > fieldset div.explanation.collapsed {
  display: none;
}

form.help fieldset {
  max-width: 100%;
  min-width: 0;
}

:root {
  --form-fieldset-fields-margin-top: 1rem;
  --form-fieldset-legend-font-weight: bold;
  --form-fieldset-legend-margin-bottom: 1rem;
}

form > fieldset legend {
  font-weight: var(--form-fieldset-legend-font-weight);
  margin-bottom: var(--form-fieldset-legend-margin-bottom);
}

form > fieldset div {
  float: left;
  width: 100%;
  display: block;
}

form > fieldset div > * {
  float: left;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
}

form > fieldset > *, form > fieldset > fieldset {
  margin-bottom: var(--form-fieldset-fields-margin-top);
}

form > fieldset > :last-child, form > fieldset > fieldset:last-child {
  margin-bottom: 0;
}

form fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

:root {
  --form-horizontal-view-fieldset-label-margin-top: .75rem;
  --form-horizontal-view-fieldset-nota-bene-required-margin-bottom: 1rem;
  --form-horizontal-view-fieldset-button-margin-left: var(--form-horizontal-view-label-max-width);
}

@media (min-width: 24rem) {
  form.horizontal-view > fieldset > div > * {
    width: var(--form-horizontal-view-input-max-width);
    float: right;
  }

  form.horizontal-view > fieldset > div label {
    float: left;
    width: var(--form-horizontal-view-label-max-width);
    padding-right: var(--form-horizontal-view-gap);
    margin-top: var(--form-horizontal-view-fieldset-label-margin-top);
  }
}

form.horizontal-view > fieldset .required .nota-bene {
  margin-bottom: var(--form-horizontal-view-fieldset-nota-bene-required-margin-bottom);
}

form.horizontal-view > fieldset input[type="color"] {
  float: left;
}

form.horizontal-view > fieldset .checkbox, form.horizontal-view > fieldset .radio-button {
  margin-left: var(--form-horizontal-view-label-max-width);
  width: var(--form-horizontal-view-input-max-width);
}

form.horizontal-view > fieldset .checkbox .horizontal-view, form.horizontal-view > fieldset .radio-button .horizontal-view {
  gap: 0;
  display: block;
}

form.horizontal-view > fieldset .checkbox input, form.horizontal-view > fieldset .checkbox label, form.horizontal-view > fieldset .radio-button input, form.horizontal-view > fieldset .radio-button label {
  float: none;
  margin: 0;
}

form.horizontal-view > fieldset .checkbox input, form.horizontal-view > fieldset .radio-button input {
  width: var(--form-checkbox-width);
  margin-right: var(--form-checkbox-margin-right);
}

form.horizontal-view > fieldset .checkbox label, form.horizontal-view > fieldset .radio-button label {
  margin-top: 0;
}

form.horizontal-view > fieldset .checkbox > button, form.horizontal-view > fieldset .radio-button > button {
  top: 0;
}

form.horizontal-view > fieldset .checkbox > p, form.horizontal-view > fieldset .checkbox > div, form.horizontal-view > fieldset .radio-button > p, form.horizontal-view > fieldset .radio-button > div {
  width: 100%;
}

form.horizontal-view > fieldset + button, form.horizontal-view > fieldset + a.button, form.horizontal-view > fieldset + input[type="button"], form.horizontal-view > fieldset + input[type="submit"], form.horizontal-view > fieldset + input[type="reset"] {
  margin-left: var(--form-horizontal-view-fieldset-button-margin-left);
}

form.horizontal-view > fieldset > fieldset legend {
  font-weight: inherit;
  box-sizing: border-box;
  width: var(--form-horizontal-view-label-max-width);
  float: left;
  padding-right: var(--form-horizontal-view-gap);
  margin: 0;
  margin-top: var(--form-horizontal-view-fieldset-label-margin-top);
}

@media (min-width: 24rem) {
  form.horizontal-view > fieldset > fieldset > * {
    width: var(--form-horizontal-view-input-max-width);
    float: right;
  }
}

:root {
  --form-fieldset-visually-hidden-help-button-top: 2.25rem;
}

form.help > fieldset > fieldset .help-button {
  top: var(--form-fieldset-visually-hidden-help-button-top);
}

:root {
  --notification-base-notification-text-color: #000;
  --notification-base-width: 100%;
  --notification-base-padding-top: 1.5rem;
  --notification-base-padding-right: 1rem;
  --notification-base-padding-bottom: 1.5rem;
  --notification-base-padding-left: 1rem;
  --notification-base-background-color: #f0f0f0;
  --notification-base-border-width: 1px;
  --notification-base-border-style: solid;
  --notification-base-border-color: #a3a3a3;
  --notification-base-link-text-color: var(--link-base-text-color);
  --notification-base-link-hover-text-color: var(--link-base-hover-text-color);
  --notification-base-link-visited-text-color: var(--link-base-visited-text-color);
  --notification-base-link-visited-hover-text-color: var(--link-base-visited-hover-text-color);
  --notification-base-span-font-weight: bold;
}

.notification {
  background-color: var(--notification-base-background-color);
  color: var(--notification-base-notification-text-color);
}

.notification.hidden {
  display: none;
}

.notification a {
  color: var(--notification-base-link-text-color);
}

.notification a:hover {
  color: var(--notification-base-link-hover-text-color);
}

.notification a:visited {
  color: var(--notification-base-link-visited-text-color);
}

.notification a:visited:hover {
  color: var(--notification-base-link-visited-hover-text-color);
}

.notification a:visited:before {
  color: inherit;
}

:root {
  --notification-block-element-flex-direction: column;
  --notification-block-element-align-items: flex-start;
  --notification-block-element-justify-content: flex-start;
  --notification-block-element-position: relative;
  --notification-block-element-width: var(--notification-base-width);
  --notification-block-element-padding-top: 1.5rem;
  --notification-block-element-padding-right: 1rem;
  --notification-block-element-padding-bottom: 1.5rem;
  --notification-block-element-padding-left: 1rem;
  --notification-block-element-gap: 1rem;
  --notification-block-element-text-color: #000;
  --notification-block-element-link-text-color: var(--link-block-element-text-color);
  --notification-block-element-link-hover-text-color: var(--link-block-element-hover-text-color);
  --notification-block-element-link-visited-text-color: var(--link-block-element-visited-text-color);
  --notification-block-element-link-visited-hover-text-color: var(--link-block-element-visited-hover-text-color);
  --notification-block-element-span-font-weight: bold;
}

div.notification, div.error, div.warning, div.confirmation, div.explanation, div.system, section.notification, section.error, section.warning, section.confirmation, section.explanation, section.system {
  flex-direction: var(--notification-block-element-flex-direction);
  align-items: var(--notification-block-element-align-items);
  justify-content: var(--notification-block-element-justify-content);
  position: var(--notification-block-element-position);
  width: var(--notification-block-element-width);
  padding-top: var(--notification-block-element-padding-top);
  padding-right: var(--notification-block-element-padding-right);
  padding-bottom: var(--notification-block-element-padding-bottom);
  padding-left: var(--notification-block-element-padding-left);
  box-sizing: border-box;
  gap: var(--notification-block-element-gap);
  color: var(--notification-block-element-text-color);
  display: flex;
}

:root {
  --notification-paragraph-width: var(--notification-base-width);
  --notification-paragraph-padding-top: var(--notification-base-padding-top);
  --notification-paragraph-padding-right: var(--notification-base-padding-right);
  --notification-paragraph-padding-bottom: var(--notification-base-padding-bottom);
  --notification-paragraph-padding-left: var(--notification-base-padding-left);
  --notification-paragraph-span-font-weight: var(--notification-base-span-font-weight);
}

p.notification, p.error, p.warning, p.confirmation, p.explanation, p.system {
  box-sizing: border-box;
  width: var(--notification-paragraph-width);
  padding-top: var(--notification-paragraph-padding-top);
  padding-right: var(--notification-paragraph-padding-right);
  padding-bottom: var(--notification-paragraph-padding-bottom);
  padding-left: var(--notification-paragraph-padding-left);
  display: inline-block;
}

p.notification > span:first-of-type, p.error > span:first-of-type, p.warning > span:first-of-type, p.confirmation > span:first-of-type, p.explanation > span:first-of-type, p.system > span:first-of-type {
  font-weight: var(--notification-paragraph-span-font-weight);
}

:root {
  --notification-error-background-color: #ffd9d9;
  --notification-error-border-width: var(--notification-base-border-width);
  --notification-error-border-style: var(--notification-base-border-style);
  --notification-error-border-color: #d98383;
}

p.error, span.error, section.error, div.error {
  background-color: var(--notification-error-background-color);
  border-width: var(--notification-error-border-width);
  border-style: var(--notification-error-border-style);
  border-color: var(--notification-error-border-color);
}

p.error:before, span.error:before, section.error:before, div.error:before {
  content: var(--notification-error-icon-before-content);
}

p.error:after, span.error:after, section.error:after, div.error:after {
  content: var(--notification-error-icon-after-content);
}

textarea.error, select.error, input.error {
  border-width: var(--notification-error-border-width);
  border-style: var(--notification-error-border-style);
  border-color: var(--notification-error-border-color);
}

textarea.error:before, select.error:before, input.error:before {
  content: var(--notification-error-icon-before-content);
}

textarea.error:after, select.error:after, input.error:after {
  content: var(--notification-error-icon-after-content);
}

:root {
  --notification-warning-background-color: #fff2d1;
  --notification-warning-border-width: var(--notification-base-border-width);
  --notification-warning-border-style: var(--notification-base-border-style);
  --notification-warning-border-color: #f6cb5f;
}

p.warning, span.warning, section.warning, div.warning {
  background-color: var(--notification-warning-background-color);
  border-width: var(--notification-warning-border-width);
  border-style: var(--notification-warning-border-style);
  border-color: var(--notification-warning-border-color);
}

p.warning:before, span.warning:before, section.warning:before, div.warning:before {
  content: var(--notification-warning-icon-before-content);
}

p.warning:after, span.warning:after, section.warning:after, div.warning:after {
  content: var(--notification-warning-icon-after-content);
}

textarea.warning, select.warning, input.warning {
  border-width: var(--notification-warning-border-width);
  border-style: var(--notification-warning-border-style);
  border-color: var(--notification-warning-border-color);
}

textarea.warning:before, select.warning:before, input.warning:before {
  content: var(--notification-warning-icon-before-content);
}

textarea.warning:after, select.warning:after, input.warning:after {
  content: var(--notification-warning-icon-after-content);
}

:root {
  --notification-explanation-background-color: #d9edff;
  --notification-explanation-border-width: var(--notification-base-border-width);
  --notification-explanation-border-style: var(--notification-base-border-style);
  --notification-explanation-border-color: #a6c1d8;
}

p.explanation, span.explanation, section.explanation, div.explanation {
  background-color: var(--notification-explanation-background-color);
  border-width: var(--notification-explanation-border-width);
  border-style: var(--notification-explanation-border-style);
  border-color: var(--notification-explanation-border-color);
}

p.explanation:before, span.explanation:before, section.explanation:before, div.explanation:before {
  content: var(--notification-explanation-icon-before-content);
}

p.explanation:after, span.explanation:after, section.explanation:after, div.explanation:after {
  content: var(--notification-explanation-icon-after-content);
}

textarea.explanation, select.explanation, input.explanation {
  border-width: var(--notification-explanation-border-width);
  border-style: var(--notification-explanation-border-style);
  border-color: var(--notification-explanation-border-color);
}

textarea.explanation:before, select.explanation:before, input.explanation:before {
  content: var(--notification-explanation-icon-before-content);
}

textarea.explanation:after, select.explanation:after, input.explanation:after {
  content: var(--notification-explanation-icon-after-content);
}

:root {
  --notification-confirmation-background-color: #deffdb;
  --notification-confirmation-border-width: var(--notification-base-border-width);
  --notification-confirmation-border-style: var(--notification-base-border-style);
  --notification-confirmation-border-color: #9ac596;
}

p.confirmation, span.confirmation, section.confirmation, div.confirmation {
  background-color: var(--notification-confirmation-background-color);
  border-width: var(--notification-confirmation-border-width);
  border-style: var(--notification-confirmation-border-style);
  border-color: var(--notification-confirmation-border-color);
}

p.confirmation:before, span.confirmation:before, section.confirmation:before, div.confirmation:before {
  content: var(--notification-confirmation-icon-before-content);
}

p.confirmation:after, span.confirmation:after, section.confirmation:after, div.confirmation:after {
  content: var(--notification-confirmation-icon-after-content);
}

textarea.confirmation, select.confirmation, input.confirmation {
  border-width: var(--notification-confirmation-border-width);
  border-style: var(--notification-confirmation-border-style);
  border-color: var(--notification-confirmation-border-color);
}

textarea.confirmation:before, select.confirmation:before, input.confirmation:before {
  content: var(--notification-confirmation-icon-before-content);
}

textarea.confirmation:after, select.confirmation:after, input.confirmation:after {
  content: var(--notification-confirmation-icon-after-content);
}

:root {
  --notification-system-background-color: #eee;
  --notification-system-border-width: var(--notification-base-border-width);
  --notification-system-border-style: var(--notification-base-border-style);
  --notification-system-border-color: #c7c7c7;
}

p.system, span.system, section.system, div.system {
  background-color: var(--notification-system-background-color);
  border-width: var(--notification-system-border-width);
  border-style: var(--notification-system-border-style);
  border-color: var(--notification-system-border-color);
}

p.system:before, span.system:before, section.system:before, div.system:before {
  content: var(--notification-system-icon-before-content);
}

p.system:after, span.system:after, section.system:after, div.system:after {
  content: var(--notification-system-icon-after-content);
}

textarea.system, select.system, input.system {
  border-width: var(--notification-system-border-width);
  border-style: var(--notification-system-border-style);
  border-color: var(--notification-system-border-color);
}

textarea.system:before, select.system:before, input.system:before {
  content: var(--notification-system-icon-before-content);
}

textarea.system:after, select.system:after, input.system:after {
  content: var(--notification-system-icon-after-content);
}

:root {
  --login-meta-flex-direction: row;
  --login-meta-justify-content: flex-end;
  --login-meta-align-items: center;
  --login-meta-padding-top: .5rem;
  --login-meta-padding-right: .5rem;
  --login-meta-padding-bottom: .5rem;
  --login-meta-padding-left: .5rem;
  --login-meta-gap: 1rem;
  --login-meta-background-color: #f0f0f0;
  --login-meta-color: #fff;
}

.login-meta {
  flex-direction: var(--login-meta-flex-direction);
  justify-content: var(--login-meta-justify-content);
  padding-top: var(--login-meta-padding-top);
  padding-right: var(--login-meta-padding-right);
  padding-bottom: var(--login-meta-padding-bottom);
  padding-left: var(--login-meta-padding-left);
  gap: var(--login-meta-gap);
  width: 100%;
  max-width: unset;
  background-color: var(--login-meta-background-color);
  color: var(--login-meta-text-color);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 0;
  display: flex;
}

:root {
  --tag-font-size: var(--application-base-font-size);
  --tag-font-weight: bold;
  --tag-line-height: var(--application-base-line-height);
  --tag-padding: .25rem .5rem;
  --tag-border-radius: .25rem;
  --tag-border-width: 1px;
}

.tag {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--tag-line-height);
  padding: var(--tag-padding);
  border-radius: var(--tag-border-radius);
  border-width: var(--tag-border-width);
}

.tag.plain, .tag.solid, .tag.dashed, .tag.dotted {
  border-width: var(--tag-border-width);
}

.tag.plain {
  border-style: solid;
  border-color: #0000;
}

.tag.solid {
  border-style: solid;
}

.tag.dashed {
  border-style: dashed;
}

.tag.dotted {
  border-style: dotted;
}

:root {
  --tags-flex-direction: row;
  --tags-align-items: center;
  --tags-justify-content: left;
  --tags-flex-wrap: wrap;
  --tags-gap: .5rem;
  --tags-padding-top: 0;
  --tags-padding-right: 0;
  --tags-padding-bottom: 0;
  --tags-padding-left: 0;
  --tags-list-style: none;
  --tag-font-size: var(--application-base-font-size);
  --tag-font-weight: bold;
  --tag-line-height: var(--application-base-line-height);
  --tag-padding: .25rem .5rem;
  --tag-border-radius: .25rem;
  --tag-border-width: 1px;
}

ul.tags > li, table.tags td p, table.tags td span, table .tags td p, table .tags td span, .tags > p, .tags > span, .tag {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--tag-line-height);
  padding: var(--tag-padding);
  border-radius: var(--tag-border-radius);
  border-width: var(--tag-border-width);
}

ul.tags > li.plain, table.tags td p.plain, table.tags td span.plain, table .tags td p.plain, table .tags td span.plain, .tags > p.plain, .tags > span.plain, ul.tags > li.solid, table.tags td p.solid, table.tags td span.solid, table .tags td p.solid, table .tags td span.solid, .tags > p.solid, .tags > span.solid, ul.tags > li.dashed, table.tags td p.dashed, table.tags td span.dashed, table .tags td p.dashed, table .tags td span.dashed, .tags > p.dashed, .tags > span.dashed, ul.tags > li.dotted, table.tags td p.dotted, table.tags td span.dotted, table .tags td p.dotted, table .tags td span.dotted, .tags > p.dotted, .tags > span.dotted, .tag.plain, .tag.solid, .tag.dashed, .tag.dotted {
  border-width: var(--tag-border-width);
}

ul.tags > li.plain, table.tags td p.plain, table.tags td span.plain, table .tags td p.plain, table .tags td span.plain, .tags > p.plain, .tags > span.plain, .tag.plain {
  border-style: solid;
  border-color: #0000;
}

ul.tags > li.solid, table.tags td p.solid, table.tags td span.solid, table .tags td p.solid, table .tags td span.solid, .tags > p.solid, .tags > span.solid, .tag.solid {
  border-style: solid;
}

ul.tags > li.dashed, table.tags td p.dashed, table.tags td span.dashed, table .tags td p.dashed, table .tags td span.dashed, .tags > p.dashed, .tags > span.dashed, .tag.dashed {
  border-style: dashed;
}

ul.tags > li.dotted, table.tags td p.dotted, table.tags td span.dotted, table .tags td p.dotted, table .tags td span.dotted, .tags > p.dotted, .tags > span.dotted, .tag.dotted {
  border-style: dotted;
}

ul.tags {
  padding: 0;
}

ul.tags > li {
  list-style: var(--tags-list-style);
}

.tags.horizontal-view {
  flex-direction: var(--tags-flex-direction, inherit);
  align-items: var(--tags-align-items);
  justify-content: var(--tags-justify-content);
  flex-wrap: var(--tags-flex-wrap);
  gap: var(--tags-gap, inherit);
  padding-top: var(--tags-padding-top);
  padding-right: var(--tags-padding-right);
  padding-bottom: var(--tags-padding-bottom);
  padding-left: var(--tags-padding-left);
}

:root {
  --tags-color-1-light-background-color: var(--tags-soft-blue-light-background-color);
  --tags-color-1-light-text-color: var(--tags-soft-blue-light-text-color);
  --tags-color-1-light-border-color: var(--tags-soft-blue-light-border-color);
  --tags-color-1-medium-background-color: var(--tags-soft-blue-medium-background-color);
  --tags-color-1-medium-text-color: var(--tags-soft-blue-medium-text-color);
  --tags-color-1-medium-border-color: var(--tags-soft-blue-medium-border-color);
  --tags-color-1-dark-background-color: var(--tags-soft-blue-dark-background-color);
  --tags-color-1-dark-text-color: var(--tags-soft-blue-dark-text-color);
  --tags-color-1-dark-border-color: var(--tags-soft-blue-dark-border-color);
  --tags-color-2-light-background-color: var(--tags-soft-green-light-background-color);
  --tags-color-2-light-text-color: var(--tags-soft-green-light-text-color);
  --tags-color-2-light-border-color: var(--tags-soft-green-light-border-color);
  --tags-color-2-medium-background-color: var(--tags-soft-green-medium-background-color);
  --tags-color-2-medium-text-color: var(--tags-soft-green-medium-text-color);
  --tags-color-2-medium-border-color: var(--tags-soft-green-medium-border-color);
  --tags-color-2-dark-background-color: var(--tags-soft-green-dark-background-color);
  --tags-color-2-dark-text-color: var(--tags-soft-green-dark-text-color);
  --tags-color-2-dark-border-color: var(--tags-soft-green-dark-border-color);
  --tags-color-3-light-background-color: var(--tags-soft-yellow-light-background-color);
  --tags-color-3-light-text-color: var(--tags-soft-yellow-light-text-color);
  --tags-color-3-light-border-color: var(--tags-soft-yellow-light-border-color);
  --tags-color-3-medium-background-color: var(--tags-soft-yellow-medium-background-color);
  --tags-color-3-medium-text-color: var(--tags-soft-yellow-medium-text-color);
  --tags-color-3-medium-border-color: var(--tags-soft-yellow-medium-border-color);
  --tags-color-3-dark-background-color: var(--tags-soft-yellow-dark-background-color);
  --tags-color-3-dark-text-color: var(--tags-soft-yellow-dark-text-color);
  --tags-color-3-dark-border-color: var(--tags-soft-yellow-dark-border-color);
  --tags-color-4-light-background-color: var(--tags-soft-orange-light-background-color);
  --tags-color-4-light-text-color: var(--tags-soft-orange-light-text-color);
  --tags-color-4-light-border-color: var(--tags-soft-orange-light-border-color);
  --tags-color-4-medium-background-color: var(--tags-soft-orange-medium-background-color);
  --tags-color-4-medium-text-color: var(--tags-soft-orange-medium-text-color);
  --tags-color-4-medium-border-color: var(--tags-soft-orange-medium-border-color);
  --tags-color-4-dark-background-color: var(--tags-soft-orange-dark-background-color);
  --tags-color-4-dark-text-color: var(--tags-soft-orange-dark-text-color);
  --tags-color-4-dark-border-color: var(--tags-soft-orange-dark-border-color);
  --tags-color-5-light-background-color: var(--tags-soft-red-light-background-color);
  --tags-color-5-light-text-color: var(--tags-soft-red-light-text-color);
  --tags-color-5-light-border-color: var(--tags-soft-red-light-border-color);
  --tags-color-5-medium-background-color: var(--tags-soft-red-medium-background-color);
  --tags-color-5-medium-text-color: var(--tags-soft-red-medium-text-color);
  --tags-color-5-medium-border-color: var(--tags-soft-red-medium-border-color);
  --tags-color-5-dark-background-color: var(--tags-soft-red-dark-background-color);
  --tags-color-5-dark-text-color: var(--tags-soft-red-dark-text-color);
  --tags-color-5-dark-border-color: var(--tags-soft-red-dark-border-color);
  --tags-color-6-light-background-color: var(--tags-soft-violet-light-background-color);
  --tags-color-6-light-text-color: var(--tags-soft-violet-light-text-color);
  --tags-color-6-light-border-color: var(--tags-soft-violet-light-border-color);
  --tags-color-6-medium-background-color: var(--tags-soft-violet-medium-background-color);
  --tags-color-6-medium-text-color: var(--tags-soft-violet-medium-text-color);
  --tags-color-6-medium-border-color: var(--tags-soft-violet-medium-border-color);
  --tags-color-6-dark-background-color: var(--tags-soft-violet-dark-background-color);
  --tags-color-6-dark-text-color: var(--tags-soft-violet-dark-text-color);
  --tags-color-6-dark-border-color: var(--tags-soft-violet-dark-border-color);
}

.tags-color-1-light {
  background-color: var(--tags-color-1-light-background-color);
  color: var(--tags-color-1-light-text-color);
  border-color: var(--tags-color-1-light-border-color);
}

.tags-color-1-medium {
  background-color: var(--tags-color-1-medium-background-color);
  color: var(--tags-color-1-medium-text-color);
  border-color: var(--tags-color-1-medium-border-color);
}

.tags-color-1-dark {
  background-color: var(--tags-color-1-dark-background-color);
  color: var(--tags-color-1-dark-text-color);
  border-color: var(--tags-color-1-dark-border-color);
}

.tags-color-2-light {
  background-color: var(--tags-color-2-light-background-color);
  color: var(--tags-color-2-light-text-color);
  border-color: var(--tags-color-2-light-border-color);
}

.tags-color-2-medium {
  background-color: var(--tags-color-2-medium-background-color);
  color: var(--tags-color-2-medium-text-color);
  border-color: var(--tags-color-2-medium-border-color);
}

.tags-color-2-dark {
  background-color: var(--tags-color-2-dark-background-color);
  color: var(--tags-color-2-dark-text-color);
  border-color: var(--tags-color-2-dark-border-color);
}

.tags-color-3-light {
  background-color: var(--tags-color-3-light-background-color);
  color: var(--tags-color-3-light-text-color);
  border-color: var(--tags-color-3-light-border-color);
}

.tags-color-3-medium {
  background-color: var(--tags-color-3-medium-background-color);
  color: var(--tags-color-3-medium-text-color);
  border-color: var(--tags-color-3-medium-border-color);
}

.tags-color-3-dark {
  background-color: var(--tags-color-3-dark-background-color);
  color: var(--tags-color-3-dark-text-color);
  border-color: var(--tags-color-3-dark-border-color);
}

.tags-color-4-light {
  background-color: var(--tags-color-4-light-background-color);
  color: var(--tags-color-4-light-text-color);
  border-color: var(--tags-color-4-light-border-color);
}

.tags-color-4-medium {
  background-color: var(--tags-color-4-medium-background-color);
  color: var(--tags-color-4-medium-text-color);
  border-color: var(--tags-color-4-medium-border-color);
}

.tags-color-4-dark {
  background-color: var(--tags-color-4-dark-background-color);
  color: var(--tags-color-4-dark-text-color);
  border-color: var(--tags-color-4-dark-border-color);
}

.tags-color-5-light {
  background-color: var(--tags-color-5-light-background-color);
  color: var(--tags-color-5-light-text-color);
  border-color: var(--tags-color-5-light-border-color);
}

.tags-color-5-medium {
  background-color: var(--tags-color-5-medium-background-color);
  color: var(--tags-color-5-medium-text-color);
  border-color: var(--tags-color-5-medium-border-color);
}

.tags-color-5-dark {
  background-color: var(--tags-color-5-dark-background-color);
  color: var(--tags-color-5-dark-text-color);
  border-color: var(--tags-color-5-dark-border-color);
}

.tags-color-6-light {
  background-color: var(--tags-color-6-light-background-color);
  color: var(--tags-color-6-light-text-color);
  border-color: var(--tags-color-6-light-border-color);
}

.tags-color-6-medium {
  background-color: var(--tags-color-6-medium-background-color);
  color: var(--tags-color-6-medium-text-color);
  border-color: var(--tags-color-6-medium-border-color);
}

.tags-color-6-dark {
  background-color: var(--tags-color-6-dark-background-color);
  color: var(--tags-color-6-dark-text-color);
  border-color: var(--tags-color-6-dark-border-color);
}

.graph-d3 {
  overflow: hidden;
  margin: 0 !important;
}

.d-none {
  display: none !important;
}

section dl div {
  display: grid;
}

ul.tiles > li > a, .tiles > div > a, .tile > a {
  color: var(--link-text-color, initial);
}

@font-face {
  font-family: Fredoka;
  font-weight: 600;
  font-style: normal;
  src: url("Fredoka-SemiBold.c8828b7c.ttf") format("truetype");
}

@font-face {
  font-family: Open Sans;
  font-weight: normal;
  font-style: normal;
  src: url("OpenSans-Regular.edf9e01b.ttf") format("truetype");
}

@font-face {
  font-family: Open Sans;
  font-weight: normal;
  font-style: italic;
  src: url("OpenSans-Italic.c0566325.ttf") format("truetype");
}

@font-face {
  font-family: Open Sans;
  font-weight: bold;
  font-style: normal;
  src: url("OpenSans-Bold.8fceb72b.ttf") format("truetype");
}

@font-face {
  font-family: Open Sans;
  font-weight: bold;
  font-style: italic;
  src: url("OpenSans-BoldItalic.af741982.ttf") format("truetype");
}

@font-face {
  font-family: tabler-icons;
  font-style: normal;
  font-weight: 400;
  src: url("tabler-icons.87a11f26.eot");
  src: url("tabler-icons.87a11f26.eot#iefix") format("embedded-opentype"), url("tabler-icons.729ada4a.woff2") format("woff2"), url("tabler-icons.7aa644ea.woff") format("woff"), url("tabler-icons.6d5b56e4.ttf") format("truetype"), url("tabler-icons.049fe672.svg#tabler-icons") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: tabler-icons;
    src: url("tabler-icons.049fe672.svg#tabler-icons") format("svg");
  }
}

.ti:before, .ti :after {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: tabler-icons !important;
}

.ti-2fa:before {
  content: "";
}

.ti-3d-cube-sphere:before {
  content: "";
}

.ti-3d-rotate:before {
  content: "";
}

.ti-a-b:before {
  content: "";
}

.ti-a-b-off:before {
  content: "";
}

.ti-abacus:before {
  content: "";
}

.ti-access-point:before {
  content: "";
}

.ti-access-point-off:before {
  content: "";
}

.ti-accessible:before {
  content: "";
}

.ti-accessible-off:before {
  content: "";
}

.ti-activity:before {
  content: "";
}

.ti-activity-heartbeat:before {
  content: "";
}

.ti-ad:before {
  content: "";
}

.ti-ad-2:before {
  content: "";
}

.ti-address-book:before {
  content: "";
}

.ti-adjustments:before {
  content: "";
}

.ti-adjustments-alt:before {
  content: "";
}

.ti-adjustments-horizontal:before {
  content: "";
}

.ti-adjustments-off:before {
  content: "";
}

.ti-aerial-lift:before {
  content: "";
}

.ti-affiliate:before {
  content: "";
}

.ti-alarm:before {
  content: "";
}

.ti-alarm-off:before {
  content: "";
}

.ti-album:before {
  content: "";
}

.ti-alert-circle:before {
  content: "";
}

.ti-alert-octagon:before {
  content: "";
}

.ti-alert-triangle:before {
  content: "";
}

.ti-alien:before {
  content: "";
}

.ti-align-center:before {
  content: "";
}

.ti-align-justified:before {
  content: "";
}

.ti-align-left:before {
  content: "";
}

.ti-align-right:before {
  content: "";
}

.ti-alphabet-cyrillic:before {
  content: "";
}

.ti-alphabet-greek:before {
  content: "";
}

.ti-alphabet-latin:before {
  content: "";
}

.ti-ambulance:before {
  content: "";
}

.ti-anchor:before {
  content: "";
}

.ti-anchor-off:before {
  content: "";
}

.ti-angle:before {
  content: "";
}

.ti-ankh:before {
  content: "";
}

.ti-antenna:before {
  content: "";
}

.ti-antenna-bars-1:before {
  content: "";
}

.ti-antenna-bars-2:before {
  content: "";
}

.ti-antenna-bars-3:before {
  content: "";
}

.ti-antenna-bars-4:before {
  content: "";
}

.ti-antenna-bars-5:before {
  content: "";
}

.ti-antenna-bars-off:before {
  content: "";
}

.ti-aperture:before {
  content: "";
}

.ti-api:before {
  content: "";
}

.ti-api-app:before {
  content: "";
}

.ti-api-app-off:before {
  content: "";
}

.ti-api-off:before {
  content: "";
}

.ti-app-window:before {
  content: "";
}

.ti-apple:before {
  content: "";
}

.ti-apps:before {
  content: "";
}

.ti-apps-off:before {
  content: "";
}

.ti-archive:before {
  content: "";
}

.ti-archive-off:before {
  content: "";
}

.ti-armchair:before {
  content: "";
}

.ti-armchair-2:before {
  content: "";
}

.ti-arrow-autofit-content:before {
  content: "";
}

.ti-arrow-autofit-down:before {
  content: "";
}

.ti-arrow-autofit-height:before {
  content: "";
}

.ti-arrow-autofit-left:before {
  content: "";
}

.ti-arrow-autofit-right:before {
  content: "";
}

.ti-arrow-autofit-up:before {
  content: "";
}

.ti-arrow-autofit-width:before {
  content: "";
}

.ti-arrow-back:before {
  content: "";
}

.ti-arrow-back-up:before {
  content: "";
}

.ti-arrow-bar-down:before {
  content: "";
}

.ti-arrow-bar-left:before {
  content: "";
}

.ti-arrow-bar-right:before {
  content: "";
}

.ti-arrow-bar-to-down:before {
  content: "";
}

.ti-arrow-bar-to-left:before {
  content: "";
}

.ti-arrow-bar-to-right:before {
  content: "";
}

.ti-arrow-bar-to-up:before {
  content: "";
}

.ti-arrow-bar-up:before {
  content: "";
}

.ti-arrow-bear-left:before {
  content: "";
}

.ti-arrow-bear-left-2:before {
  content: "";
}

.ti-arrow-bear-right:before {
  content: "";
}

.ti-arrow-bear-right-2:before {
  content: "";
}

.ti-arrow-big-down:before {
  content: "";
}

.ti-arrow-big-down-line:before {
  content: "";
}

.ti-arrow-big-down-lines:before {
  content: "";
}

.ti-arrow-big-left:before {
  content: "";
}

.ti-arrow-big-left-line:before {
  content: "";
}

.ti-arrow-big-left-lines:before {
  content: "";
}

.ti-arrow-big-right:before {
  content: "";
}

.ti-arrow-big-right-line:before {
  content: "";
}

.ti-arrow-big-right-lines:before {
  content: "";
}

.ti-arrow-big-top:before {
  content: "";
}

.ti-arrow-big-up-line:before {
  content: "";
}

.ti-arrow-big-up-lines:before {
  content: "";
}

.ti-arrow-bottom-bar:before {
  content: "";
}

.ti-arrow-bottom-circle:before {
  content: "";
}

.ti-arrow-bottom-square:before {
  content: "";
}

.ti-arrow-bottom-tail:before {
  content: "";
}

.ti-arrow-curve-left:before {
  content: "";
}

.ti-arrow-curve-right:before {
  content: "";
}

.ti-arrow-down:before {
  content: "";
}

.ti-arrow-down-circle:before {
  content: "";
}

.ti-arrow-down-left:before {
  content: "";
}

.ti-arrow-down-left-circle:before {
  content: "";
}

.ti-arrow-down-right:before {
  content: "";
}

.ti-arrow-down-right-circle:before {
  content: "";
}

.ti-arrow-fork:before {
  content: "";
}

.ti-arrow-forward:before {
  content: "";
}

.ti-arrow-forward-up:before {
  content: "";
}

.ti-arrow-left:before {
  content: "";
}

.ti-arrow-left-bar:before {
  content: "";
}

.ti-arrow-left-circle:before {
  content: "";
}

.ti-arrow-left-right:before {
  content: "";
}

.ti-arrow-left-square:before {
  content: "";
}

.ti-arrow-left-tail:before {
  content: "";
}

.ti-arrow-loop-left:before {
  content: "";
}

.ti-arrow-loop-left-2:before {
  content: "";
}

.ti-arrow-loop-right:before {
  content: "";
}

.ti-arrow-loop-right-2:before {
  content: "";
}

.ti-arrow-merge:before {
  content: "";
}

.ti-arrow-narrow-down:before {
  content: "";
}

.ti-arrow-narrow-left:before {
  content: "";
}

.ti-arrow-narrow-right:before {
  content: "";
}

.ti-arrow-narrow-up:before {
  content: "";
}

.ti-arrow-ramp-left:before {
  content: "";
}

.ti-arrow-ramp-left-2:before {
  content: "";
}

.ti-arrow-ramp-left-3:before {
  content: "";
}

.ti-arrow-ramp-right:before {
  content: "";
}

.ti-arrow-ramp-right-2:before {
  content: "";
}

.ti-arrow-ramp-right-3:before {
  content: "";
}

.ti-arrow-right:before {
  content: "";
}

.ti-arrow-right-bar:before {
  content: "";
}

.ti-arrow-right-circle:before {
  content: "";
}

.ti-arrow-right-square:before {
  content: "";
}

.ti-arrow-right-tail:before {
  content: "";
}

.ti-arrow-rotary-first-left:before {
  content: "";
}

.ti-arrow-rotary-first-right:before {
  content: "";
}

.ti-arrow-rotary-last-left:before {
  content: "";
}

.ti-arrow-rotary-last-right:before {
  content: "";
}

.ti-arrow-rotary-left:before {
  content: "";
}

.ti-arrow-rotary-right:before {
  content: "";
}

.ti-arrow-rotary-straight:before {
  content: "";
}

.ti-arrow-sharp-turn-left:before {
  content: "";
}

.ti-arrow-sharp-turn-right:before {
  content: "";
}

.ti-arrow-top-bar:before {
  content: "";
}

.ti-arrow-top-circle:before {
  content: "";
}

.ti-arrow-top-square:before {
  content: "";
}

.ti-arrow-top-tail:before {
  content: "";
}

.ti-arrow-up:before {
  content: "";
}

.ti-arrow-up-circle:before {
  content: "";
}

.ti-arrow-up-left:before {
  content: "";
}

.ti-arrow-up-left-circle:before {
  content: "";
}

.ti-arrow-up-right:before {
  content: "";
}

.ti-arrow-up-right-circle:before {
  content: "";
}

.ti-arrow-wave-left-down:before {
  content: "";
}

.ti-arrow-wave-left-up:before {
  content: "";
}

.ti-arrow-wave-right-down:before {
  content: "";
}

.ti-arrow-wave-right-up:before {
  content: "";
}

.ti-arrows-cross:before {
  content: "";
}

.ti-arrows-diagonal:before {
  content: "";
}

.ti-arrows-diagonal-2:before {
  content: "";
}

.ti-arrows-diagonal-minimize:before {
  content: "";
}

.ti-arrows-diagonal-minimize-2:before {
  content: "";
}

.ti-arrows-double-ne-sw:before {
  content: "";
}

.ti-arrows-double-nw-se:before {
  content: "";
}

.ti-arrows-double-se-nw:before {
  content: "";
}

.ti-arrows-double-sw-ne:before {
  content: "";
}

.ti-arrows-down:before {
  content: "";
}

.ti-arrows-down-up:before {
  content: "";
}

.ti-arrows-exchange:before {
  content: "";
}

.ti-arrows-exchange-2:before {
  content: "";
}

.ti-arrows-horizontal:before {
  content: "";
}

.ti-arrows-join:before {
  content: "";
}

.ti-arrows-join-2:before {
  content: "";
}

.ti-arrows-left:before {
  content: "";
}

.ti-arrows-left-down:before {
  content: "";
}

.ti-arrows-left-right:before {
  content: "";
}

.ti-arrows-maximize:before {
  content: "";
}

.ti-arrows-minimize:before {
  content: "";
}

.ti-arrows-random:before {
  content: "";
}

.ti-arrows-right:before {
  content: "";
}

.ti-arrows-right-down:before {
  content: "";
}

.ti-arrows-right-left:before {
  content: "";
}

.ti-arrows-shuffle:before {
  content: "";
}

.ti-arrows-shuffle-2:before {
  content: "";
}

.ti-arrows-sort:before {
  content: "";
}

.ti-arrows-split:before {
  content: "";
}

.ti-arrows-split-2:before {
  content: "";
}

.ti-arrows-up:before {
  content: "";
}

.ti-arrows-up-down:before {
  content: "";
}

.ti-arrows-up-left:before {
  content: "";
}

.ti-arrows-up-right:before {
  content: "";
}

.ti-arrows-vertical:before {
  content: "";
}

.ti-artboard:before {
  content: "";
}

.ti-artboard-off:before {
  content: "";
}

.ti-article:before {
  content: "";
}

.ti-aspect-ratio:before {
  content: "";
}

.ti-aspect-ratio-off:before {
  content: "";
}

.ti-asset:before {
  content: "";
}

.ti-asterisk:before {
  content: "";
}

.ti-asterisk-simple:before {
  content: "";
}

.ti-at:before {
  content: "";
}

.ti-at-off:before {
  content: "";
}

.ti-atom:before {
  content: "";
}

.ti-atom-2:before {
  content: "";
}

.ti-atom-off:before {
  content: "";
}

.ti-augmented-reality:before {
  content: "";
}

.ti-award:before {
  content: "";
}

.ti-award-off:before {
  content: "";
}

.ti-axe:before {
  content: "";
}

.ti-axis-x:before {
  content: "";
}

.ti-axis-y:before {
  content: "";
}

.ti-baby-carriage:before {
  content: "";
}

.ti-backhoe:before {
  content: "";
}

.ti-backpack:before {
  content: "";
}

.ti-backspace:before {
  content: "";
}

.ti-badge:before {
  content: "";
}

.ti-badge-off:before {
  content: "";
}

.ti-badges:before {
  content: "";
}

.ti-badges-off:before {
  content: "";
}

.ti-ball-american-football:before {
  content: "";
}

.ti-ball-baseball:before {
  content: "";
}

.ti-ball-basketball:before {
  content: "";
}

.ti-ball-bowling:before {
  content: "";
}

.ti-ball-football:before {
  content: "";
}

.ti-ball-football-off:before {
  content: "";
}

.ti-ball-tennis:before {
  content: "";
}

.ti-ball-volleyball:before {
  content: "";
}

.ti-ballon:before {
  content: "";
}

.ti-ballon-off:before {
  content: "";
}

.ti-ballpen:before {
  content: "";
}

.ti-ballpen-off:before {
  content: "";
}

.ti-ban:before {
  content: "";
}

.ti-bandage:before {
  content: "";
}

.ti-barbell:before {
  content: "";
}

.ti-barbell-off:before {
  content: "";
}

.ti-barcode:before {
  content: "";
}

.ti-barcode-off:before {
  content: "";
}

.ti-barrel:before {
  content: "";
}

.ti-barrel-off:before {
  content: "";
}

.ti-barrier-block:before {
  content: "";
}

.ti-barrier-block-off:before {
  content: "";
}

.ti-baseline:before {
  content: "";
}

.ti-basket:before {
  content: "";
}

.ti-basket-off:before {
  content: "";
}

.ti-bath:before {
  content: "";
}

.ti-bath-off:before {
  content: "";
}

.ti-battery:before {
  content: "";
}

.ti-battery-1:before {
  content: "";
}

.ti-battery-2:before {
  content: "";
}

.ti-battery-3:before {
  content: "";
}

.ti-battery-4:before {
  content: "";
}

.ti-battery-automotive:before {
  content: "";
}

.ti-battery-charging:before {
  content: "";
}

.ti-battery-charging-2:before {
  content: "";
}

.ti-battery-eco:before {
  content: "";
}

.ti-battery-off:before {
  content: "";
}

.ti-beach:before {
  content: "";
}

.ti-beach-off:before {
  content: "";
}

.ti-bed:before {
  content: "";
}

.ti-bed-off:before {
  content: "";
}

.ti-beer:before {
  content: "";
}

.ti-beer-off:before {
  content: "";
}

.ti-bell:before {
  content: "";
}

.ti-bell-minus:before {
  content: "";
}

.ti-bell-off:before {
  content: "";
}

.ti-bell-plus:before {
  content: "";
}

.ti-bell-ringing:before {
  content: "";
}

.ti-bell-ringing-2:before {
  content: "";
}

.ti-bell-school:before {
  content: "";
}

.ti-bell-x:before {
  content: "";
}

.ti-bell-z:before {
  content: "";
}

.ti-bible:before {
  content: "";
}

.ti-bike:before {
  content: "";
}

.ti-bike-off:before {
  content: "";
}

.ti-binary:before {
  content: "";
}

.ti-biohazard:before {
  content: "";
}

.ti-biohazard-off:before {
  content: "";
}

.ti-blockquote:before {
  content: "";
}

.ti-bluetooth:before {
  content: "";
}

.ti-bluetooth-connected:before {
  content: "";
}

.ti-bluetooth-off:before {
  content: "";
}

.ti-bluetooth-x:before {
  content: "";
}

.ti-blur:before {
  content: "";
}

.ti-bold:before {
  content: "";
}

.ti-bold-off:before {
  content: "";
}

.ti-bolt:before {
  content: "";
}

.ti-bolt-off:before {
  content: "";
}

.ti-bone:before {
  content: "";
}

.ti-bone-off:before {
  content: "";
}

.ti-book:before {
  content: "";
}

.ti-book-2:before {
  content: "";
}

.ti-book-download:before {
  content: "";
}

.ti-book-off:before {
  content: "";
}

.ti-book-upload:before {
  content: "";
}

.ti-bookmark:before {
  content: "";
}

.ti-bookmark-off:before {
  content: "";
}

.ti-bookmarks:before {
  content: "";
}

.ti-bookmarks-off:before {
  content: "";
}

.ti-books:before {
  content: "";
}

.ti-books-off:before {
  content: "";
}

.ti-border-all:before {
  content: "";
}

.ti-border-bottom:before {
  content: "";
}

.ti-border-horizontal:before {
  content: "";
}

.ti-border-inner:before {
  content: "";
}

.ti-border-left:before {
  content: "";
}

.ti-border-none:before {
  content: "";
}

.ti-border-outer:before {
  content: "";
}

.ti-border-radius:before {
  content: "";
}

.ti-border-right:before {
  content: "";
}

.ti-border-style:before {
  content: "";
}

.ti-border-style-2:before {
  content: "";
}

.ti-border-top:before {
  content: "";
}

.ti-border-vertical:before {
  content: "";
}

.ti-bottle:before {
  content: "";
}

.ti-bow:before {
  content: "";
}

.ti-box:before {
  content: "";
}

.ti-box-margin:before {
  content: "";
}

.ti-box-model:before {
  content: "";
}

.ti-box-model-2:before {
  content: "";
}

.ti-box-multiple:before {
  content: "";
}

.ti-box-multiple-0:before {
  content: "";
}

.ti-box-multiple-1:before {
  content: "";
}

.ti-box-multiple-2:before {
  content: "";
}

.ti-box-multiple-3:before {
  content: "";
}

.ti-box-multiple-4:before {
  content: "";
}

.ti-box-multiple-5:before {
  content: "";
}

.ti-box-multiple-6:before {
  content: "";
}

.ti-box-multiple-7:before {
  content: "";
}

.ti-box-multiple-8:before {
  content: "";
}

.ti-box-multiple-9:before {
  content: "";
}

.ti-box-off:before {
  content: "";
}

.ti-box-padding:before {
  content: "";
}

.ti-braces:before {
  content: "";
}

.ti-braces-off:before {
  content: "";
}

.ti-brackets:before {
  content: "";
}

.ti-brackets-contain:before {
  content: "";
}

.ti-brackets-contain-end:before {
  content: "";
}

.ti-brackets-contain-start:before {
  content: "";
}

.ti-brackets-off:before {
  content: "";
}

.ti-brand-adobe:before {
  content: "";
}

.ti-brand-airbnb:before {
  content: "";
}

.ti-brand-airtable:before {
  content: "";
}

.ti-brand-amongus:before {
  content: "";
}

.ti-brand-android:before {
  content: "";
}

.ti-brand-angular:before {
  content: "";
}

.ti-brand-apple:before {
  content: "";
}

.ti-brand-apple-arcade:before {
  content: "";
}

.ti-brand-apple-podcast:before {
  content: "";
}

.ti-brand-appstore:before {
  content: "";
}

.ti-brand-asana:before {
  content: "";
}

.ti-brand-badoo:before {
  content: "";
}

.ti-brand-bandcamp:before {
  content: "";
}

.ti-brand-beats:before {
  content: "";
}

.ti-brand-behance:before {
  content: "";
}

.ti-brand-bing:before {
  content: "";
}

.ti-brand-bitbucket:before {
  content: "";
}

.ti-brand-booking:before {
  content: "";
}

.ti-brand-bootstrap:before {
  content: "";
}

.ti-brand-chrome:before {
  content: "";
}

.ti-brand-codepen:before {
  content: "";
}

.ti-brand-codesandbox:before {
  content: "";
}

.ti-brand-coinbase:before {
  content: "";
}

.ti-brand-comedy-central:before {
  content: "";
}

.ti-brand-css3:before {
  content: "";
}

.ti-brand-cucumber:before {
  content: "";
}

.ti-brand-debian:before {
  content: "";
}

.ti-brand-deviantart:before {
  content: "";
}

.ti-brand-discord:before {
  content: "";
}

.ti-brand-disney:before {
  content: "";
}

.ti-brand-disqus:before {
  content: "";
}

.ti-brand-docker:before {
  content: "";
}

.ti-brand-doctrine:before {
  content: "";
}

.ti-brand-dribbble:before {
  content: "";
}

.ti-brand-edge:before {
  content: "";
}

.ti-brand-facebook:before {
  content: "";
}

.ti-brand-figma:before {
  content: "";
}

.ti-brand-finder:before {
  content: "";
}

.ti-brand-firebase:before {
  content: "";
}

.ti-brand-firefox:before {
  content: "";
}

.ti-brand-flickr:before {
  content: "";
}

.ti-brand-flipboard:before {
  content: "";
}

.ti-brand-foursquare:before {
  content: "";
}

.ti-brand-framer:before {
  content: "";
}

.ti-brand-git:before {
  content: "";
}

.ti-brand-github:before {
  content: "";
}

.ti-brand-gitlab:before {
  content: "";
}

.ti-brand-gmail:before {
  content: "";
}

.ti-brand-google:before {
  content: "";
}

.ti-brand-google-analytics:before {
  content: "";
}

.ti-brand-google-drive:before {
  content: "";
}

.ti-brand-google-photos:before {
  content: "";
}

.ti-brand-google-play:before {
  content: "";
}

.ti-brand-gravatar:before {
  content: "";
}

.ti-brand-grindr:before {
  content: "";
}

.ti-brand-hipchat:before {
  content: "";
}

.ti-brand-html5:before {
  content: "";
}

.ti-brand-instagram:before {
  content: "";
}

.ti-brand-intercom:before {
  content: "";
}

.ti-brand-javascript:before {
  content: "";
}

.ti-brand-kickstarter:before {
  content: "";
}

.ti-brand-kotlin:before {
  content: "";
}

.ti-brand-lastfm:before {
  content: "";
}

.ti-brand-linkedin:before {
  content: "";
}

.ti-brand-linktree:before {
  content: "";
}

.ti-brand-loom:before {
  content: "";
}

.ti-brand-mastercard:before {
  content: "";
}

.ti-brand-medium:before {
  content: "";
}

.ti-brand-mercedes:before {
  content: "";
}

.ti-brand-messenger:before {
  content: "";
}

.ti-brand-meta:before {
  content: "";
}

.ti-brand-monday:before {
  content: "";
}

.ti-brand-netbeans:before {
  content: "";
}

.ti-brand-netflix:before {
  content: "";
}

.ti-brand-nextjs:before {
  content: "";
}

.ti-brand-notion:before {
  content: "";
}

.ti-brand-nuxt:before {
  content: "";
}

.ti-brand-nytimes:before {
  content: "";
}

.ti-brand-open-source:before {
  content: "";
}

.ti-brand-opera:before {
  content: "";
}

.ti-brand-pagekit:before {
  content: "";
}

.ti-brand-patreon:before {
  content: "";
}

.ti-brand-paypal:before {
  content: "";
}

.ti-brand-php:before {
  content: "";
}

.ti-brand-pinterest:before {
  content: "";
}

.ti-brand-pocket:before {
  content: "";
}

.ti-brand-producthunt:before {
  content: "";
}

.ti-brand-pushover:before {
  content: "";
}

.ti-brand-python:before {
  content: "";
}

.ti-brand-react-native:before {
  content: "";
}

.ti-brand-reddit:before {
  content: "";
}

.ti-brand-safari:before {
  content: "";
}

.ti-brand-sass:before {
  content: "";
}

.ti-brand-sentry:before {
  content: "";
}

.ti-brand-shazam:before {
  content: "";
}

.ti-brand-sketch:before {
  content: "";
}

.ti-brand-skype:before {
  content: "";
}

.ti-brand-slack:before {
  content: "";
}

.ti-brand-snapchat:before {
  content: "";
}

.ti-brand-soundcloud:before {
  content: "";
}

.ti-brand-spotify:before {
  content: "";
}

.ti-brand-stackoverflow:before {
  content: "";
}

.ti-brand-steam:before {
  content: "";
}

.ti-brand-stripe:before {
  content: "";
}

.ti-brand-sublime-text:before {
  content: "";
}

.ti-brand-svelte:before {
  content: "";
}

.ti-brand-tabler:before {
  content: "";
}

.ti-brand-tailwind:before {
  content: "";
}

.ti-brand-telegram:before {
  content: "";
}

.ti-brand-tidal:before {
  content: "";
}

.ti-brand-tiktok:before {
  content: "";
}

.ti-brand-tinder:before {
  content: "";
}

.ti-brand-tripadvisor:before {
  content: "";
}

.ti-brand-tumblr:before {
  content: "";
}

.ti-brand-twitch:before {
  content: "";
}

.ti-brand-twitter:before {
  content: "";
}

.ti-brand-uber:before {
  content: "";
}

.ti-brand-ubuntu:before {
  content: "";
}

.ti-brand-unsplash:before {
  content: "";
}

.ti-brand-vercel:before {
  content: "";
}

.ti-brand-vimeo:before {
  content: "";
}

.ti-brand-vinted:before {
  content: "";
}

.ti-brand-visual-studio:before {
  content: "";
}

.ti-brand-vivaldi:before {
  content: "";
}

.ti-brand-vk:before {
  content: "";
}

.ti-brand-vue:before {
  content: "";
}

.ti-brand-walmart:before {
  content: "";
}

.ti-brand-whatsapp:before {
  content: "";
}

.ti-brand-windows:before {
  content: "";
}

.ti-brand-wish:before {
  content: "";
}

.ti-brand-xing:before {
  content: "";
}

.ti-brand-yahoo:before {
  content: "";
}

.ti-brand-yatse:before {
  content: "";
}

.ti-brand-ycombinator:before {
  content: "";
}

.ti-brand-youtube:before {
  content: "";
}

.ti-brand-youtube-kids:before {
  content: "";
}

.ti-brand-zoom:before {
  content: "";
}

.ti-brand-zwift:before {
  content: "";
}

.ti-bread:before {
  content: "";
}

.ti-briefcase:before {
  content: "";
}

.ti-brightness:before {
  content: "";
}

.ti-brightness-2:before {
  content: "";
}

.ti-brightness-down:before {
  content: "";
}

.ti-brightness-half:before {
  content: "";
}

.ti-brightness-up:before {
  content: "";
}

.ti-broadcast:before {
  content: "";
}

.ti-broadcast-off:before {
  content: "";
}

.ti-browser:before {
  content: "";
}

.ti-browser-check:before {
  content: "";
}

.ti-browser-off:before {
  content: "";
}

.ti-browser-plus:before {
  content: "";
}

.ti-browser-x:before {
  content: "";
}

.ti-brush:before {
  content: "";
}

.ti-brush-off:before {
  content: "";
}

.ti-bucket:before {
  content: "";
}

.ti-bucket-off:before {
  content: "";
}

.ti-bug:before {
  content: "";
}

.ti-bug-off:before {
  content: "";
}

.ti-building:before {
  content: "";
}

.ti-building-arch:before {
  content: "";
}

.ti-building-bank:before {
  content: "";
}

.ti-building-bridge:before {
  content: "";
}

.ti-building-bridge-2:before {
  content: "";
}

.ti-building-carousel:before {
  content: "";
}

.ti-building-castle:before {
  content: "";
}

.ti-building-church:before {
  content: "";
}

.ti-building-community:before {
  content: "";
}

.ti-building-cottage:before {
  content: "";
}

.ti-building-factory:before {
  content: "";
}

.ti-building-factory-2:before {
  content: "";
}

.ti-building-fortress:before {
  content: "";
}

.ti-building-hospital:before {
  content: "";
}

.ti-building-lighthouse:before {
  content: "";
}

.ti-building-monument:before {
  content: "";
}

.ti-building-pavilon:before {
  content: "";
}

.ti-building-skyscraper:before {
  content: "";
}

.ti-building-store:before {
  content: "";
}

.ti-building-warehouse:before {
  content: "";
}

.ti-bulb:before {
  content: "";
}

.ti-bulb-off:before {
  content: "";
}

.ti-bulldozer:before {
  content: "";
}

.ti-bus:before {
  content: "";
}

.ti-businessplan:before {
  content: "";
}

.ti-butterfly:before {
  content: "";
}

.ti-c-sharp:before {
  content: "";
}

.ti-cactus:before {
  content: "";
}

.ti-cake:before {
  content: "";
}

.ti-cake-off:before {
  content: "";
}

.ti-calculator:before {
  content: "";
}

.ti-calculator-off:before {
  content: "";
}

.ti-calendar:before {
  content: "";
}

.ti-calendar-event:before {
  content: "";
}

.ti-calendar-minus:before {
  content: "";
}

.ti-calendar-off:before {
  content: "";
}

.ti-calendar-plus:before {
  content: "";
}

.ti-calendar-stats:before {
  content: "";
}

.ti-calendar-time:before {
  content: "";
}

.ti-camera:before {
  content: "";
}

.ti-camera-minus:before {
  content: "";
}

.ti-camera-off:before {
  content: "";
}

.ti-camera-plus:before {
  content: "";
}

.ti-camera-rotate:before {
  content: "";
}

.ti-camera-selfie:before {
  content: "";
}

.ti-candle:before {
  content: "";
}

.ti-candy:before {
  content: "";
}

.ti-candy-off:before {
  content: "";
}

.ti-capture:before {
  content: "";
}

.ti-capture-off:before {
  content: "";
}

.ti-car:before {
  content: "";
}

.ti-car-crane:before {
  content: "";
}

.ti-car-crash:before {
  content: "";
}

.ti-car-off:before {
  content: "";
}

.ti-caravan:before {
  content: "";
}

.ti-cardboards:before {
  content: "";
}

.ti-cardboards-off:before {
  content: "";
}

.ti-caret-down:before {
  content: "";
}

.ti-caret-left:before {
  content: "";
}

.ti-caret-right:before {
  content: "";
}

.ti-caret-up:before {
  content: "";
}

.ti-carrot:before {
  content: "";
}

.ti-cash:before {
  content: "";
}

.ti-cash-banknote:before {
  content: "";
}

.ti-cash-banknote-off:before {
  content: "";
}

.ti-cash-off:before {
  content: "";
}

.ti-cast:before {
  content: "";
}

.ti-cast-off:before {
  content: "";
}

.ti-category:before {
  content: "";
}

.ti-category-2:before {
  content: "";
}

.ti-ce:before {
  content: "";
}

.ti-ce-off:before {
  content: "";
}

.ti-cell:before {
  content: "";
}

.ti-cell-signal-1:before {
  content: "";
}

.ti-cell-signal-2:before {
  content: "";
}

.ti-cell-signal-3:before {
  content: "";
}

.ti-cell-signal-4:before {
  content: "";
}

.ti-cell-signal-5:before {
  content: "";
}

.ti-cell-signal-off:before {
  content: "";
}

.ti-certificate:before {
  content: "";
}

.ti-certificate-2:before {
  content: "";
}

.ti-certificate-2-off:before {
  content: "";
}

.ti-certificate-off:before {
  content: "";
}

.ti-charging-pile:before {
  content: "";
}

.ti-chart-arcs:before {
  content: "";
}

.ti-chart-arcs-3:before {
  content: "";
}

.ti-chart-area:before {
  content: "";
}

.ti-chart-area-line:before {
  content: "";
}

.ti-chart-arrows:before {
  content: "";
}

.ti-chart-arrows-vertical:before {
  content: "";
}

.ti-chart-bar:before {
  content: "";
}

.ti-chart-bubble:before {
  content: "";
}

.ti-chart-candle:before {
  content: "";
}

.ti-chart-circles:before {
  content: "";
}

.ti-chart-donut:before {
  content: "";
}

.ti-chart-donut-2:before {
  content: "";
}

.ti-chart-donut-3:before {
  content: "";
}

.ti-chart-donut-4:before {
  content: "";
}

.ti-chart-dots:before {
  content: "";
}

.ti-chart-dots-2:before {
  content: "";
}

.ti-chart-dots-3:before {
  content: "";
}

.ti-chart-infographic:before {
  content: "";
}

.ti-chart-line:before {
  content: "";
}

.ti-chart-pie:before {
  content: "";
}

.ti-chart-pie-2:before {
  content: "";
}

.ti-chart-pie-3:before {
  content: "";
}

.ti-chart-pie-4:before {
  content: "";
}

.ti-chart-radar:before {
  content: "";
}

.ti-check:before {
  content: "";
}

.ti-checkbox:before {
  content: "";
}

.ti-checklist:before {
  content: "";
}

.ti-checks:before {
  content: "";
}

.ti-checkup-list:before {
  content: "";
}

.ti-cheese:before {
  content: "";
}

.ti-chef-hat:before {
  content: "";
}

.ti-chevron-down:before {
  content: "";
}

.ti-chevron-down-left:before {
  content: "";
}

.ti-chevron-down-right:before {
  content: "";
}

.ti-chevron-left:before {
  content: "";
}

.ti-chevron-right:before {
  content: "";
}

.ti-chevron-up:before {
  content: "";
}

.ti-chevron-up-left:before {
  content: "";
}

.ti-chevron-up-right:before {
  content: "";
}

.ti-chevrons-down:before {
  content: "";
}

.ti-chevrons-down-left:before {
  content: "";
}

.ti-chevrons-down-right:before {
  content: "";
}

.ti-chevrons-left:before {
  content: "";
}

.ti-chevrons-right:before {
  content: "";
}

.ti-chevrons-up:before {
  content: "";
}

.ti-chevrons-up-left:before {
  content: "";
}

.ti-chevrons-up-right:before {
  content: "";
}

.ti-christmas-tree:before {
  content: "";
}

.ti-circle:before {
  content: "";
}

.ti-circle-0:before {
  content: "";
}

.ti-circle-1:before {
  content: "";
}

.ti-circle-2:before {
  content: "";
}

.ti-circle-3:before {
  content: "";
}

.ti-circle-4:before {
  content: "";
}

.ti-circle-5:before {
  content: "";
}

.ti-circle-6:before {
  content: "";
}

.ti-circle-7:before {
  content: "";
}

.ti-circle-8:before {
  content: "";
}

.ti-circle-9:before {
  content: "";
}

.ti-circle-check:before {
  content: "";
}

.ti-circle-dashed:before {
  content: "";
}

.ti-circle-dot:before {
  content: "";
}

.ti-circle-dotted:before {
  content: "";
}

.ti-circle-half:before {
  content: "";
}

.ti-circle-half-2:before {
  content: "";
}

.ti-circle-half-vertical:before {
  content: "";
}

.ti-circle-minus:before {
  content: "";
}

.ti-circle-off:before {
  content: "";
}

.ti-circle-plus:before {
  content: "";
}

.ti-circle-rectangle:before {
  content: "";
}

.ti-circle-rectangle-off:before {
  content: "";
}

.ti-circle-square:before {
  content: "";
}

.ti-circle-triangle:before {
  content: "";
}

.ti-circle-x:before {
  content: "";
}

.ti-circles:before {
  content: "";
}

.ti-clear-all:before {
  content: "";
}

.ti-clear-formatting:before {
  content: "";
}

.ti-click:before {
  content: "";
}

.ti-clipboard:before {
  content: "";
}

.ti-clipboard-check:before {
  content: "";
}

.ti-clipboard-list:before {
  content: "";
}

.ti-clipboard-off:before {
  content: "";
}

.ti-clipboard-plus:before {
  content: "";
}

.ti-clipboard-text:before {
  content: "";
}

.ti-clipboard-x:before {
  content: "";
}

.ti-clock:before {
  content: "";
}

.ti-clock-2:before {
  content: "";
}

.ti-clock-off:before {
  content: "";
}

.ti-cloud:before {
  content: "";
}

.ti-cloud-computing:before {
  content: "";
}

.ti-cloud-data-connection:before {
  content: "";
}

.ti-cloud-download:before {
  content: "";
}

.ti-cloud-fog:before {
  content: "";
}

.ti-cloud-lock:before {
  content: "";
}

.ti-cloud-lock-open:before {
  content: "";
}

.ti-cloud-off:before {
  content: "";
}

.ti-cloud-rain:before {
  content: "";
}

.ti-cloud-snow:before {
  content: "";
}

.ti-cloud-storm:before {
  content: "";
}

.ti-cloud-upload:before {
  content: "";
}

.ti-clover:before {
  content: "";
}

.ti-clover-2:before {
  content: "";
}

.ti-clubs:before {
  content: "";
}

.ti-code:before {
  content: "";
}

.ti-code-minus:before {
  content: "";
}

.ti-code-off:before {
  content: "";
}

.ti-code-plus:before {
  content: "";
}

.ti-coffee:before {
  content: "";
}

.ti-coffee-off:before {
  content: "";
}

.ti-coin:before {
  content: "";
}

.ti-coin-off:before {
  content: "";
}

.ti-color-picker:before {
  content: "";
}

.ti-color-picker-off:before {
  content: "";
}

.ti-color-swatch:before {
  content: "";
}

.ti-color-swatch-off:before {
  content: "";
}

.ti-column-insert-left:before {
  content: "";
}

.ti-column-insert-right:before {
  content: "";
}

.ti-columns:before {
  content: "";
}

.ti-columns-off:before {
  content: "";
}

.ti-comet:before {
  content: "";
}

.ti-command:before {
  content: "";
}

.ti-compass:before {
  content: "";
}

.ti-compass-off:before {
  content: "";
}

.ti-components:before {
  content: "";
}

.ti-components-off:before {
  content: "";
}

.ti-cone:before {
  content: "";
}

.ti-cone-2:before {
  content: "";
}

.ti-confetti:before {
  content: "";
}

.ti-container:before {
  content: "";
}

.ti-container-off:before {
  content: "";
}

.ti-contrast:before {
  content: "";
}

.ti-contrast-2:before {
  content: "";
}

.ti-cookie:before {
  content: "";
}

.ti-cookie-off:before {
  content: "";
}

.ti-copy:before {
  content: "";
}

.ti-copy-off:before {
  content: "";
}

.ti-copyleft:before {
  content: "";
}

.ti-copyleft-off:before {
  content: "";
}

.ti-copyright:before {
  content: "";
}

.ti-copyright-off:before {
  content: "";
}

.ti-corner-down-left:before {
  content: "";
}

.ti-corner-down-left-double:before {
  content: "";
}

.ti-corner-down-right:before {
  content: "";
}

.ti-corner-down-right-double:before {
  content: "";
}

.ti-corner-left-down:before {
  content: "";
}

.ti-corner-left-down-double:before {
  content: "";
}

.ti-corner-left-up:before {
  content: "";
}

.ti-corner-left-up-double:before {
  content: "";
}

.ti-corner-right-down:before {
  content: "";
}

.ti-corner-right-down-double:before {
  content: "";
}

.ti-corner-right-up:before {
  content: "";
}

.ti-corner-right-up-double:before {
  content: "";
}

.ti-corner-up-left:before {
  content: "";
}

.ti-corner-up-left-double:before {
  content: "";
}

.ti-corner-up-right:before {
  content: "";
}

.ti-corner-up-right-double:before {
  content: "";
}

.ti-cpu:before {
  content: "";
}

.ti-cpu-2:before {
  content: "";
}

.ti-cpu-off:before {
  content: "";
}

.ti-crane:before {
  content: "";
}

.ti-crane-off:before {
  content: "";
}

.ti-creative-commons:before {
  content: "";
}

.ti-creative-commons-by:before {
  content: "";
}

.ti-creative-commons-nc:before {
  content: "";
}

.ti-creative-commons-nd:before {
  content: "";
}

.ti-creative-commons-off:before {
  content: "";
}

.ti-creative-commons-sa:before {
  content: "";
}

.ti-creative-commons-zero:before {
  content: "";
}

.ti-credit-card:before {
  content: "";
}

.ti-credit-card-off:before {
  content: "";
}

.ti-cricket:before {
  content: "";
}

.ti-crop:before {
  content: "";
}

.ti-cross:before {
  content: "";
}

.ti-cross-off:before {
  content: "";
}

.ti-crosshair:before {
  content: "";
}

.ti-crown:before {
  content: "";
}

.ti-crown-off:before {
  content: "";
}

.ti-crutches:before {
  content: "";
}

.ti-crutches-off:before {
  content: "";
}

.ti-cup:before {
  content: "";
}

.ti-cup-off:before {
  content: "";
}

.ti-curling:before {
  content: "";
}

.ti-curly-loop:before {
  content: "";
}

.ti-currency:before {
  content: "";
}

.ti-currency-bahraini:before {
  content: "";
}

.ti-currency-baht:before {
  content: "";
}

.ti-currency-bitcoin:before {
  content: "";
}

.ti-currency-cent:before {
  content: "";
}

.ti-currency-dinar:before {
  content: "";
}

.ti-currency-dirham:before {
  content: "";
}

.ti-currency-dogecoin:before {
  content: "";
}

.ti-currency-dollar:before {
  content: "";
}

.ti-currency-dollar-australian:before {
  content: "";
}

.ti-currency-dollar-canadian:before {
  content: "";
}

.ti-currency-dollar-singapore:before {
  content: "";
}

.ti-currency-ethereum:before {
  content: "";
}

.ti-currency-euro:before {
  content: "";
}

.ti-currency-forint:before {
  content: "";
}

.ti-currency-frank:before {
  content: "";
}

.ti-currency-krone-czech:before {
  content: "";
}

.ti-currency-krone-danish:before {
  content: "";
}

.ti-currency-krone-swedish:before {
  content: "";
}

.ti-currency-leu:before {
  content: "";
}

.ti-currency-lira:before {
  content: "";
}

.ti-currency-litecoin:before {
  content: "";
}

.ti-currency-naira:before {
  content: "";
}

.ti-currency-pound:before {
  content: "";
}

.ti-currency-real:before {
  content: "";
}

.ti-currency-renminbi:before {
  content: "";
}

.ti-currency-ripple:before {
  content: "";
}

.ti-currency-riyal:before {
  content: "";
}

.ti-currency-rubel:before {
  content: "";
}

.ti-currency-rupee:before {
  content: "";
}

.ti-currency-shekel:before {
  content: "";
}

.ti-currency-taka:before {
  content: "";
}

.ti-currency-tugrik:before {
  content: "";
}

.ti-currency-won:before {
  content: "";
}

.ti-currency-yen:before {
  content: "";
}

.ti-currency-zloty:before {
  content: "";
}

.ti-current-location:before {
  content: "";
}

.ti-current-location-off:before {
  content: "";
}

.ti-cursor-off:before {
  content: "";
}

.ti-cursor-text:before {
  content: "";
}

.ti-cut:before {
  content: "";
}

.ti-dashboard:before {
  content: "";
}

.ti-database:before {
  content: "";
}

.ti-database-export:before {
  content: "";
}

.ti-database-import:before {
  content: "";
}

.ti-database-off:before {
  content: "";
}

.ti-dental:before {
  content: "";
}

.ti-dental-off:before {
  content: "";
}

.ti-details:before {
  content: "";
}

.ti-device-analytics:before {
  content: "";
}

.ti-device-audio-tape:before {
  content: "";
}

.ti-device-cctv:before {
  content: "";
}

.ti-device-computer-camera:before {
  content: "";
}

.ti-device-computer-camera-off:before {
  content: "";
}

.ti-device-desktop:before {
  content: "";
}

.ti-device-desktop-analytics:before {
  content: "";
}

.ti-device-desktop-off:before {
  content: "";
}

.ti-device-floppy:before {
  content: "";
}

.ti-device-gamepad:before {
  content: "";
}

.ti-device-gamepad-2:before {
  content: "";
}

.ti-device-heart-monitor:before {
  content: "";
}

.ti-device-laptop:before {
  content: "";
}

.ti-device-laptop-off:before {
  content: "";
}

.ti-device-mobile:before {
  content: "";
}

.ti-device-mobile-charging:before {
  content: "";
}

.ti-device-mobile-message:before {
  content: "";
}

.ti-device-mobile-off:before {
  content: "";
}

.ti-device-mobile-rotated:before {
  content: "";
}

.ti-device-mobile-vibration:before {
  content: "";
}

.ti-device-nintendo:before {
  content: "";
}

.ti-device-nintendo-off:before {
  content: "";
}

.ti-device-speaker:before {
  content: "";
}

.ti-device-speaker-off:before {
  content: "";
}

.ti-device-tablet:before {
  content: "";
}

.ti-device-tablet-off:before {
  content: "";
}

.ti-device-tv:before {
  content: "";
}

.ti-device-tv-off:before {
  content: "";
}

.ti-device-tv-old:before {
  content: "";
}

.ti-device-watch:before {
  content: "";
}

.ti-device-watch-off:before {
  content: "";
}

.ti-device-watch-stats:before {
  content: "";
}

.ti-device-watch-stats-2:before {
  content: "";
}

.ti-devices:before {
  content: "";
}

.ti-devices-2:before {
  content: "";
}

.ti-devices-off:before {
  content: "";
}

.ti-devices-pc:before {
  content: "";
}

.ti-devices-pc-off:before {
  content: "";
}

.ti-dialpad:before {
  content: "";
}

.ti-dialpad-off:before {
  content: "";
}

.ti-diamond:before {
  content: "";
}

.ti-diamond-off:before {
  content: "";
}

.ti-diamonds:before {
  content: "";
}

.ti-dice:before {
  content: "";
}

.ti-dice-1:before {
  content: "";
}

.ti-dice-2:before {
  content: "";
}

.ti-dice-3:before {
  content: "";
}

.ti-dice-4:before {
  content: "";
}

.ti-dice-5:before {
  content: "";
}

.ti-dice-6:before {
  content: "";
}

.ti-dimensions:before {
  content: "";
}

.ti-direction:before {
  content: "";
}

.ti-direction-horizontal:before {
  content: "";
}

.ti-direction-sign:before {
  content: "";
}

.ti-directions:before {
  content: "";
}

.ti-directions-off:before {
  content: "";
}

.ti-disabled:before {
  content: "";
}

.ti-disabled-2:before {
  content: "";
}

.ti-disabled-off:before {
  content: "";
}

.ti-disc:before {
  content: "";
}

.ti-disc-off:before {
  content: "";
}

.ti-discount:before {
  content: "";
}

.ti-discount-2:before {
  content: "";
}

.ti-discount-check:before {
  content: "";
}

.ti-divide:before {
  content: "";
}

.ti-dna:before {
  content: "";
}

.ti-dna-2:before {
  content: "";
}

.ti-dna-2-off:before {
  content: "";
}

.ti-dna-off:before {
  content: "";
}

.ti-dog-bowl:before {
  content: "";
}

.ti-door:before {
  content: "";
}

.ti-door-enter:before {
  content: "";
}

.ti-door-exit:before {
  content: "";
}

.ti-door-off:before {
  content: "";
}

.ti-dots:before {
  content: "";
}

.ti-dots-circle-horizontal:before {
  content: "";
}

.ti-dots-diagonal:before {
  content: "";
}

.ti-dots-diagonal-2:before {
  content: "";
}

.ti-dots-vertical:before {
  content: "";
}

.ti-download:before {
  content: "";
}

.ti-download-off:before {
  content: "";
}

.ti-drag-drop:before {
  content: "";
}

.ti-drag-drop-2:before {
  content: "";
}

.ti-drone:before {
  content: "";
}

.ti-drone-off:before {
  content: "";
}

.ti-drop-circle:before {
  content: "";
}

.ti-droplet:before {
  content: "";
}

.ti-droplet-filled:before {
  content: "";
}

.ti-droplet-filled-2:before {
  content: "";
}

.ti-droplet-half:before {
  content: "";
}

.ti-droplet-half-2:before {
  content: "";
}

.ti-droplet-off:before {
  content: "";
}

.ti-ear:before {
  content: "";
}

.ti-ear-off:before {
  content: "";
}

.ti-edit:before {
  content: "";
}

.ti-edit-circle:before {
  content: "";
}

.ti-edit-circle-off:before {
  content: "";
}

.ti-edit-off:before {
  content: "";
}

.ti-egg:before {
  content: "";
}

.ti-egg-off:before {
  content: "";
}

.ti-elevator:before {
  content: "";
}

.ti-emergency-bed:before {
  content: "";
}

.ti-emphasis:before {
  content: "";
}

.ti-engine:before {
  content: "";
}

.ti-engine-off:before {
  content: "";
}

.ti-equal:before {
  content: "";
}

.ti-equal-not:before {
  content: "";
}

.ti-eraser:before {
  content: "";
}

.ti-eraser-off:before {
  content: "";
}

.ti-error-404:before {
  content: "";
}

.ti-error-404-off:before {
  content: "";
}

.ti-exchange:before {
  content: "";
}

.ti-exchange-off:before {
  content: "";
}

.ti-exclamation-mark:before {
  content: "";
}

.ti-exclamation-mark-off:before {
  content: "";
}

.ti-exposure:before {
  content: "";
}

.ti-external-link:before {
  content: "";
}

.ti-external-link-off:before {
  content: "";
}

.ti-eye:before {
  content: "";
}

.ti-eye-check:before {
  content: "";
}

.ti-eye-off:before {
  content: "";
}

.ti-eye-table:before {
  content: "";
}

.ti-eyeglass:before {
  content: "";
}

.ti-eyeglass-2:before {
  content: "";
}

.ti-eyeglass-off:before {
  content: "";
}

.ti-face-id:before {
  content: "";
}

.ti-face-id-error:before {
  content: "";
}

.ti-face-mask:before {
  content: "";
}

.ti-face-mask-off:before {
  content: "";
}

.ti-fall:before {
  content: "";
}

.ti-feather:before {
  content: "";
}

.ti-feather-off:before {
  content: "";
}

.ti-fence:before {
  content: "";
}

.ti-fence-off:before {
  content: "";
}

.ti-fidget-spinner:before {
  content: "";
}

.ti-file:before {
  content: "";
}

.ti-file-3d:before {
  content: "";
}

.ti-file-alert:before {
  content: "";
}

.ti-file-analytics:before {
  content: "";
}

.ti-file-arrow-left:before {
  content: "";
}

.ti-file-arrow-right:before {
  content: "";
}

.ti-file-barcode:before {
  content: "";
}

.ti-file-certificate:before {
  content: "";
}

.ti-file-chart:before {
  content: "";
}

.ti-file-check:before {
  content: "";
}

.ti-file-code:before {
  content: "";
}

.ti-file-code-2:before {
  content: "";
}

.ti-file-database:before {
  content: "";
}

.ti-file-description:before {
  content: "";
}

.ti-file-diff:before {
  content: "";
}

.ti-file-digit:before {
  content: "";
}

.ti-file-dislike:before {
  content: "";
}

.ti-file-dollar:before {
  content: "";
}

.ti-file-dots:before {
  content: "";
}

.ti-file-download:before {
  content: "";
}

.ti-file-euro:before {
  content: "";
}

.ti-file-export:before {
  content: "";
}

.ti-file-horizontal:before {
  content: "";
}

.ti-file-import:before {
  content: "";
}

.ti-file-info:before {
  content: "";
}

.ti-file-invoice:before {
  content: "";
}

.ti-file-like:before {
  content: "";
}

.ti-file-minus:before {
  content: "";
}

.ti-file-music:before {
  content: "";
}

.ti-file-off:before {
  content: "";
}

.ti-file-pencil:before {
  content: "";
}

.ti-file-phone:before {
  content: "";
}

.ti-file-plus:before {
  content: "";
}

.ti-file-power:before {
  content: "";
}

.ti-file-report:before {
  content: "";
}

.ti-file-rss:before {
  content: "";
}

.ti-file-scissors:before {
  content: "";
}

.ti-file-search:before {
  content: "";
}

.ti-file-settings:before {
  content: "";
}

.ti-file-shredder:before {
  content: "";
}

.ti-file-signal:before {
  content: "";
}

.ti-file-spreadsheet:before {
  content: "";
}

.ti-file-star:before {
  content: "";
}

.ti-file-symlink:before {
  content: "";
}

.ti-file-text:before {
  content: "";
}

.ti-file-time:before {
  content: "";
}

.ti-file-typography:before {
  content: "";
}

.ti-file-unknown:before {
  content: "";
}

.ti-file-upload:before {
  content: "";
}

.ti-file-vector:before {
  content: "";
}

.ti-file-x:before {
  content: "";
}

.ti-file-zip:before {
  content: "";
}

.ti-files:before {
  content: "";
}

.ti-files-off:before {
  content: "";
}

.ti-filter:before {
  content: "";
}

.ti-filter-off:before {
  content: "";
}

.ti-fingerprint:before {
  content: "";
}

.ti-fingerprint-off:before {
  content: "";
}

.ti-firetruck:before {
  content: "";
}

.ti-first-aid-kit:before {
  content: "";
}

.ti-fish:before {
  content: "";
}

.ti-fish-hook:before {
  content: "";
}

.ti-fish-off:before {
  content: "";
}

.ti-flag:before {
  content: "";
}

.ti-flag-2:before {
  content: "";
}

.ti-flag-2-off:before {
  content: "";
}

.ti-flag-3:before {
  content: "";
}

.ti-flag-off:before {
  content: "";
}

.ti-flame:before {
  content: "";
}

.ti-flame-off:before {
  content: "";
}

.ti-flare:before {
  content: "";
}

.ti-flask:before {
  content: "";
}

.ti-flask-2:before {
  content: "";
}

.ti-flask-2-off:before {
  content: "";
}

.ti-flask-off:before {
  content: "";
}

.ti-flip-horizontal:before {
  content: "";
}

.ti-flip-vertical:before {
  content: "";
}

.ti-float-center:before {
  content: "";
}

.ti-float-left:before {
  content: "";
}

.ti-float-none:before {
  content: "";
}

.ti-float-right:before {
  content: "";
}

.ti-flower:before {
  content: "";
}

.ti-flower-off:before {
  content: "";
}

.ti-focus:before {
  content: "";
}

.ti-focus-2:before {
  content: "";
}

.ti-focus-centered:before {
  content: "";
}

.ti-fold:before {
  content: "";
}

.ti-fold-down:before {
  content: "";
}

.ti-fold-up:before {
  content: "";
}

.ti-folder:before {
  content: "";
}

.ti-folder-minus:before {
  content: "";
}

.ti-folder-off:before {
  content: "";
}

.ti-folder-plus:before {
  content: "";
}

.ti-folder-x:before {
  content: "";
}

.ti-folders:before {
  content: "";
}

.ti-folders-off:before {
  content: "";
}

.ti-forbid:before {
  content: "";
}

.ti-forbid-2:before {
  content: "";
}

.ti-forklift:before {
  content: "";
}

.ti-forms:before {
  content: "";
}

.ti-fountain:before {
  content: "";
}

.ti-fountain-off:before {
  content: "";
}

.ti-frame:before {
  content: "";
}

.ti-frame-off:before {
  content: "";
}

.ti-free-rights:before {
  content: "";
}

.ti-fridge:before {
  content: "";
}

.ti-friends:before {
  content: "";
}

.ti-friends-off:before {
  content: "";
}

.ti-function:before {
  content: "";
}

.ti-gas-station:before {
  content: "";
}

.ti-gas-station-off:before {
  content: "";
}

.ti-gauge:before {
  content: "";
}

.ti-gauge-off:before {
  content: "";
}

.ti-gavel:before {
  content: "";
}

.ti-gender-agender:before {
  content: "";
}

.ti-gender-androgyne:before {
  content: "";
}

.ti-gender-bigender:before {
  content: "";
}

.ti-gender-demiboy:before {
  content: "";
}

.ti-gender-demigirl:before {
  content: "";
}

.ti-gender-epicene:before {
  content: "";
}

.ti-gender-female:before {
  content: "";
}

.ti-gender-femme:before {
  content: "";
}

.ti-gender-genderfluid:before {
  content: "";
}

.ti-gender-genderless:before {
  content: "";
}

.ti-gender-genderqueer:before {
  content: "";
}

.ti-gender-hermaphrodite:before {
  content: "";
}

.ti-gender-intergender:before {
  content: "";
}

.ti-gender-male:before {
  content: "";
}

.ti-gender-neutrois:before {
  content: "";
}

.ti-gender-third:before {
  content: "";
}

.ti-gender-transgender:before {
  content: "";
}

.ti-gender-trasvesti:before {
  content: "";
}

.ti-geometry:before {
  content: "";
}

.ti-ghost:before {
  content: "";
}

.ti-gift:before {
  content: "";
}

.ti-git-branch:before {
  content: "";
}

.ti-git-commit:before {
  content: "";
}

.ti-git-compare:before {
  content: "";
}

.ti-git-fork:before {
  content: "";
}

.ti-git-merge:before {
  content: "";
}

.ti-git-pull-request:before {
  content: "";
}

.ti-git-pull-request-closed:before {
  content: "";
}

.ti-git-pull-request-draft:before {
  content: "";
}

.ti-gizmo:before {
  content: "";
}

.ti-glass:before {
  content: "";
}

.ti-glass-full:before {
  content: "";
}

.ti-glass-off:before {
  content: "";
}

.ti-globe:before {
  content: "";
}

.ti-globe-off:before {
  content: "";
}

.ti-golf:before {
  content: "";
}

.ti-golf-off:before {
  content: "";
}

.ti-gps:before {
  content: "";
}

.ti-grain:before {
  content: "";
}

.ti-grid-dots:before {
  content: "";
}

.ti-grid-pattern:before {
  content: "";
}

.ti-grill:before {
  content: "";
}

.ti-grill-off:before {
  content: "";
}

.ti-grip-horizontal:before {
  content: "";
}

.ti-grip-vertical:before {
  content: "";
}

.ti-growth:before {
  content: "";
}

.ti-h-1:before {
  content: "";
}

.ti-h-2:before {
  content: "";
}

.ti-h-3:before {
  content: "";
}

.ti-h-4:before {
  content: "";
}

.ti-h-5:before {
  content: "";
}

.ti-h-6:before {
  content: "";
}

.ti-hammer:before {
  content: "";
}

.ti-hammer-off:before {
  content: "";
}

.ti-hand-click:before {
  content: "";
}

.ti-hand-finger:before {
  content: "";
}

.ti-hand-finger-off:before {
  content: "";
}

.ti-hand-grab:before {
  content: "";
}

.ti-hand-little-finger:before {
  content: "";
}

.ti-hand-middle-finger:before {
  content: "";
}

.ti-hand-move:before {
  content: "";
}

.ti-hand-off:before {
  content: "";
}

.ti-hand-ring-finger:before {
  content: "";
}

.ti-hand-rock:before {
  content: "";
}

.ti-hand-stop:before {
  content: "";
}

.ti-hand-three-fingers:before {
  content: "";
}

.ti-hand-two-fingers:before {
  content: "";
}

.ti-hanger:before {
  content: "";
}

.ti-hanger-2:before {
  content: "";
}

.ti-hanger-off:before {
  content: "";
}

.ti-hash:before {
  content: "";
}

.ti-haze:before {
  content: "";
}

.ti-heading:before {
  content: "";
}

.ti-heading-off:before {
  content: "";
}

.ti-headphones:before {
  content: "";
}

.ti-headphones-off:before {
  content: "";
}

.ti-headset:before {
  content: "";
}

.ti-health-recognition:before {
  content: "";
}

.ti-heart:before {
  content: "";
}

.ti-heart-broken:before {
  content: "";
}

.ti-heart-handshake:before {
  content: "";
}

.ti-heart-minus:before {
  content: "";
}

.ti-heart-off:before {
  content: "";
}

.ti-heart-plus:before {
  content: "";
}

.ti-heart-rate-monitor:before {
  content: "";
}

.ti-heartbeat:before {
  content: "";
}

.ti-helicopter:before {
  content: "";
}

.ti-helicopter-landing:before {
  content: "";
}

.ti-helmet:before {
  content: "";
}

.ti-helmet-off:before {
  content: "";
}

.ti-help:before {
  content: "";
}

.ti-hexagon:before {
  content: "";
}

.ti-hexagon-off:before {
  content: "";
}

.ti-hexagons:before {
  content: "";
}

.ti-hierarchy:before {
  content: "";
}

.ti-hierarchy-2:before {
  content: "";
}

.ti-highlight:before {
  content: "";
}

.ti-highlight-off:before {
  content: "";
}

.ti-history:before {
  content: "";
}

.ti-history-toggle:before {
  content: "";
}

.ti-home:before {
  content: "";
}

.ti-home-2:before {
  content: "";
}

.ti-home-off:before {
  content: "";
}

.ti-hotel-service:before {
  content: "";
}

.ti-hourglass:before {
  content: "";
}

.ti-hourglass-empty:before {
  content: "";
}

.ti-hourglass-high:before {
  content: "";
}

.ti-hourglass-low:before {
  content: "";
}

.ti-hourglass-off:before {
  content: "";
}

.ti-ice-cream:before {
  content: "";
}

.ti-ice-cream-2:before {
  content: "";
}

.ti-ice-cream-off:before {
  content: "";
}

.ti-ice-skating:before {
  content: "";
}

.ti-icons:before {
  content: "";
}

.ti-id:before {
  content: "";
}

.ti-id-badge:before {
  content: "";
}

.ti-id-badge-2:before {
  content: "";
}

.ti-id-off:before {
  content: "";
}

.ti-inbox:before {
  content: "";
}

.ti-inbox-off:before {
  content: "";
}

.ti-indent-decrease:before {
  content: "";
}

.ti-indent-increase:before {
  content: "";
}

.ti-infinity:before {
  content: "";
}

.ti-info-circle:before {
  content: "";
}

.ti-info-square:before {
  content: "";
}

.ti-italic:before {
  content: "";
}

.ti-jewish-star:before {
  content: "";
}

.ti-jump-rope:before {
  content: "";
}

.ti-karate:before {
  content: "";
}

.ti-kayak:before {
  content: "";
}

.ti-kering:before {
  content: "";
}

.ti-key:before {
  content: "";
}

.ti-key-off:before {
  content: "";
}

.ti-keyboard:before {
  content: "";
}

.ti-keyboard-hide:before {
  content: "";
}

.ti-keyboard-off:before {
  content: "";
}

.ti-keyboard-show:before {
  content: "";
}

.ti-ladder:before {
  content: "";
}

.ti-ladder-off:before {
  content: "";
}

.ti-lamp:before {
  content: "";
}

.ti-lamp-2:before {
  content: "";
}

.ti-lamp-off:before {
  content: "";
}

.ti-language:before {
  content: "";
}

.ti-language-hiragana:before {
  content: "";
}

.ti-language-katakana:before {
  content: "";
}

.ti-language-off:before {
  content: "";
}

.ti-lasso:before {
  content: "";
}

.ti-lasso-off:before {
  content: "";
}

.ti-layers-difference:before {
  content: "";
}

.ti-layers-intersect:before {
  content: "";
}

.ti-layers-intersect-2:before {
  content: "";
}

.ti-layers-linked:before {
  content: "";
}

.ti-layers-off:before {
  content: "";
}

.ti-layers-subtract:before {
  content: "";
}

.ti-layers-union:before {
  content: "";
}

.ti-layout:before {
  content: "";
}

.ti-layout-2:before {
  content: "";
}

.ti-layout-align-bottom:before {
  content: "";
}

.ti-layout-align-center:before {
  content: "";
}

.ti-layout-align-left:before {
  content: "";
}

.ti-layout-align-middle:before {
  content: "";
}

.ti-layout-align-right:before {
  content: "";
}

.ti-layout-align-top:before {
  content: "";
}

.ti-layout-board:before {
  content: "";
}

.ti-layout-board-split:before {
  content: "";
}

.ti-layout-bottombar:before {
  content: "";
}

.ti-layout-cards:before {
  content: "";
}

.ti-layout-columns:before {
  content: "";
}

.ti-layout-dashboard:before {
  content: "";
}

.ti-layout-distribute-horizontal:before {
  content: "";
}

.ti-layout-distribute-vertical:before {
  content: "";
}

.ti-layout-grid:before {
  content: "";
}

.ti-layout-grid-add:before {
  content: "";
}

.ti-layout-kanban:before {
  content: "";
}

.ti-layout-list:before {
  content: "";
}

.ti-layout-navbar:before {
  content: "";
}

.ti-layout-off:before {
  content: "";
}

.ti-layout-rows:before {
  content: "";
}

.ti-layout-sidebar:before {
  content: "";
}

.ti-layout-sidebar-left-collapse:before {
  content: "";
}

.ti-layout-sidebar-left-expand:before {
  content: "";
}

.ti-layout-sidebar-right:before {
  content: "";
}

.ti-layout-sidebar-right-collapse:before {
  content: "";
}

.ti-layout-sidebar-right-expand:before {
  content: "";
}

.ti-leaf:before {
  content: "";
}

.ti-leaf-off:before {
  content: "";
}

.ti-lego:before {
  content: "";
}

.ti-lemon:before {
  content: "";
}

.ti-lemon-2:before {
  content: "";
}

.ti-letter-a:before {
  content: "";
}

.ti-letter-b:before {
  content: "";
}

.ti-letter-c:before {
  content: "";
}

.ti-letter-case:before {
  content: "";
}

.ti-letter-case-lower:before {
  content: "";
}

.ti-letter-case-toggle:before {
  content: "";
}

.ti-letter-case-upper:before {
  content: "";
}

.ti-letter-d:before {
  content: "";
}

.ti-letter-e:before {
  content: "";
}

.ti-letter-f:before {
  content: "";
}

.ti-letter-g:before {
  content: "";
}

.ti-letter-h:before {
  content: "";
}

.ti-letter-i:before {
  content: "";
}

.ti-letter-j:before {
  content: "";
}

.ti-letter-k:before {
  content: "";
}

.ti-letter-l:before {
  content: "";
}

.ti-letter-m:before {
  content: "";
}

.ti-letter-n:before {
  content: "";
}

.ti-letter-o:before {
  content: "";
}

.ti-letter-p:before {
  content: "";
}

.ti-letter-q:before {
  content: "";
}

.ti-letter-r:before {
  content: "";
}

.ti-letter-s:before {
  content: "";
}

.ti-letter-spacing:before {
  content: "";
}

.ti-letter-t:before {
  content: "";
}

.ti-letter-u:before {
  content: "";
}

.ti-letter-v:before {
  content: "";
}

.ti-letter-w:before {
  content: "";
}

.ti-letter-x:before {
  content: "";
}

.ti-letter-y:before {
  content: "";
}

.ti-letter-z:before {
  content: "";
}

.ti-license:before {
  content: "";
}

.ti-license-off:before {
  content: "";
}

.ti-lifebuoy:before {
  content: "";
}

.ti-lifebuoy-off:before {
  content: "";
}

.ti-line:before {
  content: "";
}

.ti-line-dashed:before {
  content: "";
}

.ti-line-dotted:before {
  content: "";
}

.ti-line-height:before {
  content: "";
}

.ti-link:before {
  content: "";
}

.ti-list:before {
  content: "";
}

.ti-list-check:before {
  content: "";
}

.ti-list-details:before {
  content: "";
}

.ti-list-numbers:before {
  content: "";
}

.ti-list-search:before {
  content: "";
}

.ti-live-photo:before {
  content: "";
}

.ti-live-view:before {
  content: "";
}

.ti-loader:before {
  content: "";
}

.ti-loader-2:before {
  content: "";
}

.ti-loader-quarter:before {
  content: "";
}

.ti-location:before {
  content: "";
}

.ti-location-off:before {
  content: "";
}

.ti-lock:before {
  content: "";
}

.ti-lock-access:before {
  content: "";
}

.ti-lock-off:before {
  content: "";
}

.ti-lock-open:before {
  content: "";
}

.ti-lock-open-off:before {
  content: "";
}

.ti-lock-square:before {
  content: "";
}

.ti-login:before {
  content: "";
}

.ti-logout:before {
  content: "";
}

.ti-lollipop:before {
  content: "";
}

.ti-lollipop-off:before {
  content: "";
}

.ti-luggage:before {
  content: "";
}

.ti-luggage-off:before {
  content: "";
}

.ti-lungs:before {
  content: "";
}

.ti-macro:before {
  content: "";
}

.ti-magnet:before {
  content: "";
}

.ti-magnet-off:before {
  content: "";
}

.ti-mail:before {
  content: "";
}

.ti-mail-fast:before {
  content: "";
}

.ti-mail-forward:before {
  content: "";
}

.ti-mail-off:before {
  content: "";
}

.ti-mail-opened:before {
  content: "";
}

.ti-mailbox:before {
  content: "";
}

.ti-mailbox-off:before {
  content: "";
}

.ti-man:before {
  content: "";
}

.ti-manual-gearbox:before {
  content: "";
}

.ti-map:before {
  content: "";
}

.ti-map-2:before {
  content: "";
}

.ti-map-off:before {
  content: "";
}

.ti-map-pin:before {
  content: "";
}

.ti-map-pin-off:before {
  content: "";
}

.ti-map-pins:before {
  content: "";
}

.ti-map-search:before {
  content: "";
}

.ti-markdown:before {
  content: "";
}

.ti-marquee:before {
  content: "";
}

.ti-marquee-2:before {
  content: "";
}

.ti-marquee-off:before {
  content: "";
}

.ti-mars:before {
  content: "";
}

.ti-mask:before {
  content: "";
}

.ti-mask-off:before {
  content: "";
}

.ti-massage:before {
  content: "";
}

.ti-math:before {
  content: "";
}

.ti-math-avg:before {
  content: "";
}

.ti-math-function:before {
  content: "";
}

.ti-math-function-off:before {
  content: "";
}

.ti-math-max:before {
  content: "";
}

.ti-math-min:before {
  content: "";
}

.ti-math-symbols:before {
  content: "";
}

.ti-maximize:before {
  content: "";
}

.ti-maximize-off:before {
  content: "";
}

.ti-meat:before {
  content: "";
}

.ti-medal:before {
  content: "";
}

.ti-medal-2:before {
  content: "";
}

.ti-medical-cross:before {
  content: "";
}

.ti-medical-cross-off:before {
  content: "";
}

.ti-medicine-syrup:before {
  content: "";
}

.ti-menu:before {
  content: "";
}

.ti-menu-2:before {
  content: "";
}

.ti-message:before {
  content: "";
}

.ti-message-2:before {
  content: "";
}

.ti-message-2-code:before {
  content: "";
}

.ti-message-2-share:before {
  content: "";
}

.ti-message-circle:before {
  content: "";
}

.ti-message-circle-2:before {
  content: "";
}

.ti-message-circle-off:before {
  content: "";
}

.ti-message-code:before {
  content: "";
}

.ti-message-dots:before {
  content: "";
}

.ti-message-language:before {
  content: "";
}

.ti-message-off:before {
  content: "";
}

.ti-message-plus:before {
  content: "";
}

.ti-message-report:before {
  content: "";
}

.ti-message-share:before {
  content: "";
}

.ti-messages:before {
  content: "";
}

.ti-messages-off:before {
  content: "";
}

.ti-meteor:before {
  content: "";
}

.ti-microphone:before {
  content: "";
}

.ti-microphone-2:before {
  content: "";
}

.ti-microphone-off:before {
  content: "";
}

.ti-microscope:before {
  content: "";
}

.ti-military-award:before {
  content: "";
}

.ti-military-rank:before {
  content: "";
}

.ti-milk:before {
  content: "";
}

.ti-minimize:before {
  content: "";
}

.ti-minus:before {
  content: "";
}

.ti-minus-vertical:before {
  content: "";
}

.ti-mist:before {
  content: "";
}

.ti-mood-boy:before {
  content: "";
}

.ti-mood-confuzed:before {
  content: "";
}

.ti-mood-crazy-happy:before {
  content: "";
}

.ti-mood-cry:before {
  content: "";
}

.ti-mood-empty:before {
  content: "";
}

.ti-mood-happy:before {
  content: "";
}

.ti-mood-kid:before {
  content: "";
}

.ti-mood-nervous:before {
  content: "";
}

.ti-mood-neutral:before {
  content: "";
}

.ti-mood-off:before {
  content: "";
}

.ti-mood-sad:before {
  content: "";
}

.ti-mood-smile:before {
  content: "";
}

.ti-mood-suprised:before {
  content: "";
}

.ti-mood-tongue:before {
  content: "";
}

.ti-moon:before {
  content: "";
}

.ti-moon-2:before {
  content: "";
}

.ti-moon-off:before {
  content: "";
}

.ti-moon-stars:before {
  content: "";
}

.ti-moped:before {
  content: "";
}

.ti-motorbike:before {
  content: "";
}

.ti-mountain:before {
  content: "";
}

.ti-mouse:before {
  content: "";
}

.ti-mouse-2:before {
  content: "";
}

.ti-mouse-off:before {
  content: "";
}

.ti-movie:before {
  content: "";
}

.ti-movie-off:before {
  content: "";
}

.ti-mug:before {
  content: "";
}

.ti-mug-off:before {
  content: "";
}

.ti-multiplier-0-5x:before {
  content: "";
}

.ti-multiplier-1-5x:before {
  content: "";
}

.ti-multiplier-1x:before {
  content: "";
}

.ti-multiplier-2x:before {
  content: "";
}

.ti-mushroom:before {
  content: "";
}

.ti-music:before {
  content: "";
}

.ti-music-off:before {
  content: "";
}

.ti-network:before {
  content: "";
}

.ti-new-section:before {
  content: "";
}

.ti-news:before {
  content: "";
}

.ti-news-off:before {
  content: "";
}

.ti-nfc:before {
  content: "";
}

.ti-nfc-off:before {
  content: "";
}

.ti-no-copyright:before {
  content: "";
}

.ti-no-creative-commons:before {
  content: "";
}

.ti-no-derivatives:before {
  content: "";
}

.ti-north-star:before {
  content: "";
}

.ti-note:before {
  content: "";
}

.ti-note-off:before {
  content: "";
}

.ti-notebook:before {
  content: "";
}

.ti-notes:before {
  content: "";
}

.ti-notes-off:before {
  content: "";
}

.ti-notification:before {
  content: "";
}

.ti-notification-off:before {
  content: "";
}

.ti-number:before {
  content: "";
}

.ti-number-0:before {
  content: "";
}

.ti-number-1:before {
  content: "";
}

.ti-number-2:before {
  content: "";
}

.ti-number-3:before {
  content: "";
}

.ti-number-4:before {
  content: "";
}

.ti-number-5:before {
  content: "";
}

.ti-number-6:before {
  content: "";
}

.ti-number-7:before {
  content: "";
}

.ti-number-8:before {
  content: "";
}

.ti-number-9:before {
  content: "";
}

.ti-numbers:before {
  content: "";
}

.ti-nurse:before {
  content: "";
}

.ti-octagon:before {
  content: "";
}

.ti-octagon-off:before {
  content: "";
}

.ti-old:before {
  content: "";
}

.ti-olympics:before {
  content: "";
}

.ti-omega:before {
  content: "";
}

.ti-outlet:before {
  content: "";
}

.ti-oval:before {
  content: "";
}

.ti-oval-vertical:before {
  content: "";
}

.ti-overline:before {
  content: "";
}

.ti-package:before {
  content: "";
}

.ti-package-off:before {
  content: "";
}

.ti-packge-export:before {
  content: "";
}

.ti-packge-import:before {
  content: "";
}

.ti-pacman:before {
  content: "";
}

.ti-page-break:before {
  content: "";
}

.ti-paint:before {
  content: "";
}

.ti-paint-off:before {
  content: "";
}

.ti-palette:before {
  content: "";
}

.ti-palette-off:before {
  content: "";
}

.ti-panorama-horizontal:before {
  content: "";
}

.ti-panorama-vertical:before {
  content: "";
}

.ti-paper-bag:before {
  content: "";
}

.ti-paper-bag-off:before {
  content: "";
}

.ti-paperclip:before {
  content: "";
}

.ti-parachute:before {
  content: "";
}

.ti-parachute-off:before {
  content: "";
}

.ti-parentheses:before {
  content: "";
}

.ti-parentheses-off:before {
  content: "";
}

.ti-parking:before {
  content: "";
}

.ti-parking-off:before {
  content: "";
}

.ti-paw:before {
  content: "";
}

.ti-peace:before {
  content: "";
}

.ti-pencil:before {
  content: "";
}

.ti-pencil-minus:before {
  content: "";
}

.ti-pencil-off:before {
  content: "";
}

.ti-pencil-plus:before {
  content: "";
}

.ti-pennant:before {
  content: "";
}

.ti-pennant-2:before {
  content: "";
}

.ti-pennant-off:before {
  content: "";
}

.ti-pentagon:before {
  content: "";
}

.ti-pepper:before {
  content: "";
}

.ti-pepper-off:before {
  content: "";
}

.ti-percentage:before {
  content: "";
}

.ti-perspective:before {
  content: "";
}

.ti-perspective-off:before {
  content: "";
}

.ti-phone:before {
  content: "";
}

.ti-phone-call:before {
  content: "";
}

.ti-phone-calling:before {
  content: "";
}

.ti-phone-check:before {
  content: "";
}

.ti-phone-incoming:before {
  content: "";
}

.ti-phone-off:before {
  content: "";
}

.ti-phone-outgoing:before {
  content: "";
}

.ti-phone-pause:before {
  content: "";
}

.ti-phone-plus:before {
  content: "";
}

.ti-phone-x:before {
  content: "";
}

.ti-photo:before {
  content: "";
}

.ti-photo-off:before {
  content: "";
}

.ti-physotherapist:before {
  content: "";
}

.ti-picture-in-picture:before {
  content: "";
}

.ti-picture-in-picture-off:before {
  content: "";
}

.ti-picture-in-picture-on:before {
  content: "";
}

.ti-picture-in-picture-top:before {
  content: "";
}

.ti-pig:before {
  content: "";
}

.ti-pig-off:before {
  content: "";
}

.ti-pill:before {
  content: "";
}

.ti-pill-off:before {
  content: "";
}

.ti-pills:before {
  content: "";
}

.ti-pin:before {
  content: "";
}

.ti-pinned:before {
  content: "";
}

.ti-pinned-off:before {
  content: "";
}

.ti-pizza:before {
  content: "";
}

.ti-pizza-off:before {
  content: "";
}

.ti-plane:before {
  content: "";
}

.ti-plane-arrival:before {
  content: "";
}

.ti-plane-departure:before {
  content: "";
}

.ti-plane-inflight:before {
  content: "";
}

.ti-plane-off:before {
  content: "";
}

.ti-plane-tilt:before {
  content: "";
}

.ti-planet:before {
  content: "";
}

.ti-planet-off:before {
  content: "";
}

.ti-plant:before {
  content: "";
}

.ti-plant-2:before {
  content: "";
}

.ti-plant-2-off:before {
  content: "";
}

.ti-plant-off:before {
  content: "";
}

.ti-play-card:before {
  content: "";
}

.ti-play-card-off:before {
  content: "";
}

.ti-player-eject:before {
  content: "";
}

.ti-player-pause:before {
  content: "";
}

.ti-player-play:before {
  content: "";
}

.ti-player-record:before {
  content: "";
}

.ti-player-skip-back:before {
  content: "";
}

.ti-player-skip-forward:before {
  content: "";
}

.ti-player-stop:before {
  content: "";
}

.ti-player-track-next:before {
  content: "";
}

.ti-player-track-prev:before {
  content: "";
}

.ti-playlist:before {
  content: "";
}

.ti-playlist-add:before {
  content: "";
}

.ti-playlist-off:before {
  content: "";
}

.ti-playlist-x:before {
  content: "";
}

.ti-plug:before {
  content: "";
}

.ti-plug-connected:before {
  content: "";
}

.ti-plug-connected-x:before {
  content: "";
}

.ti-plug-off:before {
  content: "";
}

.ti-plug-x:before {
  content: "";
}

.ti-plus:before {
  content: "";
}

.ti-podium:before {
  content: "";
}

.ti-point:before {
  content: "";
}

.ti-point-off:before {
  content: "";
}

.ti-pokeball:before {
  content: "";
}

.ti-polaroid:before {
  content: "";
}

.ti-polygon:before {
  content: "";
}

.ti-polygon-off:before {
  content: "";
}

.ti-pool:before {
  content: "";
}

.ti-power:before {
  content: "";
}

.ti-pray:before {
  content: "";
}

.ti-premium-rights:before {
  content: "";
}

.ti-prescription:before {
  content: "";
}

.ti-presentation:before {
  content: "";
}

.ti-presentation-analytics:before {
  content: "";
}

.ti-presentation-off:before {
  content: "";
}

.ti-printer:before {
  content: "";
}

.ti-printer-off:before {
  content: "";
}

.ti-prison:before {
  content: "";
}

.ti-prompt:before {
  content: "";
}

.ti-propeller:before {
  content: "";
}

.ti-propeller-off:before {
  content: "";
}

.ti-puzzle:before {
  content: "";
}

.ti-puzzle-2:before {
  content: "";
}

.ti-puzzle-off:before {
  content: "";
}

.ti-pyramid:before {
  content: "";
}

.ti-pyramid-off:before {
  content: "";
}

.ti-qrcode:before {
  content: "";
}

.ti-question-mark:before {
  content: "";
}

.ti-quote:before {
  content: "";
}

.ti-quote-off:before {
  content: "";
}

.ti-radar:before {
  content: "";
}

.ti-radar-2:before {
  content: "";
}

.ti-radio:before {
  content: "";
}

.ti-radioactive:before {
  content: "";
}

.ti-radioactive-off:before {
  content: "";
}

.ti-radius-bottom-left:before {
  content: "";
}

.ti-radius-bottom-right:before {
  content: "";
}

.ti-radius-top-left:before {
  content: "";
}

.ti-radius-top-right:before {
  content: "";
}

.ti-rainbow:before {
  content: "";
}

.ti-rainbow-off:before {
  content: "";
}

.ti-receipt:before {
  content: "";
}

.ti-receipt-2:before {
  content: "";
}

.ti-receipt-off:before {
  content: "";
}

.ti-receipt-refund:before {
  content: "";
}

.ti-receipt-tax:before {
  content: "";
}

.ti-recharging:before {
  content: "";
}

.ti-record-mail:before {
  content: "";
}

.ti-record-mail-off:before {
  content: "";
}

.ti-rectangle:before {
  content: "";
}

.ti-rectangle-vertical:before {
  content: "";
}

.ti-recycle:before {
  content: "";
}

.ti-recycle-off:before {
  content: "";
}

.ti-refresh:before {
  content: "";
}

.ti-refresh-alert:before {
  content: "";
}

.ti-refresh-dot:before {
  content: "";
}

.ti-refresh-off:before {
  content: "";
}

.ti-registered:before {
  content: "";
}

.ti-relation-many-to-many:before {
  content: "";
}

.ti-relation-one-to-many:before {
  content: "";
}

.ti-relation-one-to-one:before {
  content: "";
}

.ti-repeat:before {
  content: "";
}

.ti-repeat-off:before {
  content: "";
}

.ti-repeat-once:before {
  content: "";
}

.ti-replace:before {
  content: "";
}

.ti-report:before {
  content: "";
}

.ti-report-analytics:before {
  content: "";
}

.ti-report-medical:before {
  content: "";
}

.ti-report-money:before {
  content: "";
}

.ti-report-off:before {
  content: "";
}

.ti-report-search:before {
  content: "";
}

.ti-resize:before {
  content: "";
}

.ti-ripple:before {
  content: "";
}

.ti-ripple-off:before {
  content: "";
}

.ti-road:before {
  content: "";
}

.ti-road-off:before {
  content: "";
}

.ti-road-sign:before {
  content: "";
}

.ti-robot:before {
  content: "";
}

.ti-robot-off:before {
  content: "";
}

.ti-rocket:before {
  content: "";
}

.ti-rocket-off:before {
  content: "";
}

.ti-roller-skating:before {
  content: "";
}

.ti-rollercoaster:before {
  content: "";
}

.ti-rotate:before {
  content: "";
}

.ti-rotate-2:before {
  content: "";
}

.ti-rotate-360:before {
  content: "";
}

.ti-rotate-clockwise:before {
  content: "";
}

.ti-rotate-clockwise-2:before {
  content: "";
}

.ti-rotate-dot:before {
  content: "";
}

.ti-rotate-rectangle:before {
  content: "";
}

.ti-route:before {
  content: "";
}

.ti-route-off:before {
  content: "";
}

.ti-router:before {
  content: "";
}

.ti-row-insert-bottom:before {
  content: "";
}

.ti-row-insert-top:before {
  content: "";
}

.ti-rss:before {
  content: "";
}

.ti-ruler:before {
  content: "";
}

.ti-ruler-2:before {
  content: "";
}

.ti-ruler-2-off:before {
  content: "";
}

.ti-ruler-off:before {
  content: "";
}

.ti-run:before {
  content: "";
}

.ti-sailboat:before {
  content: "";
}

.ti-salt:before {
  content: "";
}

.ti-satellite:before {
  content: "";
}

.ti-satellite-off:before {
  content: "";
}

.ti-sausage:before {
  content: "";
}

.ti-scale:before {
  content: "";
}

.ti-scale-off:before {
  content: "";
}

.ti-scale-outline:before {
  content: "";
}

.ti-scale-outline-off:before {
  content: "";
}

.ti-scan:before {
  content: "";
}

.ti-scan-eye:before {
  content: "";
}

.ti-schema:before {
  content: "";
}

.ti-school:before {
  content: "";
}

.ti-school-off:before {
  content: "";
}

.ti-scissors:before {
  content: "";
}

.ti-scissors-off:before {
  content: "";
}

.ti-scooter:before {
  content: "";
}

.ti-scooter-electric:before {
  content: "";
}

.ti-screen-share:before {
  content: "";
}

.ti-screen-share-off:before {
  content: "";
}

.ti-screenshot:before {
  content: "";
}

.ti-scribble:before {
  content: "";
}

.ti-scuba-mask:before {
  content: "";
}

.ti-search:before {
  content: "";
}

.ti-search-off:before {
  content: "";
}

.ti-section:before {
  content: "";
}

.ti-section-sign:before {
  content: "";
}

.ti-seeding:before {
  content: "";
}

.ti-seeding-off:before {
  content: "";
}

.ti-select:before {
  content: "";
}

.ti-selector:before {
  content: "";
}

.ti-send:before {
  content: "";
}

.ti-separator:before {
  content: "";
}

.ti-separator-horizontal:before {
  content: "";
}

.ti-separator-vertical:before {
  content: "";
}

.ti-server:before {
  content: "";
}

.ti-server-2:before {
  content: "";
}

.ti-server-off:before {
  content: "";
}

.ti-servicemark:before {
  content: "";
}

.ti-settings:before {
  content: "";
}

.ti-settings-automation:before {
  content: "";
}

.ti-settings-off:before {
  content: "";
}

.ti-shadow:before {
  content: "";
}

.ti-shadow-off:before {
  content: "";
}

.ti-shape:before {
  content: "";
}

.ti-shape-2:before {
  content: "";
}

.ti-shape-3:before {
  content: "";
}

.ti-shape-off:before {
  content: "";
}

.ti-share:before {
  content: "";
}

.ti-share-off:before {
  content: "";
}

.ti-shield:before {
  content: "";
}

.ti-shield-check:before {
  content: "";
}

.ti-shield-checkered:before {
  content: "";
}

.ti-shield-chevron:before {
  content: "";
}

.ti-shield-lock:before {
  content: "";
}

.ti-shield-off:before {
  content: "";
}

.ti-shield-x:before {
  content: "";
}

.ti-ship:before {
  content: "";
}

.ti-shirt:before {
  content: "";
}

.ti-shirt-off:before {
  content: "";
}

.ti-shoe:before {
  content: "";
}

.ti-shoe-off:before {
  content: "";
}

.ti-shopping-cart:before {
  content: "";
}

.ti-shopping-cart-discount:before {
  content: "";
}

.ti-shopping-cart-off:before {
  content: "";
}

.ti-shopping-cart-plus:before {
  content: "";
}

.ti-shopping-cart-x:before {
  content: "";
}

.ti-shovel:before {
  content: "";
}

.ti-shredder:before {
  content: "";
}

.ti-sign-left:before {
  content: "";
}

.ti-sign-right:before {
  content: "";
}

.ti-signal-3g:before {
  content: "";
}

.ti-signal-4g:before {
  content: "";
}

.ti-signal-5g:before {
  content: "";
}

.ti-signature:before {
  content: "";
}

.ti-signature-off:before {
  content: "";
}

.ti-sitemap:before {
  content: "";
}

.ti-sitemap-off:before {
  content: "";
}

.ti-skateboard:before {
  content: "";
}

.ti-sleigh:before {
  content: "";
}

.ti-slice:before {
  content: "";
}

.ti-slideshow:before {
  content: "";
}

.ti-smart-home:before {
  content: "";
}

.ti-smart-home-off:before {
  content: "";
}

.ti-smoking:before {
  content: "";
}

.ti-smoking-no:before {
  content: "";
}

.ti-snowflake:before {
  content: "";
}

.ti-snowflake-off:before {
  content: "";
}

.ti-soccer-field:before {
  content: "";
}

.ti-social:before {
  content: "";
}

.ti-social-off:before {
  content: "";
}

.ti-sock:before {
  content: "";
}

.ti-sofa:before {
  content: "";
}

.ti-sort-ascending:before {
  content: "";
}

.ti-sort-ascending-2:before {
  content: "";
}

.ti-sort-ascending-letters:before {
  content: "";
}

.ti-sort-ascending-numbers:before {
  content: "";
}

.ti-sort-descending:before {
  content: "";
}

.ti-sort-descending-2:before {
  content: "";
}

.ti-sort-descending-letters:before {
  content: "";
}

.ti-sort-descending-numbers:before {
  content: "";
}

.ti-soup:before {
  content: "";
}

.ti-space:before {
  content: "";
}

.ti-space-off:before {
  content: "";
}

.ti-spacing-horizontal:before {
  content: "";
}

.ti-spacing-vertical:before {
  content: "";
}

.ti-spade:before {
  content: "";
}

.ti-speakerphone:before {
  content: "";
}

.ti-speedboat:before {
  content: "";
}

.ti-sport-billard:before {
  content: "";
}

.ti-spy:before {
  content: "";
}

.ti-square:before {
  content: "";
}

.ti-square-0:before {
  content: "";
}

.ti-square-1:before {
  content: "";
}

.ti-square-2:before {
  content: "";
}

.ti-square-3:before {
  content: "";
}

.ti-square-4:before {
  content: "";
}

.ti-square-5:before {
  content: "";
}

.ti-square-6:before {
  content: "";
}

.ti-square-7:before {
  content: "";
}

.ti-square-8:before {
  content: "";
}

.ti-square-9:before {
  content: "";
}

.ti-square-asterisk:before {
  content: "";
}

.ti-square-check:before {
  content: "";
}

.ti-square-dot:before {
  content: "";
}

.ti-square-forbid:before {
  content: "";
}

.ti-square-forbid-2:before {
  content: "";
}

.ti-square-half:before {
  content: "";
}

.ti-square-minus:before {
  content: "";
}

.ti-square-off:before {
  content: "";
}

.ti-square-plus:before {
  content: "";
}

.ti-square-root:before {
  content: "";
}

.ti-square-root-2:before {
  content: "";
}

.ti-square-rotated:before {
  content: "";
}

.ti-square-rotated-forbid:before {
  content: "";
}

.ti-square-rotated-forbid-2:before {
  content: "";
}

.ti-square-rotated-off:before {
  content: "";
}

.ti-square-toggle:before {
  content: "";
}

.ti-square-toggle-horizontal:before {
  content: "";
}

.ti-square-x:before {
  content: "";
}

.ti-squares-diagonal:before {
  content: "";
}

.ti-squares-filled:before {
  content: "";
}

.ti-stack:before {
  content: "";
}

.ti-stack-2:before {
  content: "";
}

.ti-stack-3:before {
  content: "";
}

.ti-stairs:before {
  content: "";
}

.ti-stairs-down:before {
  content: "";
}

.ti-stairs-up:before {
  content: "";
}

.ti-star:before {
  content: "";
}

.ti-star-half:before {
  content: "";
}

.ti-star-off:before {
  content: "";
}

.ti-stars:before {
  content: "";
}

.ti-steering-wheel:before {
  content: "";
}

.ti-step-into:before {
  content: "";
}

.ti-step-out:before {
  content: "";
}

.ti-stethoscope:before {
  content: "";
}

.ti-sticker:before {
  content: "";
}

.ti-strikethrough:before {
  content: "";
}

.ti-submarine:before {
  content: "";
}

.ti-subscript:before {
  content: "";
}

.ti-subtask:before {
  content: "";
}

.ti-sum:before {
  content: "";
}

.ti-sum-off:before {
  content: "";
}

.ti-sun:before {
  content: "";
}

.ti-sun-off:before {
  content: "";
}

.ti-sunrise:before {
  content: "";
}

.ti-sunset:before {
  content: "";
}

.ti-superscript:before {
  content: "";
}

.ti-swimming:before {
  content: "";
}

.ti-switch:before {
  content: "";
}

.ti-switch-2:before {
  content: "";
}

.ti-switch-3:before {
  content: "";
}

.ti-switch-horizontal:before {
  content: "";
}

.ti-switch-vertical:before {
  content: "";
}

.ti-sword:before {
  content: "";
}

.ti-sword-off:before {
  content: "";
}

.ti-swords:before {
  content: "";
}

.ti-table:before {
  content: "";
}

.ti-table-export:before {
  content: "";
}

.ti-table-import:before {
  content: "";
}

.ti-table-off:before {
  content: "";
}

.ti-tag:before {
  content: "";
}

.ti-tag-off:before {
  content: "";
}

.ti-tags:before {
  content: "";
}

.ti-tags-off:before {
  content: "";
}

.ti-tallymark-1:before {
  content: "";
}

.ti-tallymark-2:before {
  content: "";
}

.ti-tallymark-3:before {
  content: "";
}

.ti-tallymark-4:before {
  content: "";
}

.ti-tallymarks:before {
  content: "";
}

.ti-tank:before {
  content: "";
}

.ti-target:before {
  content: "";
}

.ti-target-off:before {
  content: "";
}

.ti-telescope:before {
  content: "";
}

.ti-telescope-off:before {
  content: "";
}

.ti-temperature:before {
  content: "";
}

.ti-temperature-celsius:before {
  content: "";
}

.ti-temperature-fahrenheit:before {
  content: "";
}

.ti-temperature-minus:before {
  content: "";
}

.ti-temperature-off:before {
  content: "";
}

.ti-temperature-plus:before {
  content: "";
}

.ti-template:before {
  content: "";
}

.ti-template-off:before {
  content: "";
}

.ti-tent:before {
  content: "";
}

.ti-terminal:before {
  content: "";
}

.ti-terminal-2:before {
  content: "";
}

.ti-test-pipe:before {
  content: "";
}

.ti-test-pipe-2:before {
  content: "";
}

.ti-test-pipe-off:before {
  content: "";
}

.ti-text-decrease:before {
  content: "";
}

.ti-text-direction-ltr:before {
  content: "";
}

.ti-text-direction-rtl:before {
  content: "";
}

.ti-text-increase:before {
  content: "";
}

.ti-text-recognition:before {
  content: "";
}

.ti-text-resize:before {
  content: "";
}

.ti-text-wrap:before {
  content: "";
}

.ti-text-wrap-disabled:before {
  content: "";
}

.ti-thermometer:before {
  content: "";
}

.ti-thumb-down:before {
  content: "";
}

.ti-thumb-up:before {
  content: "";
}

.ti-ticket:before {
  content: "";
}

.ti-ticket-off:before {
  content: "";
}

.ti-tie:before {
  content: "";
}

.ti-tilt-shift:before {
  content: "";
}

.ti-tilt-shift-off:before {
  content: "";
}

.ti-timeline:before {
  content: "";
}

.ti-tir:before {
  content: "";
}

.ti-toggle-left:before {
  content: "";
}

.ti-toggle-right:before {
  content: "";
}

.ti-toilet-paper:before {
  content: "";
}

.ti-toilet-paper-off:before {
  content: "";
}

.ti-tool:before {
  content: "";
}

.ti-tools:before {
  content: "";
}

.ti-tools-kitchen:before {
  content: "";
}

.ti-tools-kitchen-2:before {
  content: "";
}

.ti-tools-kitchen-2-off:before {
  content: "";
}

.ti-tools-kitchen-off:before {
  content: "";
}

.ti-tools-off:before {
  content: "";
}

.ti-tornado:before {
  content: "";
}

.ti-tournament:before {
  content: "";
}

.ti-track:before {
  content: "";
}

.ti-tractor:before {
  content: "";
}

.ti-trademark:before {
  content: "";
}

.ti-traffic-cone:before {
  content: "";
}

.ti-traffic-cone-off:before {
  content: "";
}

.ti-traffic-lights:before {
  content: "";
}

.ti-traffic-lights-off:before {
  content: "";
}

.ti-train:before {
  content: "";
}

.ti-transfer-in:before {
  content: "";
}

.ti-transfer-out:before {
  content: "";
}

.ti-trash:before {
  content: "";
}

.ti-trash-off:before {
  content: "";
}

.ti-trash-x:before {
  content: "";
}

.ti-tree:before {
  content: "";
}

.ti-trees:before {
  content: "";
}

.ti-trending-down:before {
  content: "";
}

.ti-trending-down-2:before {
  content: "";
}

.ti-trending-down-3:before {
  content: "";
}

.ti-trending-up:before {
  content: "";
}

.ti-trending-up-2:before {
  content: "";
}

.ti-trending-up-3:before {
  content: "";
}

.ti-triangle:before {
  content: "";
}

.ti-triangle-inverted:before {
  content: "";
}

.ti-triangle-off:before {
  content: "";
}

.ti-triangle-square-circle:before {
  content: "";
}

.ti-triangles:before {
  content: "";
}

.ti-trident:before {
  content: "";
}

.ti-trophy:before {
  content: "";
}

.ti-truck:before {
  content: "";
}

.ti-truck-delivery:before {
  content: "";
}

.ti-truck-loading:before {
  content: "";
}

.ti-truck-off:before {
  content: "";
}

.ti-truck-return:before {
  content: "";
}

.ti-typography:before {
  content: "";
}

.ti-typography-off:before {
  content: "";
}

.ti-umbrella:before {
  content: "";
}

.ti-umbrella-off:before {
  content: "";
}

.ti-underline:before {
  content: "";
}

.ti-unlink:before {
  content: "";
}

.ti-upload:before {
  content: "";
}

.ti-urgent:before {
  content: "";
}

.ti-usb:before {
  content: "";
}

.ti-user:before {
  content: "";
}

.ti-user-check:before {
  content: "";
}

.ti-user-circle:before {
  content: "";
}

.ti-user-exclamation:before {
  content: "";
}

.ti-user-minus:before {
  content: "";
}

.ti-user-off:before {
  content: "";
}

.ti-user-plus:before {
  content: "";
}

.ti-user-search:before {
  content: "";
}

.ti-user-x:before {
  content: "";
}

.ti-users:before {
  content: "";
}

.ti-vaccine:before {
  content: "";
}

.ti-vaccine-bottle:before {
  content: "";
}

.ti-vaccine-off:before {
  content: "";
}

.ti-variable:before {
  content: "";
}

.ti-variable-off:before {
  content: "";
}

.ti-vector:before {
  content: "";
}

.ti-vector-bezier:before {
  content: "";
}

.ti-vector-bezier-2:before {
  content: "";
}

.ti-vector-off:before {
  content: "";
}

.ti-vector-triangle:before {
  content: "";
}

.ti-vector-triangle-off:before {
  content: "";
}

.ti-venus:before {
  content: "";
}

.ti-versions:before {
  content: "";
}

.ti-versions-off:before {
  content: "";
}

.ti-video:before {
  content: "";
}

.ti-video-minus:before {
  content: "";
}

.ti-video-off:before {
  content: "";
}

.ti-video-plus:before {
  content: "";
}

.ti-view-360:before {
  content: "";
}

.ti-view-360-off:before {
  content: "";
}

.ti-viewfinder:before {
  content: "";
}

.ti-viewfinder-off:before {
  content: "";
}

.ti-viewport-narrow:before {
  content: "";
}

.ti-viewport-wide:before {
  content: "";
}

.ti-vinyl:before {
  content: "";
}

.ti-virus:before {
  content: "";
}

.ti-virus-off:before {
  content: "";
}

.ti-virus-search:before {
  content: "";
}

.ti-vocabulary:before {
  content: "";
}

.ti-volume:before {
  content: "";
}

.ti-volume-2:before {
  content: "";
}

.ti-volume-3:before {
  content: "";
}

.ti-volume-off:before {
  content: "";
}

.ti-walk:before {
  content: "";
}

.ti-wall:before {
  content: "";
}

.ti-wallet:before {
  content: "";
}

.ti-wallet-off:before {
  content: "";
}

.ti-wallpaper:before {
  content: "";
}

.ti-wallpaper-off:before {
  content: "";
}

.ti-wand:before {
  content: "";
}

.ti-wand-off:before {
  content: "";
}

.ti-wave-saw-tool:before {
  content: "";
}

.ti-wave-sine:before {
  content: "";
}

.ti-wave-square:before {
  content: "";
}

.ti-webhook:before {
  content: "";
}

.ti-wheelchair:before {
  content: "";
}

.ti-wifi:before {
  content: "";
}

.ti-wifi-0:before {
  content: "";
}

.ti-wifi-1:before {
  content: "";
}

.ti-wifi-2:before {
  content: "";
}

.ti-wifi-off:before {
  content: "";
}

.ti-wind:before {
  content: "";
}

.ti-wind-off:before {
  content: "";
}

.ti-windmill:before {
  content: "";
}

.ti-windmill-off:before {
  content: "";
}

.ti-window:before {
  content: "";
}

.ti-window-maximize:before {
  content: "";
}

.ti-window-minimize:before {
  content: "";
}

.ti-window-off:before {
  content: "";
}

.ti-windsock:before {
  content: "";
}

.ti-wiper:before {
  content: "";
}

.ti-wiper-wash:before {
  content: "";
}

.ti-woman:before {
  content: "";
}

.ti-world:before {
  content: "";
}

.ti-world-download:before {
  content: "";
}

.ti-world-latitude:before {
  content: "";
}

.ti-world-longitude:before {
  content: "";
}

.ti-world-off:before {
  content: "";
}

.ti-world-upload:before {
  content: "";
}

.ti-wrecking-ball:before {
  content: "";
}

.ti-writing:before {
  content: "";
}

.ti-writing-off:before {
  content: "";
}

.ti-writing-sign:before {
  content: "";
}

.ti-writing-sign-off:before {
  content: "";
}

.ti-x:before {
  content: "";
}

.ti-yin-yang:before {
  content: "";
}

.ti-yoga:before {
  content: "";
}

.ti-zodiac-aquarius:before {
  content: "";
}

.ti-zodiac-aries:before {
  content: "";
}

.ti-zodiac-cancer:before {
  content: "";
}

.ti-zodiac-capricorn:before {
  content: "";
}

.ti-zodiac-gemini:before {
  content: "";
}

.ti-zodiac-leo:before {
  content: "";
}

.ti-zodiac-libra:before {
  content: "";
}

.ti-zodiac-pisces:before {
  content: "";
}

.ti-zodiac-sagittarius:before {
  content: "";
}

.ti-zodiac-scorpio:before {
  content: "";
}

.ti-zodiac-taurus:before {
  content: "";
}

.ti-zodiac-virgo:before {
  content: "";
}

.ti-zoom-cancel:before {
  content: "";
}

.ti-zoom-check:before {
  content: "";
}

.ti-zoom-code:before {
  content: "";
}

.ti-zoom-exclamation:before {
  content: "";
}

.ti-zoom-in:before {
  content: "";
}

.ti-zoom-in-area:before {
  content: "";
}

.ti-zoom-money:before {
  content: "";
}

.ti-zoom-out:before {
  content: "";
}

.ti-zoom-out-area:before {
  content: "";
}

.ti-zoom-pan:before {
  content: "";
}

.ti-zoom-question:before {
  content: "";
}

.ti-zzz:before {
  content: "";
}

:root {
  --link-text-color: #006fb3;
  --link-hover-text-color: #005c94;
  --link-visited-text-color: #5b109f;
  --link-visited-hover-text-color: #430e73;
  --branding-color-1: #eeecf8;
  --branding-color-2: #fecf48;
  --branding-color-accent-1: #3b3b3b;
  --branding-color-accent-2: #007ebb;
  --branding-color-accent-3: #4cba51;
  --branding-color-accent-4: #f58900;
  --branding-color-accent-5: #fe7e6d;
  --branding-color-1-background-color: var(--branding-color-1);
  --branding-color-1-text-color: black;
  --branding-color-1-link-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-active-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-hover-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-hover-text-color: var(--branding-color-1-text-color);
  --branding-color-1-darker: #8c7cd2;
  --branding-color-2-background-color: var(--branding-color-2);
  --branding-color-2-text-color: black;
  --branding-color-2-link-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-active-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-hover-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-hover-text-color: var(--branding-color-2-text-color);
  --branding-color-accent-1-background-color: var(--branding-color-accent-1);
  --branding-color-accent-1-text-color: white;
  --branding-color-accent-1-link-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-active-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-hover-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-hover-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-2-background-color: var(--branding-color-accent-2);
  --branding-color-accent-2-text-color: white;
  --branding-color-accent-2-link-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-active-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-hover-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-hover-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-3-background-color: var(--branding-color-accent-3);
  --branding-color-accent-3-text-color: white;
  --branding-color-accent-3-link-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-active-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-hover-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-hover-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-4-background-color: var(--branding-color-accent-4);
  --branding-color-accent-4-text-color: white;
  --branding-color-accent-4-link-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-active-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-hover-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-hover-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-5-background-color: var(--branding-color-accent-5);
  --branding-color-accent-5-text-color: white;
  --branding-color-accent-5-link-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-active-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-hover-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-hover-text-color: var(--branding-color-accent-5-text-color);
  --headings-base-set-font-family: "Fredoka", sans-serif;
  --headings-base-set-font-weight: 600;
  --application-base-font-family: "Open Sans", sans-serif;
  --application-base-line-height: 1.75;
  --application-base-accent-color: var(--branding-color-1);
  --application-base-accent-color-text-color: var(--branding-color-1-text-color);
  --critical-informational-labels-min-width: 8rem;
  --de-emphasized-text-color: #000;
  --h1-font-size: var(--heading-xl-font-size);
  --h2-font-size: var(--heading-large-font-size);
  --icon-base-font-family: "tabler-icons";
  --icon-base-font-size: 1.2rem;
  --nota-bene-text-color: #000;
  --section-gap: 1.5rem;
  --section-title-margin: var(--layout-base-content-block-title-margin);
  --section-subtitle-margin: var(--layout-base-content-block-subtitle-margin);
  --button-base-background-color: var(--branding-color-2-background-color);
  --button-base-text-color: var(--branding-color-2-text-color);
  --button-base-border-radius: 1rem;
  --button-ghost-border-width: 1px;
  --button-base-font-size: 1rem;
  --button-base-font-weight: bold;
  --button-base-line-height: 1;
  --button-base-hover-background-color: #ddb900;
  --button-base-hover-text-color: #000;
  --button-base-active-background-color: #ddb900;
  --button-base-active-text-color: #000;
  --button-base-focus-background-color: #ddb900;
  --button-base-focus-text-color: #000;
  --table-base-background-color: #fff;
  --table-base-text-color: var(--application-base-text-color);
  --table-base-foot-background-color: #f5f5f5;
  --table-base-body-head-cell-background-color: var(--application-base-accent-color);
  --table-base-head-background-color: #fff;
  --table-base-head-border-width: 0 0 2px 0;
  --table-base-head-border-style: solid;
  --table-base-head-border-color: var(--branding-color-1-darker);
  --table-base-head-cell-font-size: 1rem;
  --table-base-cell-border-width: 0 0 1px 0;
  --table-base-cell-border-style: solid;
  --table-base-cell-border-color: #ccc;
  --table-base-cell-padding: .5rem 1rem;
  --table-base-cells-padding: .25rem 1rem;
  --table-action-buttons-button-font-size: var(--icon-base-font-size);
  --footer-min-height: 0;
  --footer-background-color: #fff;
  --footer-text-color: #6a6a6a;
  --footer-padding: 1rem 2%;
  --footer-link-text-color: var(--link-text-color);
  --footer-link-text-decoration: underline;
  --footer-link-hover-text-color: var(--link-hover-text-color);
  --footer-link-hover-text-decoration: underline;
  --footer-link-visited-text-color: var(--link-visited-text-color);
  --footer-link-visited-text-decoration: underline;
  --footer-link-visited-hover-text-color: var(--link-visited-hover-text-color);
  --footer-link-visited-hover-text-decoration: underline;
  --form-accent-color-color: var(--branding-color-2);
  --form-base-input-text-color: #000;
  --form-input-border-width: 1px;
  --form-input-border-style: solid;
  --form-input-border-color: #ccc;
  --form-help-button-icon-content: "";
  --form-help-button-icon-font-family: var(--icon-base-font-family);
  --form-textarea-text-color: var(--form-base-input-text-color);
  --form-textarea-border-color: #ccc;
  --form-fieldset-legend-font-weight: 600;
  --accordion-button-icon-after-open-content: "";
  --accordion-button-icon-after-close-content: "";
  --accordion-content-gap: 1.5rem;
  --accordion-content-text-color: #000;
  --breadcrumb-bar-background-color: var(--branding-color-1-background-color);
  --breadcrumb-bar-text-color: var(--branding-color-1-text-color);
  --breadcrumb-bar-content-block-icon: "";
  --breadcrumb-bar-padding-right: 3%;
  --breadcrumb-bar-padding-left: 3%;
  --breadcrumb-bar-list-vertical-align: center;
  --breadcrumb-bar-icon-font-family: var(--icon-base-font-family);
  --breadcrumb-bar-icon: "";
  --button-ghost-background-color: transparent;
  --button-ghost-border-color: #d4d4d4;
  --button-ghost-text-color: #006fb3;
  --button-ghost-font-size: 1rem;
  --button-ghost-font-weight: bold;
  --button-ghost-line-height: 1;
  --button-ghost-hover-background-color: transparent;
  --button-ghost-hover-border-color: #006fb3;
  --button-ghost-hover-text-color: #006fb3;
  --button-ghost-active-background-color: transparent;
  --button-ghost-active-border-color: var(--branding-color-1-darker);
  --button-ghost-active-text-color: #006fb3;
  --button-ghost-focus-background-color: transparent;
  --button-ghost-focus-border-color: var(--branding-color-1-darker);
  --button-ghost-focus-text-color: #006fb3;
  --button-icon-min-width: 2.75rem;
  --button-icon-min-height: 2.75rem;
  --button-icon-icon-font-size: .8rem;
  --button-icon-icon-width: .75rem;
  --filter-intro-text-color: var(--application-base-text-color);
  --filter-button-icon-before-open-content: "";
  --filter-button-icon-before-close-content: "";
  --layout-block-gap: 3rem;
  --layout-base-max-width: 80rem;
  --layout-base-content-block-gap: 1.5rem;
  --layout-base-main-padding-top: 5rem;
  --layout-footer-content-block-gap: var(--layout-block-gap);
  --layout-footer-block-max-width: var(--layout-base-max-width);
  --layout-footer-content-block-gap: var(--layout-base-content-block-gap);
  --layout-header-gap: 0;
  --layout-header-padding-top: var(--layout-base-padding-top);
  --layout-header-padding-bottom: var(--layout-base-padding-bottom);
  --layout-header-padding-left: var(--layout-base-padding-left);
  --layout-header-block-max-width: var(--layout-base-max-width);
  --layout-main-gap: var(--layout-block-gap);
  --layout-main-first-padding-top: 5rem;
  --login-meta-background-color: #eaeaea;
  --login-meta-color: #000;
  --navigation-collapsible-menu-list-item-collapsed-hover-background-color: #14131a;
  --navigation-gap: 1rem;
  --navigation-align-content: flex-start;
  --navigation-list-gap: var(--navigation-gap);
  --navigation-link-text-decoration: none;
  --navigation-link-hover-text-decoration: none;
  --navigation-background-color: var(--application-base-background-color);
  --navigation-text-color: var(--application-base-text-color);
  --tabs-border-color: #ccc;
  --tabs-item-active-text-color: var(--branding-color-1-darker);
  --tabs-item-active-border-color: var(--branding-color-1-darker);
  --tile-background-color: #fff;
  --critical-informational-labels-min-width: 8rem;
  --de-emphasized-text-color: #000;
  --h1-font-size: var(--heading-xl-font-size);
  --h2-font-size: var(--heading-large-font-size);
  --icon-base-font-family: "tabler-icons";
  --icon-base-font-size: 1.2rem;
  --nota-bene-text-color: #000;
  --section-gap: 1.5rem;
  --section-title-margin: var(--layout-base-content-block-title-margin);
  --section-subtitle-margin: var(--layout-base-content-block-subtitle-margin);
}

.action-buttons {
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.action-buttons form {
  width: auto;
}

.is-indented {
  padding-left: 4em;
  position: relative;
}

.is-indented > aside {
  height: 100%;
  width: 4em;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes ball {
  0% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

@keyframes paw {
  0% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(-40deg);
  }
}

.cat-loader-wrapper {
  justify-content: center;
  margin: 3rem auto;
  display: flex;
}

.cat-loader {
  height: 13rem;
  width: 14rem;
  position: relative;
}

.cat-loader .tail {
  position: absolute;
  top: 8rem;
  left: 2rem;
}

.cat-loader .ball {
  transform-origin: bottom;
  animation: ball 1s ease-in-out infinite alternate;
  position: absolute;
  top: 9.5rem;
  left: 1.5rem;
}

.cat-loader .head {
  position: absolute;
  top: 1rem;
}

.cat-loader .paw {
  transform-origin: top;
  animation: paw 1s ease-in-out infinite alternate;
  position: absolute;
  top: 8rem;
  left: 2.375rem;
}

.cat-loader .line {
  position: absolute;
  top: 11.5rem;
  left: 2rem;
}

.cat-loader .body {
  display: block;
  position: absolute;
  top: 4rem;
}

.cat-loader .ear-left {
  position: absolute;
  left: .5rem;
}

.cat-loader .ear-right {
  position: absolute;
  left: 3rem;
}

@keyframes blink {
  50% {
    opacity: .1;
  }
}

.cat-paw-loader {
  justify-content: flex-start;
  margin: 2.5rem 0;
  display: flex;
}

.cat-paw-loader > img {
  width: 1rem;
  margin-right: .75rem;
  animation: blink 2s infinite;
  display: inline-block;
}

.cat-paw-loader > img:nth-child(2) {
  animation-delay: .25s;
}

.cat-paw-loader > img:nth-child(3) {
  animation-delay: .5s;
}

.cat-paw-loader > img:nth-child(4) {
  animation-delay: .75s;
}

.cat-paw-loader > img:nth-child(5) {
  animation-delay: 1s;
}

.cat-paw-loader > img:nth-child(6) {
  animation-delay: 1.25s;
}

#cy {
  width: 100%;
  height: 800px;
  display: block;
}

dl div {
  display: grid;
}

.dropdown {
  position: relative;
}

.dropdown span {
  pointer-events: none;
}

.dropdown > ul {
  padding: 0;
  z-index: 1;
  width: max-content;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  gap: 0;
  margin-block: 0;
  padding-inline-start: 0;
  list-style-type: disc;
  display: none;
  position: absolute;
  top: 2.875rem;
}

.dropdown > ul[aria-expanded="true"] {
  display: flex;
}

.dropdown > ul li {
  border-top: 1px solid #fafafa;
  padding: 0;
}

.dropdown > ul li > a {
  color: #000;
  align-items: center;
  margin: 0;
  padding: .5rem;
  text-decoration: none;
  display: flex;
}

.dropdown > ul li > a:hover {
  color: #000;
}

.dropdown > ul li::marker {
  content: none;
}

.dropdown > ul li:first-child {
  border-top: none;
}

.dropdown > ul li:last-child {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.dropdown > ul li:hover {
  background-color: #ccc;
}

.affected-objects span:not(:last-child):after {
  content: "-";
  display: inline-block;
}

.kat-logo {
  max-width: 10rem;
  display: flex;
}

.kat-logo img {
  object-fit: cover;
  width: 100%;
  background-color: #fff;
  border: 4px solid #fff;
  overflow: hidden;
}

.kat-logo:hover img {
  animation: rainbow 1s infinite;
}

@keyframes rainbow {
  0% {
    border-color: red;
  }

  10% {
    border-color: #ff8000;
  }

  20% {
    border-color: #ff0;
  }

  30% {
    border-color: #80ff00;
  }

  40% {
    border-color: #0f0;
  }

  50% {
    border-color: #00ff80;
  }

  60% {
    border-color: #0ff;
  }

  70% {
    border-color: #0080ff;
  }

  80% {
    border-color: #00f;
  }

  90% {
    border-color: #8000ff;
  }

  100% {
    border-color: #ff0080;
  }
}

form button, form a.button, form input[type="button"], form input[type="submit"], form input[type="reset"] {
  min-height: 2.75rem;
}

header > section > div {
  gap: 0;
}

body > header nav:not(.breadcrumb-bar) {
  position: relative;
}

body > header nav:not(.breadcrumb-bar) a.logo {
  position: absolute;
}

@media (min-width: 56rem) {
  body > header nav:not(.breadcrumb-bar) a.logo {
    position: static;
  }
}

body > header nav:not(.breadcrumb-bar) a.logo:hover {
  border-bottom: 0;
}

tbody td.has-hover-block {
  position: relative;
}

tbody td.has-hover-block .hover-block {
  opacity: 1;
  width: 480px;
  z-index: 9;
  background: #fff;
  border: 1px solid #ccc;
  padding: .5em;
  display: none;
  position: absolute;
  top: calc(100% - .25em);
  left: 15%;
  box-shadow: 0 8px 8px #0a0a0a1a;
}

tbody td.has-hover-block:hover .hover-block, tbody td.has-hover-block.is-active .hover-block {
  display: block;
}

form.inline.help {
  padding-right: 0;
}

.input-link {
  text-align: right;
  display: flex;
}

.language {
  width: 100%;
  background-color: #f5f5f5;
}

.language form {
  background-color: #0000;
  padding: .5rem;
}

.language form button {
  color: #000;
  min-height: 0;
  min-width: 0;
  background-color: #0000;
  border-bottom: 4px solid #0000;
  border-radius: 0;
  padding: 0;
}

.language form button[aria-current="true"], .language form button:hover {
  border-bottom: 4px solid var(--branding-color-1-darker);
}

section div {
  gap: var(--section-content-block-gap);
  flex-direction: column;
  display: flex;
}

@media (max-width: 56rem) {
  body > header div.horizontal-view {
    padding: 0;
    position: relative;
  }

  body > header div.horizontal-view .logo {
    position: absolute;
    top: .75rem;
    left: .5rem;
  }

  body > header div.horizontal-view .collapsible-menu {
    padding-top: 1rem;
  }

  body > header div.horizontal-view .collapsible-menu .secondary-menu {
    width: 100%;
  }

  body > header div.horizontal-view .collapsible-menu .secondary-menu:hover {
    background-color: #0000;
  }
}

body > header nav:not(.breadcrumb-bar) {
  min-height: 4rem;
  align-items: center;
}

body > header nav:not(.breadcrumb-bar) a.logo {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #000;
  border: 0;
  border-radius: 50%;
}

body > header nav:not(.breadcrumb-bar) a.logo img {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  padding: .25rem;
}

body > header nav:not(.breadcrumb-bar) .dropdown > ul {
  background-color: #fff;
  border-radius: .25rem;
  padding-top: .25rem;
  top: 100%;
}

body > header nav:not(.breadcrumb-bar) .dropdown > ul > li {
  width: 100%;
}

body > header nav:not(.breadcrumb-bar) .dropdown > ul > li > a {
  color: #000;
  width: 100%;
  padding-top: var(--button-base-padding-top);
  padding-right: var(--button-base-padding-right);
  padding-bottom: var(--button-base-padding-bottom);
  padding-left: var(--button-base-padding-left);
}

@media (max-width: 56rem) {
  body > header nav:not(.breadcrumb-bar) .dropdown {
    width: 100%;
  }

  body > header nav:not(.breadcrumb-bar) .dropdown-button {
    text-align: left;
    width: 100%;
    padding-left: 0;
  }

  body > header nav:not(.breadcrumb-bar) .dropdown > ul > li {
    max-width: 100%;
  }
}

body > header nav:not(.breadcrumb-bar) select {
  color: inherit;
  font-size: inherit;
  background-color: #0000;
  border-radius: 1rem;
  padding: .5rem;
}

body > header nav:not(.breadcrumb-bar) ul {
  align-items: center;
}

body > header nav:not(.breadcrumb-bar) a {
  border-bottom: 4px solid #0000;
}

body > header nav:not(.breadcrumb-bar) a[aria-current="page"], body > header nav:not(.breadcrumb-bar) a:hover {
  border-bottom: 4px solid var(--branding-color-1-darker);
}

body > header nav:not(.breadcrumb-bar) .secondary-menu {
  margin-left: auto;
}

.message > div {
  margin-top: 0;
}

div.explanation.help_text {
  float: none;
  margin: 0 0 auto auto;
}

div.confirmation {
  margin-top: 0;
}

.recommendation {
  padding: var(--critical-informational-labels-padding);
  min-width: var(--critical-informational-labels-min-width);
  text-align: var(--critical-informational-labels-text-align);
  background-color: #c3ddf6;
  display: inline-block;
}

.ooi_summary__risk-rating h5 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.ooi_summary__risk-rating > span {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.page-meta .logged-in {
  justify-content: space-between;
}

.plugins.tiles img {
  object-fit: cover;
  object-position: center;
  height: 15rem;
}

.plugins > div {
  margin-top: 1.75rem;
  position: relative;
}

.plugins > div.required .nota-bene {
  margin: 0;
  display: inline;
  position: absolute;
  top: -1.75rem;
}

.plugins > div > .nota-bene {
  display: none;
}

.plugins > div > input[type="checkbox"] {
  margin-top: 0;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.plugins > div .tile-label {
  color: #fff;
  width: auto;
  background-color: #262431;
  margin: 0;
  padding: .25rem 1rem;
  font-size: 1.125rem;
  position: absolute;
  top: 1rem;
  right: 0;
}

.plugins > div.boefje .tile-label {
  background-color: #275937;
}

.plugins > div.bit .tile-label {
  background-color: #42145f;
}

.plugins > div.normalizer .tile-label {
  background-color: #e17000;
}

pre {
  color: #000;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 1rem;
}

form.horizontal-view > fieldset > div > .qr-code-image, .qr-code-image {
  max-width: 15rem;
  float: left;
  background-color: #fff;
}

.level-indicator.l4 > li:nth-child(-n+4), .level-indicator.l3 > li:nth-child(-n+3), .level-indicator.l2 > li:nth-child(-n+2), .level-indicator.l1 > li:nth-child(-n+1) {
  background-image: url("paw-active.baf57460.svg");
}

.level-indicator {
  background-color: #0000;
  flex-direction: row;
  gap: .25rem;
  padding: 0;
  line-height: 1em;
  display: flex;
}

.level-indicator li {
  min-width: 1rem;
  min-height: 1rem;
  background-image: url("paw-inactive.bb233e49.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

form select {
  padding: .5rem;
}

.stepper {
  border-top: 1px solid #ccc;
}

.stepper > ul {
  padding: 1rem 0;
}

.stepper > ul > li {
  opacity: .4;
  font-weight: 400;
  list-style-type: none;
}

.stepper > ul > li > a {
  color: #000;
  margin: 0;
  text-decoration: none;
}

.stepper > ul > li > a:hover {
  text-decoration: underline;
}

.stepper > ul > li a:visited {
  color: #000;
  text-decoration: none;
}

.stepper > ul > li:not(li[aria-label="current-step"] ~ li) {
  opacity: 1;
  font-weight: 700;
}

@media (min-width: 56rem) {
  .stepper > ul {
    flex-direction: row;
    padding: 2rem 0;
    display: flex;
  }

  .stepper > ul > li:before {
    content: "";
    margin: 0 2rem 0 1rem;
    font-family: tabler-icons;
    font-size: 1rem;
    font-weight: 400;
    display: inline-flex;
  }

  .stepper > ul > li:first-child:before {
    content: none;
  }
}

table tr.expando-row td table td {
  background-color: var(--application-base-background-color);
  padding: var(--table-base-cell-padding);
}

table.nowrap th a, table.nowrap td a {
  white-space: inherit;
}

td.confirmation, tr.confirmation {
  background-color: var(--notification-confirmation-background-color);
  border-width: var(--notification-confirmation-border-width);
  border-style: var(--notification-confirmation-border-style);
  border-color: var(--notification-confirmation-border-color);
}

td.confirmation:before, tr.confirmation:before {
  content: var(--notification-confirmation-icon-before-content);
}

td.confirmation:after, tr.confirmation:after {
  content: var(--notification-confirmation-icon-after-content);
}

td.error, tr.error {
  background-color: var(--notification-error-background-color);
  border-width: var(--notification-error-border-width);
  border-style: var(--notification-error-border-style);
  border-color: var(--notification-error-border-color);
}

td.error:before, tr.error:before {
  content: var(--notification-error-icon-before-content);
}

td.error:after, tr.error:after {
  content: var(--notification-error-icon-after-content);
}

td.warning, tr.warning {
  background-color: var(--notification-warning-background-color);
  border-width: var(--notification-warning-border-width);
  border-style: var(--notification-warning-border-style);
  border-color: var(--notification-warning-border-color);
}

td.warning:before, tr.warning:before {
  content: var(--notification-warning-icon-before-content);
}

td.warning:after, tr.warning:after {
  content: var(--notification-warning-icon-after-content);
}

td.explanation, tr.explanation {
  background-color: var(--notification-explanation-background-color);
  border-width: var(--notification-explanation-border-width);
  border-style: var(--notification-explanation-border-style);
  border-color: var(--notification-explanation-border-color);
}

td.explanation:before, tr.explanation:before {
  content: var(--notification-explanation-icon-before-content);
}

td.explanation:after, tr.explanation:after {
  content: var(--notification-explanation-icon-after-content);
}

.tiles .level-indicator {
  margin-left: auto;
}

.tiles img + div {
  border-top: 0;
}

.tiles > div {
  min-width: 0;
}

.toolbar {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

table.tree-view tbody > tr > td[colspan] {
  padding-right: 0;
}

table.tree-view tr > .col-ooi-id {
  width: 65%;
}

table.tree-view tr > .col-ooi-type {
  width: 20%;
}

table.tree-view tr > .col-action {
  width: 15%;
}

table.tree-view tr.folded > td {
  background-color: #ccc;
  overflow: hidden;
}

table.tree-view tr.folded > td:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.toggle-table-body:not(:checked) + table.tree-view tbody {
  display: none;
}

.toggle-table-body + table.tree-view > thead label {
  cursor: pointer;
}

.toggle-table-body:checked + table.tree-view > thead .ro-icon.open, .toggle-table-body:not(:checked) + table.tree-view > thead .ro-icon.close {
  display: none;
}

table.tree-view {
  table-layout: fixed;
}

table.tree-view th > div {
  justify-content: space-between;
  display: flex;
}

.two-thirds-one-third > div {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.wait-text {
  height: 4rem;
  position: relative;
}

.wait-text span {
  height: 3.5rem;
  opacity: 0;
  margin-top: 2.5rem;
  animation: fadeInOut 10s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.wait-text span:nth-child(2) {
  animation-delay: 2s;
}

.wait-text span:nth-child(3) {
  animation-delay: 4s;
}

.wait-text span:nth-child(4) {
  animation-delay: 6s;
}

.wait-text span:nth-child(5) {
  animation-name: fadeIn;
  animation-delay: 8s;
}

/*# sourceMappingURL=app.css.map */
